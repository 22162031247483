import {
  Button,
  Card,
  Chip,
  Paragraph,
  Space,
  TextInput,
} from "../../../../styles";
import LabeledTextArea from "../../../../components/LabeledTextArea";
import LabeledSelect from "../../../../components/LabeledSelect";
import i18n from "../../../../i18n";
import React, { useEffect, useRef, useState } from "react";
import {
  ActionButton,
  ModalActionWrapper,
  QuestionCardActions,
  QuestionIndicatorContainer,
  QuestionsContainer,
  StyledCheckboxWrapper,
  StyledModal,
} from "../styles";
import {
  AddQuestionButton,
  DeleteQuestionButton,
  DeleteQuestionOptionButton,
  SavingIndicator,
} from "./styles";
import { bindActionCreators } from "redux";
import {
  _surveyQuestionDelete as SurveyQuestionDelete,
  _surveyQuestionOptionCreate as SurveyQuestionOptionCreate,
  _surveyQuestionOptionDelete as SurveyQuestionOptionDelete,
  _surveyQuestionUpdate as SurveyQuestionUpdate,
  _surveyQuestionOptionUpdate as SurveyQuestionOptionUpdate,
} from "../../../../_redux/api/survey";
import { connect } from "react-redux";
import { bySortId } from "../SurveyModule";
import { SURVEY_QUESTION_TYPE, SURVEY_STATE } from "../../../../Constants";
import { LabelContainer } from "../../../Admin/SelectionStages/styles";
import * as S from "../../../../styles";
import { LanguageSelector } from "../../../../components/LanguageSelector";
import { LabeledCheckbox } from "components/Checkbox";

const AUTO_SAVE_DELAY_MS = 1200;

function EditQuestionCard(props) {
  const { languageSelected, setLanguageSelected } = props;
  const [question, setQuestion] = useState(props.question);
  const questionRef = useRef(question);
  questionRef.current = question;
  const [choices, setChoices] = useState(props.question.choices.sort(bySortId));
  const choicesRef = useRef(choices);
  choicesRef.current = choices;
  const [isLoading, setIsLoading] = useState(false);
  const [questionTypes, setQuestionTypes] = useState([]);
  const [notify_users, setNotifyUsers] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    setIsLoading(false);
    setQuestion(props.question);
    setChoices(props.question.choices);

    if (!questionTypes.length) {
      for (const key in SURVEY_QUESTION_TYPE) {
        setQuestionTypes((prevState) => [
          ...prevState,
          SURVEY_QUESTION_TYPE[key].key,
        ]);
      }
    }
  }, [props.question]);

  const deleteQuestion = () => {
    props.SurveyQuestionDelete({
      question: { uuid: question.uuid },
    });
  };

  const addQuestionOption = () => {
    let sortId = 1;
    if (choices.length) {
      sortId = choices[choices.length - 1].sort_id + 1;
    }
    props.SurveyQuestionOptionCreate({
      option: {
        survey_question_id: question.uuid,
        sort_id: sortId,
      },
    });
  };

  const deleteQuestionOption = (choice) => {
    props.SurveyQuestionOptionDelete({
      option: {
        uuid: choice.uuid,
      },
    });
  };

  const [questionSaveCountdown, setQuestionSaveCountdown] = useState(null);
  const saveQuestionData = () => {
    // Update the question and auto set type if it is not set
    // to have a default value
    props.SurveyQuestionUpdate(
      {
        question: {
          uuid: questionRef.current.uuid,
          name: questionRef.current.name,
          type: questionRef.current.type
            ? questionRef.current.type
            : questionTypes[0],
          survey_id: questionRef.current.survey_id,
        },
      },
      languageSelected
    );
  };
  const changeQuestionData = (e) => {
    const eTarget = e.target.value;
    const eName = e.target.dataset.id;
    setIsLoading(true);
    if (questionSaveCountdown) {
      clearTimeout(questionSaveCountdown);
    }
    setQuestionSaveCountdown(
      setTimeout(() => {
        saveQuestionData();
      }, AUTO_SAVE_DELAY_MS)
    );

    setQuestion({ ...question, [eName]: eTarget });
  };

  const [optionSaveCountdown, setOptionSaveCountdown] = useState(null);
  const saveQuestionOptionData = () => {
    setIsLoading(true);
    for (let choice of choicesRef.current) {
      props
        .SurveyQuestionOptionUpdate(
          {
            option: {
              uuid: choice.uuid,
              name: choice.name,
              survey_question_id: choice.survey_question_id,
            },
          },
          languageSelected
        )
        .then(() => {
          setIsLoading(false);
        });
    }
  };
  const changeQuestionOptionData = (e, i) => {
    const eTarget = e.target.value;
    const eName = e.target.name;

    if (optionSaveCountdown) {
      clearTimeout(optionSaveCountdown);
    }
    setOptionSaveCountdown(
      setTimeout(() => saveQuestionOptionData(), AUTO_SAVE_DELAY_MS)
    );
    setChoices((choices) => {
      return choices.map((choice, index) => {
        if (index === i && choice[eName] != e.eTarget) {
          return { ...choice, [eName]: eTarget };
        } else {
          return choice;
        }
      });
    });
  };

  const onQuestionOptionBlur = (e) => {
    if (optionSaveCountdown) {
      clearTimeout(optionSaveCountdown);
    }
    saveQuestionOptionData();
  };

  const handleStartQuestion = () => {
    if (notify_users) {
      setOpenModal(true);
      return;
    }
    props.changeQuestionState(props.question, SURVEY_STATE.ACTIVE);
  };

  return (
    <Card>
      <QuestionIndicatorContainer>
        <Chip color="draft">{i18n.t("survey.state.draft")}</Chip>
        {/*{isLoading ? (*/}
        {/*  <SurveyCardSpinner size="18px" />*/}
        {/*) : (*/}
        {/*  <SaveCompleteIndicator />*/}
        {/*)}*/}
      </QuestionIndicatorContainer>
      <SavingIndicator isSaving={isLoading} />
      <DeleteQuestionButton onClick={deleteQuestion}>
        <Paragraph color="var(--bg-dark)" inline bold margin="0px" size="16px">
          X
        </Paragraph>
      </DeleteQuestionButton>
      <LabelContainer>
        <LanguageSelector
          isDark
          languageSelected={languageSelected}
          setLanguageSelected={setLanguageSelected}
        />
      </LabelContainer>
      <LabeledTextArea
        label={i18n.t("form.question")}
        value={question.name}
        name="name"
        data-id="name"
        width="100%"
        color="var(--bg-dark)"
        bgColor="var(--gray-light)"
        labelColor="var(--bg-dark)"
        onChange={(e) => changeQuestionData(e)}
      />
      <LabeledSelect
        width="100%"
        label={i18n.t("survey.edit.type.label")}
        labelColor="var(--bg-dark)"
        color="var(--bg-dark)"
        bgColor="var(--gray-light)"
        value={question.type}
        options={questionTypes}
        name={i18n.t("form.type")}
        data-id="type"
        onChange={(e) => {
          changeQuestionData(e);
        }}
      />
      <Space />
      <QuestionsContainer>
        {question?.type !== "text" &&
          choices.map((choice, i) => (
            <div style={{ position: "relative" }} key={i}>
              <TextInput
                value={choice.name}
                name="name"
                bgColor="var(--gray-light)"
                color="var(--bg-dark)"
                width="100%"
                onChange={(e) => changeQuestionOptionData(e, i)}
                onBlur={(e) => onQuestionOptionBlur(e, i)}
              />
              <DeleteQuestionOptionButton
                onClick={() => deleteQuestionOption(choice)}
              >
                <Paragraph
                  color="var(--bg-dark)"
                  inline
                  bold
                  margin="0px"
                  size="9px"
                >
                  X
                </Paragraph>
              </DeleteQuestionOptionButton>
            </div>
          ))}

        {question?.type !== "text" && (
          <AddQuestionButton onClick={addQuestionOption}>
            <Paragraph margin="0px" size="25px" color="var(--bg-dark)">
              +
            </Paragraph>
          </AddQuestionButton>
        )}
      </QuestionsContainer>
      <StyledCheckboxWrapper>
        <LabeledCheckbox
          label={i18n.t("survey.checkbox.label")}
          width="20px"
          height="20px"
          checked={notify_users}
          data-id="notify_users"
          className="customCheckbox"
          value="0"
          labelColor="black"
          onChange={(e) => setNotifyUsers(e.target.checked)}
        />
      </StyledCheckboxWrapper>
      <Space />
      <QuestionCardActions borderTop>
        <Button
          width="100%"
          paddingSides="5px"
          color="var(--bg-dark)"
          onClick={handleStartQuestion}
        >
          {i18n.t("button.start")}
        </Button>
      </QuestionCardActions>
      {openModal && (
        <StyledModal
          isOpen={openModal}
          onEscapeKeydown={() => setOpenModal(false)}
          onBackgroundClick={() => setOpenModal(false)}
        >
          {i18n.t("survey.email.confirm")}
          <ModalActionWrapper>
            <ActionButton onClick={() => setOpenModal(false)}>
              {i18n.t("button.cancel")}
            </ActionButton>
            <ActionButton
              onClick={() => {
                props.changeQuestionState(
                  props.question,
                  SURVEY_STATE.ACTIVE,
                  notify_users
                );
                setOpenModal(false);
              }}
            >
              {i18n.t("button.submit")}
            </ActionButton>
          </ModalActionWrapper>
        </StyledModal>
      )}
    </Card>
  );
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      SurveyQuestionDelete,
      SurveyQuestionOptionCreate,
      SurveyQuestionOptionDelete,
      SurveyQuestionUpdate,
      SurveyQuestionOptionUpdate,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(EditQuestionCard);
