import React, { useEffect, useRef, useState } from "react";

import {
  _sendFile as SendFile,
  _addMessageNew as AddNewMessageCreatedByMe,
} from "_redux/api/message";

import { CONST } from "Constants";
import Icon from "ui/assets/Icon";

import "./MessageTextInput.css";
import DropZoneWrapper from "../../../../components/DropZoneWrapper";
import {UploadedFile} from "../../../../_redux/reducers/message";
import { SendWsMessage } from "../../../../_ws/Websocket";
import { SendGroupMessage } from "../../../../_ws/GroupWebsocket";
import { CreateUUID } from "../../../../Utils";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getGroupId } from "../GroupChat/MobileGroupChat";

const MessageTextInput = (props) => {
  const ref = useRef();
  const [state, setState] = useState({lastMsgId: props.lastLoadedMessageId + 1});
  const dispatch = useDispatch();

  //const groupInformation = useSelector(state =>{ return GetGroupInformation(state)});
  const uploadedFile = useSelector(state =>{ return UploadedFile(state)});

  const keyDown = (e) =>{
    if (e.keyCode === 13) {
      if (!e.ctrlKey && !e.altKey) send();
      else ref.current.value += "\n";
    }
  }

  const send = () => {

    if (!ref.current.value.replace(/\s/g, "")?.length) {
      return;
    }
    if (props.chat === CONST.chat.private_chat) {
      let msg = {
        type: CONST.messagetypes.text,
        msg: ref.current.value.replace(/^\n|\n$/g, ""),
        to: props.user.email,
        from: props.currentUser.email,
        created_at: new Date().toISOString(),
      };

      //this.props.handleNewMessage(msg, true);
      SendWsMessage(props.user, msg.msg, msg.type);
      dispatch(AddNewMessageCreatedByMe(msg));
    } else if (props.chat === CONST.chat.group_chat) {

      let msg = {
        message_type: CONST.messagetypes.group_msg,
        msg: ref.current.value.replace(/^\n|\n$/g, ""),
        to: getGroupId(props.meeting),
        from: props.currentUser.email,
        created_at: new Date().toISOString(),
      };
      SendGroupMessage(msg.to, msg.msg, msg.message_type);
      //      WSUpdateSurvey(msg.to, msg.msg);
      msg.tmp = true;
      dispatch(AddNewMessageCreatedByMe(msg));
    }
    ref.current.value = CONST._empty;
  }

  const fileChanged = (base64, file) => {
    if (!file) return;
    ref.current.classList.remove("drag_active");

    const fileuuid = CreateUUID();
    setState({ ...state, pendingFileUuid: fileuuid });
    dispatch(SendFile({
      fileuuid: fileuuid,
      file: base64,
      filename: file.name /*,
      socket: this.props.socket,*/,
    }));
  }

  useEffect(()=>{
    if (
      uploadedFile?.url != null &&
      uploadedFile?.uuid === state.pendingFileUuid
    ) {
      ref.current.classList.add("drag_active");

      if (props.chat === CONST.chat.private_chat) {
        let msg = {
          type: CONST.messagetypes.text,
          msg: uploadedFile.url,
          to: props.user.email,
          from: props.currentUser.email,
          created_at: new Date().toISOString(),
        };
        SendWsMessage(props.user, msg.msg, msg.type);
        dispatch(AddNewMessageCreatedByMe(msg));
      } else if (props.chat === CONST.chat.group_chat) {
        let msg = {
          v: CONST.messagetypes.group_msg,
          msg: uploadedFile.url,
          to: props.meeting.uuid
            ? props.meeting.uuid
            : props.meeting.message_group_id,
          from: props.currentUser.email,
          created_at: new Date().toISOString(),
        };
        SendGroupMessage(msg.to, msg.msg, msg.message_type);

        msg.tmp = true;
        dispatch(AddNewMessageCreatedByMe(msg));
      }

      setState({ ...state, pendingFileUuid: null });
    }
  },[uploadedFile, state.pendingFileUuid])


  useEffect(()=>{
    const textarea = ref.current;
    const limit = 80; //height limit
    if (textarea) {
      textarea.oninput = function () {
        textarea.style.height = "";
        textarea.style.height = Math.min(textarea.scrollHeight, limit) + "px";
      };
    }
  },[]);

  if (!props.user && !props.meeting) return null;

  return (
    <div className="MessageTextInput">
      <DropZoneWrapper
        onDone={fileChanged}
        dragOverClassName="drag-over"
        disabled={
          !(
            props.user ||
            props.meeting?.uuid ||
            props.meeting?.message_group_id
          )
        }
        className="messageWrapper"
      >
        <textarea
          id="textarea"
          placeholder="Ihre Nachricht"
          onKeyUp={keyDown}
          ref={ref}
        />
      </DropZoneWrapper>
      <div className="messageAction">
        <DropZoneWrapper
          onDone={fileChanged}
          dragOverClassName="drag-over"
          onClickSelectFileEnabled={true}
          disabled={
            !(
              props.user ||
              props.meeting?.uuid ||
              props.meeting?.message_group_id
            )
          }
        >
          <Icon
            className="ia"
            type={Icon.Types.link}
            color={Icon.Colors.black}
          />
        </DropZoneWrapper>

        <Icon
          className="send ia"
          type={Icon.Types.chevron_right}
          color={Icon.Colors.black}
          onClick={() => {
            send();
          }}
        />
      </div>
    </div>
  );

}

export default MessageTextInput;
