import React from "react";

import { useSelector} from 'react-redux'

import classes from "./RemoteSharingStateView.module.css";
import Icon from "../../../../../ui/assets/Icon/Icon";
import { CONST, SPACE } from "../../../../../Constants";

const RemoteSharingStateView = ({groupId}) => {
  const ws = useSelector((state) => {return state.websocket})
  
  if(ws.groups[groupId] == null)
    return null;
  
  const badgeValue = ws.groups[groupId].listeners?.length>0?ws.groups[groupId].listeners.length:null;
  return (
    <div className={classes.root + SPACE + (ws.actualGroup === groupId?classes.active:CONST._empty)}>
      {ws.actualGroup === groupId?
        <>
        {ws.isSharing && !ws.isListening && <Icon type={Icon.Types.remote_sharing} badge={badgeValue} color="red"></Icon>}
        {ws.isListening && <Icon type={Icon.Types.remote_listening} badge={badgeValue} color="main"></Icon>}
        </>
      :
        <>
         {ws.groups[groupId].sharer==null && ws.groups[groupId].listeners?.length>0 && <Icon type={Icon.Types.remote_listening} badge={badgeValue}></Icon>}
         {ws.groups[groupId].sharer!=null && <Icon type={Icon.Types.remote_sharing} badge={badgeValue}></Icon>}
        </>
      }
    </div>
  );
};

export default RemoteSharingStateView;
