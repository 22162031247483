import React, { useEffect, useState } from "react";
import {_  } from "Constants";
import { useDispatch, useSelector } from "react-redux";

import {_addSupportUser} from "_redux/api/users";
import { GetActual as GetActualWorkspace } from "_redux/reducers/workspace";
import { getUserUuid } from "_redux/reducers/login";

import classes from './ContactsList.module.css'

import ContactIcon from "./ContactIcon/ContactIcon";
import { setChatOpened } from "_redux/api/_app";

const ContactsList = () => {
  const dispatch = useDispatch();

  const [contacts, SetContacts] = useState([]);
  const [opened, SetOpened] = useState(false);

  const userUuid = useSelector(state =>{ return getUserUuid(state)});
  const actualWorkspace = useSelector(GetActualWorkspace);

  const close = () =>{
    SetOpened(false);
  }

  const open = (ev) =>{
    if(ev){
      ev.preventDefault();
      ev.stopPropagation();
    }
    SetOpened(true);
  }

  useEffect(()=>{
    if(!actualWorkspace?.contacts)
    {
      SetContacts([]);
      return;
    }
    const contactUsers = actualWorkspace?.contacts?.filter(
      (contact) => contact.uuid !== userUuid
    );
    if(contactUsers && Array.isArray(contactUsers))
      SetContacts(contactUsers);
    else
      SetContacts([]);
  },[actualWorkspace?.contacts, userUuid])


  useEffect(()=>{
    if(opened){
      document.addEventListener("click", close);
    }

    return ()=>{
      document.removeEventListener("click", close);
    }
  },[opened])

  const selectUser = async (user) => {
      dispatch(setChatOpened({ opened: true, scope: "user", entity: user }));
  };

  if (contacts.length === 0) return null;
  return (
      <div
          className={classes.root + _ + (opened ? classes.opened : _)}
          onClick={(e) => (!opened ? open(e) : null)}
      >
          <i className="icon-headset btn"></i>
          {contacts?.map((entry, i) => (
              <ContactIcon
                  style={{ "--id": i }}
                  user={entry}
                  key={i}
                  _key={i}
                  onClick={() => (opened ? selectUser(entry) : null)}
                  opened={opened}
              />
          ))}
      </div>
  );
};


export default ContactsList;