import React, {useEffect, useRef, useState } from "react";

import {
  /*_create as SendMessage,*/
  _get as LoadUserMessages,
  _setShown as SetAsShown,
} from "_redux/api/message";

import { GetMessagesHash } from "_redux/reducers/message";
import { getUser as GetMyUser } from "_redux/reducers/login";

import { CONST } from "Constants";
import { RenderDateTime } from "KlarHelper";
import Icon from "ui/assets/Icon";

import "./MessengerChat.css";

import MessageTextInput from "../MessageTextInput/MessageTextInput";
import CallState from "../../../../ui/Call/CallState/CallState";
import MessengerChatMessage from "../MessengerChatMessage/MessengerChatMessage";
import { Fragment } from "react";
import { StartCall } from "../../../../_ws/CallWebsocket";
import {
  GetListenersInGroup,
  GetSharerInGroup,
  IsListeningGroup,
  IsSharingGroup,
} from "../../../../_redux/reducers/websocket";

import { CreateGroupIdFromUserIds, IsMobile } from "../../../../Utils";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setChatOpened } from "_redux/api/_app";
import { _GetName } from "_redux/_models/User";
import {
    MobileHeaderActionsPortal,
    MobileHeaderLeftActionPortal,
    MobileHeaderTitlePortal,
} from "ui/HeaderMobile/HeaderMobile";
import { Portal } from "components/Portal";

//handleStatus={handleStatus}

export default function MobileMessengerChat({ user, call, messages = [] }) {
    const dispatch = useDispatch();
    const listEndRef = useRef();
    const me = useSelector((state) => {
        return GetMyUser(state);
    });

    useEffect(() => {
        if (user) dispatch(LoadUserMessages({ email: user.email }));
    }, [user?.email]);

    useEffect(() => {
        scrollToBottom();
    }, [messages, messages.length]);

    const setAsShown = (ids) => {
        if (ids && Array.isArray(ids)) dispatch(SetAsShown({ msg_ids: ids }));
        else if (ids && !Array.isArray(ids))
            dispatch(SetAsShown({ msg_ids: [ids] }));
    };

    const scrollToBottom = () => {
        if (listEndRef.current != null) {
            listEndRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    if (!user) return null;

    let lastDate = "";
    return (
        <div className={"MessengerChat"}>
            {IsMobile() && (
                <>
                    <Portal selector={MobileHeaderLeftActionPortal}>
                        <i
                            className={"icon-backBtn" + " icon-chevron ia"}
                            onClick={() =>
                                dispatch(
                                    setChatOpened({
                                        opened: false,
                                        scope: "user",
                                        entity: null,
                                    })
                                )
                            }
                        ></i>
                    </Portal>
                    <Portal selector={MobileHeaderTitlePortal}>
                        <span>{_GetName(user)}</span>
                    </Portal>
                    <Portal selector={MobileHeaderActionsPortal}>
                        <div>
                            {call?.hasUser(me.email) ? (
                                <CallState call={call} user={user} />
                            ) : (
                                <Icon
                                    className="videocall ia"
                                    type={Icon.Types.videocall}
                                    onClick={() => {
                                        StartCall(me.uuid, user.uuid);
                                    }}
                                    color="main"
                                ></Icon>
                            )}
                        </div>
                    </Portal>
                </>
            )}

            {!IsMobile() && (
                <div className="title">
                    <div className="titleLine">{_GetName(user)}</div>
                    {call?.hasUser(me.email) ? (
                        <CallState call={call} user={user} />
                    ) : (
                        <Icon
                            className="videocall ia"
                            type={Icon.Types.videocall}
                            onClick={() => {
                                StartCall(me.uuid, user.uuid);
                            }}
                        ></Icon>
                    )}
                    <Icon
                        className="arRight ia"
                        type={Icon.Types.chevron_right}
                        onClick={() =>
                            dispatch(
                                setChatOpened({
                                    opened: false,
                                    scope: "user",
                                    entity: null,
                                })
                            )
                        }
                    ></Icon>
                </div>
            )}
            <div className="list">
                {messages.map((msg, index) => {
                    if (msg.is_deleted) return null;

                    let renderDate = RenderDateTime(msg.timestamp);
                    let dateString =
                        lastDate !== renderDate.date ? renderDate.date : null;
                    lastDate = renderDate.date;
                    if (dateString)
                        dateString = <div className="date">{dateString}</div>;

                    return (
                        <Fragment key={index}>
                            {dateString ? dateString : ""}
                            <MessengerChatMessage
                                msg={msg}
                                me={me}
                                user={user}
                                setAsShownHandler={setAsShown}
                            ></MessengerChatMessage>
                        </Fragment>
                    );
                })}
                <div className="listEndAnchor" ref={listEndRef}></div>
            </div>
            <MessageTextInput
                chat={CONST.chat.private_chat}
                user={user}
                currentUser={me}
                myUser={me}
            ></MessageTextInput>
        </div>
    );
}



//export default connect(mapStateToProps, mapDispatchToProps)(MobileMessengerChat);
