import {
  CURRENT_INSTRUCTION_LOCATIONS,
  INSTRUCTIONS_COMPONENT_STATS,
} from "../actions/instructions";
import { initialState } from "../../initialState";

export function instructionsReducer(state = initialState, action) {
  return state;
  switch (action.type) {
    case INSTRUCTIONS_COMPONENT_STATS:

       console.log("------action data" + JSON.stringify(action.data));
       console.log("------state stats" + JSON.stringify(state.stats));
      for (let key of Object.keys(action.data)) {
        if (state.stats.hasOwnProperty(key)) {
          // console.log(key);
          return {
            ...state,
            stats: {
              ...state.stats,
              [key]: Object.assign({}, state.stats[key], action.data[key]),
            },
          };
        } else {
          return {
            ...state,
            stats: Object.assign({}, state.stats, action.data),
          };
        }
      }

    case CURRENT_INSTRUCTION_LOCATIONS:

      return {
        ...state,
        locations: Object.assign({}, state.locations, action.data),
      };
    default:
      return state;
  }
}

export const ComponentStats = (state) => state.instructions.stats;
export const getCurrentInstructionLocations = (state) =>
  state.instructions.locations;
