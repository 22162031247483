let isActive = false;
export class AdminMode
{
	SetActive(val)
  {
    console.log(val)
    isActive = val===true;
  }
  
  IsActive()
  {
    return isActive;
  }

}