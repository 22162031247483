import { CONST } from "../../Constants";

export class Workspace {
  constructor() {
    this.name = CONST._empty;
    this.public_url = CONST._empty;
    this.calendar_id = CONST._empty;
    this.access = CONST._empty; //'public
    this.contacts = [];
    this.users = [];
    this.meetings = [];
    this.company_id = CONST._empty;
    this.duration = 0;
    this.role = {}; // id: 2, value: 10, name: "Owner" }
    this.stages = [];
    this.stages_home = [];
    this.direct_registration = CONST._empty;

    this.uuid = CONST._empty;

    this.__type = "workspace";
  }
}

export function ParseWorkspace(raw) {
  let ws = new Workspace();
  for (let i in raw) {
    ws[i] = raw[i];
  }

  if(raw.message_group_id != null)
    ws.add_group_chat = true;
  else
    ws.add_group_chat = false;
  return ws;
}
