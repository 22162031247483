import {
  _getInPinboard as getInPinboard,
  _setToPinboard as setToPinboard,
  _createMessage as createMessage,
  _deleteMessage as deleteMessage,
  _updateMessage as updateMessage,
  _createLike as createLike,
  _setLike as setLike,
  _getLike as getLike,
  _getMessageComments as getMessageComments,
  _getUserMessages as getUserMessages,
  _getMessage as getMessage,
  _getNewUserMessages as getNewUserMessages,
  addViewToMessage,
  _unfollow,
  _hide
} from "./../actions/social";
import { _doRequest, Endpoints, EndpointFunctionMap, _doRequest_async, RESPONSE_TYPE } from "./_api.js";

EndpointFunctionMap.set(Endpoints.social_message_get_pinboard, getInPinboard);
EndpointFunctionMap.set(Endpoints.social_message_create, createMessage);
EndpointFunctionMap.set(Endpoints.social_message_delete, deleteMessage);
EndpointFunctionMap.set(Endpoints.social_message_update, updateMessage);
EndpointFunctionMap.set(Endpoints.social_like_create, createLike);
EndpointFunctionMap.set(Endpoints.social_message_comments_get,getMessageComments);
EndpointFunctionMap.set(Endpoints.social_user_messages_get, getUserMessages);
EndpointFunctionMap.set(Endpoints.social_message_set_pinboard, setToPinboard);
EndpointFunctionMap.set(Endpoints.social_message_get, getMessage);
EndpointFunctionMap.set(Endpoints.social_like_set, setLike);
EndpointFunctionMap.set(Endpoints.social_like_get, getLike);
EndpointFunctionMap.set(Endpoints.social_new_messages_get, getNewUserMessages);
EndpointFunctionMap.set(Endpoints.social_message_add_view, addViewToMessage);

export function _getInPinboard(data) {
  const endpoint = Endpoints.social_message_get_pinboard;
  return _doRequest(endpoint, EndpointFunctionMap.get(endpoint), data);
}

export function _createMessage(data) {
  const endpoint = Endpoints.social_message_create;
  return _doRequest(endpoint, EndpointFunctionMap.get(endpoint), data);
}

export function _reloadMessage(data) {
  const endpoint = Endpoints.social_message_get;
  return _doRequest(endpoint, EndpointFunctionMap.get(endpoint), data);
}

export function _deleteMessage(data) {
  const endpoint = Endpoints.social_message_delete;
  return _doRequest(endpoint, EndpointFunctionMap.get(endpoint), data);
}
export function _updateMessage(data) {
  const endpoint = Endpoints.social_message_update;
  return _doRequest(endpoint, EndpointFunctionMap.get(endpoint), data);
  //return _doRequest(Endpoints.social_message_update, updateMessage, data);
}

export function _sendLike(data) {
  const endpoint = Endpoints.social_like_create;
  return _doRequest(endpoint, EndpointFunctionMap.get(endpoint), data);
  //return _doRequest(Endpoints.social_like_create, createLike, data);
}

export function _setLike(data) {
  const endpoint = Endpoints.social_like_set;
  return _doRequest(endpoint, EndpointFunctionMap.get(endpoint), data);
}

export function _getLike(data) {
  const endpoint = Endpoints.social_like_get;
  return _doRequest(endpoint, EndpointFunctionMap.get(endpoint), data);
}

export function _getMessageComments(data) {
  const endpoint = Endpoints.social_message_comments_get;
  return _doRequest(endpoint, EndpointFunctionMap.get(endpoint), data);
  //return _doRequest(Endpoints.social_message_comments_get, getMessageComments, data);
}
export function _getUserMessages(data) {
  const endpoint = Endpoints.social_user_messages_get;
  return _doRequest(endpoint, EndpointFunctionMap.get(endpoint), data);
}
export function _getNewUserMessages(data) {
  const endpoint = Endpoints.social_new_messages_get;
  return _doRequest(endpoint, EndpointFunctionMap.get(endpoint), data);
}

export function _sendToPinboard(data) {
  const endpoint = Endpoints.social_message_set_pinboard;
  return _doRequest(endpoint, EndpointFunctionMap.get(endpoint), data);
  //return _doRequest(Endpoints.social_message_set_pinboard, setToPinboard, data);
}


export function addView(message_id) {
  const endpoint = Endpoints.social_message_add_view;
  //return _doRequest(endpoint, EndpointFunctionMap.get(endpoint), {"message_id":message_id});
  return (dispatch) => {
    dispatch(EndpointFunctionMap.get(endpoint)(message_id));
  };
}

export async function unfollow(data, reducerDispatch = null) {
  const res = await _doRequest_async(Endpoints.social_subscription_unset, null, data);
  reducerDispatch && reducerDispatch(_unfollow(RESPONSE_TYPE.SUCCESS, res.info, res.data))
  return res;
}

export async function follow(data, reducerDispatch = null) {
  const res = await _doRequest_async(Endpoints.social_subscription_set, null, data);
  reducerDispatch && reducerDispatch(_unfollow(RESPONSE_TYPE.SUCCESS, res.info, res.data))
  return res;
}

export async function getFollowingState(data, reducerDispatch = null) {
    //data = {entity_id:uuid}
    const res = await _doRequest_async(
        Endpoints.social_subscription_get,
        null,
        data
    );
    reducerDispatch &&
        reducerDispatch(_unfollow(RESPONSE_TYPE.SUCCESS, res.info, res.data));
    return res;
}

export async function hideMessage(data, reducerDispatch = null) {
    const res = await _doRequest_async(
        Endpoints.social_message_hide,
        null,
        data
    );
    //console.log(res);

    reducerDispatch &&
        reducerDispatch(_hide(RESPONSE_TYPE.SUCCESS, res.info, res.data));
    return res;
}
