export const DOCUMENTS_GET = {
  PENDING: "DOCUMENTS_GET_PENDING",
  SUCCESS: "DOCUMENTS_GET_SUCCESS",
  ERROR: "DOCUMENTS_GET_ERROR",
};
export const DOCUMENTS_CREATE = {
  PENDING: "DOCUMENTS_CREATE_PENDING",
  SUCCESS: "DOCUMENTS_CREATE_SUCCESS",
  ERROR: "DOCUMENTS_CREATE_ERROR",
};
export const DOCUMENTS_UPDATE = {
  PENDING: "DOCUMENTS_UPDATE_PENDING",
  SUCCESS: "DOCUMENTS_UPDATE_SUCCESS",
  ERROR: "DOCUMENTS_UPDATE_ERROR",
};
export const DOCUMENTS_GET_INWORKSPACE = {
  PENDING: "DOCUMENTS_GET_INWORKSPACE_PENDING",
  SUCCESS: "DOCUMENTS_GET_INWORKSPACE_SUCCESS",
  ERROR: "DOCUMENTS_GET_INWORKSPACE_ERROR",
};
export const DOCUMENTS_GET_RECORDING = {
  PENDING: "DOCUMENTS_GET_RECORDING_PENDING",
  SUCCESS: "DOCUMENTS_GET_RECORDING_SUCCESS",
  ERROR: "DOCUMENTS_GET_RECORDING_ERROR",
};
export const DOCUMENTS_DELETE = {
  PENDING: "DOCUMENTS_DELETE_PENDING",
  SUCCESS: "DOCUMENTS_DELETE_SUCCESS",
  ERROR: "DOCUMENTS_DELETE_ERROR",
};

export function _getInWorkspace(type, info, payload) {
  return {
    type: DOCUMENTS_GET_INWORKSPACE[type],
    info: info,
    payload: payload,
  };
}

export function _getInRecording(type, info, payload) {
  return {
    type: DOCUMENTS_GET_RECORDING[type],
    info: info,
    payload: payload,
  };
}

export function _get(type, info, payload) {
  return { type: DOCUMENTS_GET[type], info: info, payload: payload };
}
export function _delete(type, info, payload) {
  return { type: DOCUMENTS_DELETE[type], info: info, payload: payload };
}
export function _update(type, info, payload) {
  return { type: DOCUMENTS_UPDATE[type], info: info, payload: payload };
}
export function _create(type, info, payload) {
  return { type: DOCUMENTS_CREATE[type], info: info, payload: payload };
}
