export const _ = " ";
export const SPACE = " ";
export const EMPTY = "";
export const _ia = " ia";
export const ia = "ia";

export const CONST = {
  _SERGEJ_developing: true,
  _empty: "",
  _slash: "/",
  _hash: "#",
  _asterisk: "*",
  _space: " ",
  _: " ",
  meet_domain: "https://klarland.cap-tek.com",
  app_iframeContext: "klar.land",
  content_types: {
    pdf: "pdf",
    media: "media",
    text: "text",
    material: "material",
    call: "call",
  },
  pathes: {
    data_privacy: "/data_privacy/",
    contact: "/contact/",
    imprint: "/imprint/",
    settings: "/settings/",
    terms_use: "/terms_use/",
    /*showroom:'/showroom/',*/

    company: "COMPANY_PATH",
    workspace: "WORKSPACE_PATH",
    menu: "/menu/",
    home: "/",
    overview: "/overview/",
    agenda: "/agenda/",
    stages: "/stages/",
    live: "/live/",
    workshops: "/workshops/",
    showrooms: "/showrooms/",
    showroomEmbeddeds: "/showroomEmbeddeds/",
    WorkspaceViewEmbedded: "/workspaceViewEmbedded/",
    survey: "/survey=:survey_id",

    document: "/doc/:documentId/ws/:workspaceId",
    meeting_response: "/meeting/set_response/:token/:meetingId/:response",
    page: "/page/",
    meeting_type: {
      call: "/call/",
      callShowRoom: "/callShowRoom/",
      recording: "/recording/",
      conference: "/workshops/",
      broadcast: "/live/",
      meeting: "/workshops/",
      magic_login: "/magic_login/",
    },
    html: "/html/",
    html_url: "html/",

    checkin: "/checkin/",
    showroom: {
      _: "/showroom/",
      animation: "/showroom/animation/",
      media: "/showroom/media/",
      config: "/showroom/config/",
      perspectives: "/showroom/perspectives/",
    },
    showroomEmbedded: {
      _: "/showroomEmbedded/",
      animation: "/showroomEmbedded/animation/",
      media: "/showroomEmbedded/media/",
      config: "/showroomEmbedded/config/",
      perspectives: "/showroomEmbedded/perspectives/",
    },
    user: {
      _: "/user/",
      calendar: "/user/calendar",
      network: "/user/network",
      settings: "/user/settings",
      pinboard: "/user/pinboard",
      social: "/user/social",
    },
    search: "/search",
    login: {
      _: "/login",
      register: "/login/register",
      pass_forgot: "/login/pass_forgot",
      logout: "logout",
      confirm_email: "/login/confirmEmail",
      confirm_terms: "/login/confirmTerms",
      general_login: "/login/general_login",
      api: "/api/login/",
      social: "/social/login/",
    },
  },
  _cookies: {
    data: "data", //to fecth local info
    agreed_comfort: "agreed_comfort", //is comfort agreed
    token: "comfort.token", //auth token
    item_view_type: "comfort.item_view_type", //list|card
    remote_sharing: {
      auto_change_pages: "comfort.remote_sharing.auto_change_pages",
    },

    essential: {
      settings: {},
    },
    comfort: {
      user: {
        prefernces: {
          meeting: {
            start_muted_audio:
              "comfort.user.prefernces.meeting.start_muted_audio",
            start_muted_video:
              "comfort.user.prefernces.meeting.start_muted_video",
          },
        },
      },
    },
  },
  classes: {
    _: " ",
    active: "active",
    _active: " active",
    opened: "opened",
    _opened: " opened",
    ia: "ia",
    _ia: " ia",
    view_list: "view_list",
    view_tiles: "view_tiles",
    new: "new",
    _new: " new",
    left: "left",
    _left: " left",
    right: "right",
    _right: " right",
    hidden: "hidden",
    _hidden: " hidden",
    minimized: "minimized",
    _minimized: " minimized",
    disabled: "disabled",
    _disabled: " disabled",
  },
  messagetypes: {
    call: "call",
    /*text: "text",*/
    identification: "identification",
    status: "status",
    text: "text",
    group_left: "leftGroup",
    group_joined: "joinedGroup",
    group_msg: "group_msg",
    group_remote_msg: "gr",
    group_remote_3d_msg: "gr3",

    group_set_sharer: "group_set_sharer",
    group_share: "group_share",
    socketconnection: "socketconnection",
    connectedUser: "ConnectedUsers",
  },

  remoteShareStates: {
    _default: "_default",
    sharing: "sharing",
    listening: "listening",
  },
  chat: {
    group_chat: "group_chat",
    private_chat: "private_chat",
    workspace: "workspace",
  },
  callstates: {
    calling: "calling",
    established: "established",
    hangup: "hangup",
  },

  reducerStates: {
    pending: "pending",
    error: "error",
    success: "success",
  },
  userStates: {
    available: "available",
    busy: "busy",
    unknown: "unknown",
    offline: "offline",
  },
  roles: {
    workspace: [
      { value: 10, name: "Owner" },
      { value: 20, name: "Editor" },
      { value: 30, name: "Reader" },
      { value: 40, name: "Guest" },
      { value: 41, name: "Invited" },
      { value: 50, name: "Requested" },
    ],
    publicWorkspace: [
      { value: 10, name: "Owner" },
      { value: 20, name: "Editor" },
      { value: 30, name: "Reader" },
      { value: 40, name: "Guest" },
      { value: 41, name: "Invited" },
    ],
  },
  analyticsRole: [
    { value: 1, name: "Admin" },
    { value: 2, name: "Owner" },
    { value: 3, name: "Editor" },
    { value: 4, name: "Reader" },
    { value: 5, name: "Notfound" },
  ],

  adminModuleKeys: {
    companyDetails: "companyDetails",
    companyCreate: "companyCreate",
    companyHome: "companyHome",
    companyNews: "companyNews",
    newCompany: "newcompany",
    selectionContacts: "selectionContacts",
    selectionDetails: "selectionDetails",
    newSelection: "newSelection",
    selectionDocs: "selectionDocs",
    selectionEvents: "selectionEvents",
    selectionHome: "selectionHome",
    selectionStages: "selectionStages",
    selectionUsers: "selectionUsers",
    selectionHtml: "selectionHtml",
    selectionNews: "selectionNews",
    companyAnalytics: "companyAnalytics",
    workspaceAnalytics: "workspaceAnalytics",
  },
  calendar: {
    uuid: "calendar-uuid",
  },
  skeleton_loading: "skeleton_loading",
};

export const AVAILABLE_TRANSLATIONS = [
  "de",
  "en",
  "fr",
  "es",
  "it",
  "cn",
  "nl",
];

export const SURVEY_STATE = {
  DRAFT: { key: "draft", label: "Draft" },
  ACTIVE: { key: "active", label: "Active" },
  CLOSED: { key: "closed", label: "Closed" },
  PUBLISHED: { key: "published", label: "Published" },
};

export const SURVEY_QUESTION_TYPE = {
  SINGLE_CHOICE: { key: "single_choice", label: "Single Choice" },
  MULTIPLE_CHOICE: { key: "multiple_choice", label: "Multiple Choice" },
  TEXT: { key: "text", label: "Text" },
  // VOTING: { key: "voting", label: "Voting" },
};

export const INSTRUCTIONS_INDICATOR_FORM = {
  round: "round",
  square: "square",
};

export const INSTRUCTIONS_PAGE = {
  main: "main",
  workspace: "workspace",
  messenger: "messenger",
  survey: "survey",
  userNetwork: "userNetwork",
  userProfile: "userProfile",
  userCalendar: "userCalendar",
  adminDrawer: "adminDrawer",
  companyDetailsOverview: "companyDetailsOverview",
  companyDetailsEdit: "companyDetailsEdit",
  companyHomeOverview: "companyHomeOverview",
  companyHomeEdit: "companyHomeEdit",
  workspaceEventsOverview: "workspaceEventsOverview",
  workspaceEventsEdit: "workspaceEventsEdit",
  workspaceDocsCreate: "workspaceDocsCreate",
  workspaceDocsEdit: "workspaceDocsEdit",
  workspaceDetailsOverview: "workspaceDetailsOverview",
  workspaceDetailsEdit: "workspaceDetailsEdit",
  workspaceHomeOverview: "workspaceHomeOverview",
  workspaceHomeEdit: "workspaceHomeEdit",
  workspaceStagesOverview: "workspaceStagesOverview",
  workspaceStagesEdit: "workspaceStagesEdit",
  workspaceUsersOverview: "workspaceUsersOverview",
  workspaceUsersAdd1: "workspaceUsersAdd1",
  workspaceUsersAdd2: "workspaceUsersAdd2",
};

export const INSTRUCTIONS_STEP = {
  selectWorkspace: "selectWorkspace",
  selectCompany: "selectCompany",
  messenger: "messenger",
  topRight: "topRight",
  start: "start",
  drawer: "drawer",
  tileInfoButton: "tileInfoButton",
  userNetworkSearchInput: "userNetworkSearchInput",
  userNetworkSearchButton: "userNetworkSearchButton",
  userCalendarEventCreate: "userCalendarEventCreate",
  userCalendarEventEdit: "userCalendarEventEdit",
  userCalendarEventDelete: "userCalendarEventDelete",
  userCalendarEventStart: "userCalendarEventStart",
  userCalendarDownloadIcs: "userCalendarDownloadIcs",
  userProfileLanguage: "userProfileLanguage",
  userProfileDataFields: "userProfileDataFields",
  userProfileNotifications: "userProfileNotifications",
  userProfilePicture: "userProfilePicture",
  userProfileTakePicture: "userProfileTakePicture",
  adminDrawerCompanyDetails: "adminDrawerCompanyDetails",
  adminDrawerCompanyHome: "adminDrawerCompanyHome",
  adminDrawerWorkspaceDetails: "adminDrawerWorkspaceDetails",
  adminDrawerWorkspaceHome: "adminDrawerWorkspaceHome",
  adminDrawerWorkspaceDocs: "adminDrawerWorkspaceDocs",
  adminDrawerWorkspaceEvents: "adminDrawerWorkspaceEvents",
  adminDrawerWorkspaceStages: "adminDrawerWorkspaceStages",
  adminDrawerWorkspaceUsers: "adminDrawerWorkspaceUsers",
  adminDrawerWorkspaceCreate: "adminDrawerWorkspaceCreate",
  companyDetailsOverviewButton: "companyDetailsOverviewButton",
  companyDetailsEditName: "companyDetailsEditName",
  companyDetailsEditPublicUrl: "companyDetailsEditPublicUrl",
  companyDetailsEditContactData: "companyDetailsEditContactData",
  companyDetailsEditTitleText: "companyDetailsEditTitleText",
  companyDetailsEditPublic: "companyDetailsEditPublic",
  companyDetailsEditPrivate: "companyDetailsEditPrivate",
  companyDetailsEditImages: "companyDetailsEditImages",
  companyHomeOverview: "companyHomeOverview",
  companyHomeEditTileFunction: "companyHomeEditTileFunction",
  companyHomeEditMainData: "companyHomeEditMainData",
  companyHomeEditBacklink: "companyHomeEditBacklink",
  companyHomeEditBackground: "companyHomeEditBackground",
  workspaceEventsOverview: "workspaceEventsOverview",
  workspaceEventsEditType: "workspaceEventsEditType",
  workspaceEventsEditOrdering: "workspaceEventsEditOrdering",
  workspaceEventsEditName: "workspaceEventsEditName",
  workspaceEventsEditTime: "workspaceEventsEditTime",
  workspaceEventsEditModerator: "workspaceEventsEditModerator",
  workspaceEventsEditAccess: "workspaceEventsEditAccess",
  workspaceEventsEditAttendees: "workspaceEventsEditAttendees",
  workspaceEventsEditSubtitle: "workspaceEventsEditSubtitle",
  workspaceEventsEditDescription: "workspaceEventsEditDescription",
  workspaceEventsEditShortDescription: "workspaceEventsEditShortDescription",
  workspaceEventsEditPreviewImage: "workspaceEventsEditPreviewImage",
  workspaceEventsEditAttendeeLimit: "workspaceEventsEditAttendeeLimit",
  workspaceEventsEditShowLobby: "workspaceEventsEditShowLobby",
  workspaceEventsEditShowGroupChat: "workspaceEventsEditShowGroupChat",
  workspaceEventsEditShowTile: "workspaceEventsEditShowTile",
  workspaceEventsEditSendInvite: "workspaceEventsEditSendInvite",
  workspaceEventsEditRegisterMandatory: "workspaceEventsEditRegisterMandatory",
  workspaceEventsEditShowAttendees: "workspaceEventsEditShowAttendees",
  workspaceEventsEditBackground: "workspaceEventsEditBackground",
  workspaceDocsCreate: "workspaceDocsCreate",
  workspaceDocsEditFile: "workspaceDocsEditFile",
  workspaceDocsEditName: "workspaceDocsEditName",
  workspaceDocsEditUrl: "workspaceDocsEditUrl",
  workspaceDocsEditCheckbox: "workspaceDocsEditCheckbox",
  workspaceDocsEditSave: "workspaceDocsEditSave",
  workspaceDetailsOverview: "workspaceDetailsOverview",
  workspaceDetailsEditName: "workspaceDetailsEditName",
  workspaceDetailsEditPublicUrl: "workspaceDetailsEditPublicUrl",
  workspaceDetailsEditTexts: "workspaceDetailsEditTexts",
  workspaceDetailsEditPublic: "workspaceDetailsEditPublic",
  workspaceDetailsEditPrivate: "workspaceDetailsEditPrivate",
  workspaceDetailsEditUuid: "workspaceDetailsEditUuid",
  workspaceDetailsEditLogo: "workspaceDetailsEditLogo",
  workspaceDetailsEditBackground: "workspaceDetailsEditBackground",
  workspaceDetailsEditMakeDefault: "workspaceDetailsEditMakeDefault",
  workspaceDetailsEditInvite: "workspaceDetailsEditInvite",
  workspaceHomeOverview: "workspaceHomeOverview",
  workspaceHomeEditCheckboxes: "workspaceHomeEditCheckboxes",
  workspaceHomeEditInputs: "workspaceHomeEditInputs",
  workspaceHomeEditReference: "workspaceHomeEditReference",
  workspaceHomeEditBackground: "workspaceHomeEditBackground",
  workspaceHomeEditSave: "workspaceHomeEditSave",
  workspaceStagesOverview: "workspaceStagesOverview",
  workspaceStagesEditType: "workspaceStagesEditType",
  workspaceStagesEditInputs: "workspaceStagesEditInputs",
  workspaceStagesEditPath: "workspaceStagesEditPath",
  workspaceStagesEditActive: "workspaceStagesEditActive",
  workspaceStagesEditDefaultJson: "workspaceStagesEditDefaultJson",
  workspaceStagesEditJson: "workspaceStagesEditJson",
  workspaceStagesEditBackground: "workspaceStagesEditBackground",
  workspaceStagesEditFile: "workspaceStagesEditFile",
  workspaceStagesEditEmbedLink: "workspaceStagesEditEmbedLink",
  workspaceStagesEditOverlay: "workspaceStagesEditOverlay",
  workspaceUsersAddSearch: "workspaceUsersAddSearch",
  workspaceUsersAddUserForm: "workspaceUsersAddUserForm",
  workspaceUsersAddCheckbox: "workspaceUsersAddCheckbox",
  workspaceUsersAddInvite: "workspaceUsersAddInvite",
  workspaceUsersOverviewUsers: "workspaceUsersOverviewUsers",
  workspaceUsersOverviewUsersExtraData: "workspaceUsersOverviewUsersExtraData",
  workspaceUsersOverviewInvite: "workspaceUsersOverviewInvite",
  workspaceUsersOverviewSampleCsv: "workspaceUsersOverviewSampleCsv",
  workspaceUsersOverviewUploadCsv: "workspaceUsersOverviewUploadCsv",
  workspaceUsersOverviewInviteEmail: "workspaceUsersOverviewInviteEmail",
  workspaceUsersOverviewConfirmUpload: "workspaceUsersOverviewConfirmUpload",
};

export const DEFAULT_COMPANY_THEME = {
  text: "#fff",
  icon: "#f59b00",
  menu: "#fff",
  background: "rgba(51, 51, 51, 0.9)",
  companySelect: "#f59b00",
  workspaceSelect: "#f59b00",
  menuText: "#000",
};

export const DEFAULT_BLOCK = {
  icon: "",
  image: "",
  name: "",
  content: "",
  entity_id: "",
  entity_type: "",
  section_type: "",
  order: 0,
  has_form: "",
  details: {
    formColor: "rgba(51, 51, 51, 0.9)",
    footerColor: "#444D56",
    topSpace: "32",
    bottomSpace: "32",
    imageType: "normal",
  },
};

export const EMAIL_TEMPLATE = [
  {
    template_key: "eventOverbookedUser",
    title_key: "email_template_eventOverbookedUser",
    subject: "subject.eventOverbookedUser",
    button: "button.eventOverbookedUser",
  },
  {
    template_key: "eventSignedUpUser",
    title_key: "email_template_eventSignedUpUser",
    subject: "subject.eventSignedUpUser",
    button: "button.eventSignedUpUser",
  },
  {
    template_key: "requestToWorkspaceAccessPending",
    title_key: "email_template_requestToWorkspaceAccessPending",
    subject: "subject.requestToWorkspaceAccessPending",
    button: "button.requestToWorkspaceAccessPending",
  },
  {
    template_key: "requestToWorkspaceAccepted",
    title_key: "email_template_requestToWorkspaceAccepted",
    subject: "subject.requestToWorkspaceAccepted",
    button: "button.requestToWorkspaceAccepted",
  },
  {
    template_key: "requestToJoinWorkspace",
    title_key: "email_template_requestToJoinWorkspace",
    subject: "subject.requestToJoinWorkspace",
    button: "button.requestToJoinWorkspace",
  },
  {
    template_key: "emailConfirmRequest",
    title_key: "email_template_emailConfirmRequest",
    subject: "subject.emailConfirmRequest",
    button: "button.emailConfirmRequest",
  },
  {
    template_key: "passResetRequest",
    title_key: "email_template_passResetRequest",
    subject: "subject.passResetRequest",
    button: "button.passResetRequest",
  },
  {
    template_key: "eventOverbookedAdmin",
    title_key: "email_template_eventOverbookedAdmin",
    subject: "subject.eventOverbookedAdmin",
    button: "button.eventOverbookedAdmin",
  },
  {
    template_key: "userAddedToEvent",
    title_key: "email_template_userAddedToEvent",
    subject: "subject.userAddedToEvent",
    button: "button.userAddedToEvent",
  },
  {
    template_key: "calendarReminder",
    title_key: "email_template_calendarReminder",
    subject: "subject.calendarReminder",
    button: "button.calendarReminder",
  },
  {
    template_key: "welcomeNewUser",
    title_key: "email_template_welcomeNewUser",
    subject: "subject.welcomeNewUser",
    button: "button.welcomeNewUser",
  },
];

export const EmailParameter = [
  {
    value: ":meeting_name",
    title: "parameter.meeting_name",
  },
  {
    value: ":meeting_description",
    title: "parameter.meeting_description",
  },
  {
    value: ":meeting_dates",
    title: "parameter.meeting_dates",
  },
  {
    value: ":company_name",
    title: "parameter.company_name",
  },
  {
    value: ":workspace_name",
    title: "parameter.workspace_name",
  },
  {
    value: ":host_name",
    title: "parameter.host_name",
  },
  {
    value: ":user_name",
    title: "parameter.user_name",
  },
  {
    value: ":from_name",
    title: "parameter.from_name",
  },
  {
    value: ":phone",
    title: "parameter.phone",
  },
  {
    value: ":pin",
    title: "parameter.pin",
  },
  {
    value: ":email",
    title: "parameter.email",
  },
  {
    value: ":pass",
    title: "parameter.pass",
  },
];

export const graphColor = [
  "#F70303",
  "#FC6D06",
  "#FDB80A",
  "#FFE011",
  "#7FFE09",
];
