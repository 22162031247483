import { CONST } from "../../Constants";

export function ParseDocument(raw) {
  try {
    let doc = new Document();
    doc.uuid = raw.uuid;
    doc.name = raw.name;
    doc.description = raw.description;
    doc.is_public = raw.is_public === true || raw.is_public === 1;
    doc.type = raw.type;
    doc.path = raw.path;
    doc.used = raw.used;
    doc.folder_id = raw.folder_id;
    doc.short_description = raw.short_description;
    doc.workspace_id = raw.workspace_id;
    doc.accessibility = raw.accessibility;
    doc.show_daily_notification = raw.show_daily_notification
      ? raw.show_daily_notification
      : 0;
    doc.html_fixed_title = raw.html_fixed_title || 0;
    doc.html_title = raw.html_title || CONST._empty;
    doc.html_width = raw.html_width || 0;
    doc.html_height = raw.html_height || 0;
    doc.is_flexible = raw.is_flexible || 0;
    doc.x_position = raw.x_position || 0;
    doc.y_position = raw.y_position || 0;
    doc.title_color = raw.title_color || "#000000";
    doc.title_size = raw.title_size || 28;
    return doc;
  } catch (e) {
    console.error("Error parsing document", raw);
  }
  return null;
}

export class Document {
  constructor() {
    this.name = CONST._empty;
    this.description = CONST._empty;
    this.short_description = CONST._empty;
    this.is_public = false;
    this.path = CONST._empty;
    this.type = CONST._empty;
    this.used = 0;
    this.workspace_id = CONST._empty;
    this.folder_id = CONST._empty;
    this.uuid = CONST._empty;
    this.accessibility = CONST._empty;
    this.show_daily_notification = 0;
    this.html_fixed_title = 0;
    this.html_height = 0;
    this.html_width = 0;
    this.html_title = CONST._empty;
    this.is_flexible = 0;
    this.x_position = 0;
    this.y_position = 0;
    this.title_color = "#000000";
    this.title_size = 28;
  }

  IsExternal() {
    if (
      this.path.toLowerCase().indexOf("http:") > -1 ||
      this.path.toLowerCase().indexOf("https:") > -1 ||
      this.path.toLowerCase().indexOf("//") > -1
    )
      return true;
    return false;
  }
}

Document.Types = {
  html: "html",
  pdf: "pdf",
  image: "image",
  video: "video",
  text: "text",
  object_view: "object_view",
};
