import {
    APP_MESSAGE_ADD,
    APP_MESSAGE_REMOVE,
    ALERT_MESSAGE_TYPE_SHOW,
    ALERT_MESSAGE_TYPE_HIDE,
    SHOW_OVERLAY_TYPE,
    APP_BASKET_ADD,
    APP_BASKET_DELETE,
    APP_SET_MEETING,
    PUBLICDATA_SUCCESS,
    APP_MEDIA_SHOW,
    APP_MEDIA_HIDE,
    APP_ADMINMODE_SET,
    APP_ENTITIYPICKER,
    APP_ANALYTICS_LOAD,
    APP_SOCIAL_USER_BOARD_OPENED,
    APP_INSTRUCTIONS_SHOW,
    APP_INSTRUCTIONS_HIDE,
    LANDING_PAGE_TEMPLATE,
    APP_ANALYTICS_GET_ALL,
    APP_DRAWER_SET_MODULE,
    APP_CHAT_SET_OPENED,
    APP_SURVEY_SET_QUESTIONS,
    APP_MODAL_CALENDAR_ENTRY,
} from "./../actions/app";
import { STAGE_GET } from "./../actions/stage";
import { initialState } from "initialState.js";
import { CONST } from "../../Constants";
import { PublicData } from "../_models/PublicData";
import { RESPONSE_TYPE } from "../api/_api";
import { ParseEntries } from "../_models/AnalyticsEntry";
import { Modules } from "domain/MobileOverlayActions/MobileOverlayActions";

export function appReducer(state = initialState, action) {
    switch (action.type) {
        case APP_ENTITIYPICKER.SHOW: {
            return {
                ...state,
                entityPicker: true,
                entityPickerVal: action.val,
            };
        }
        case APP_ENTITIYPICKER.HIDE: {
            return {
                ...state,
                entityPicker: false,
                entityPickerVal: action.val,
            };
        }
        case APP_ANALYTICS_LOAD: {
            if (action.status === RESPONSE_TYPE.PENDING) {
                return { ...state, analyticsData: null };
            } else if (action.status === RESPONSE_TYPE.SUCCESS) {
                return {
                    ...state,
                    analyticsData: action.payload
                        ? ParseEntries(action.payload)
                        : [],
                };
            }
            return state;
        }
        case APP_ADMINMODE_SET: {
            let adminMode = state.admin;

            adminMode.SetActive(action.val);

            return { ...state, admin: adminMode };
        }
        case APP_MEDIA_SHOW:
            return {
                ...state,
                media: {
                    path: action.path,
                    options: action.options,
                    settings: action.settings,
                },
            };
        case APP_MEDIA_HIDE:
            return { ...state, media: null };

        case APP_INSTRUCTIONS_SHOW:
            return {
                ...state,
                instructions: action.instructions,
            };
        case APP_INSTRUCTIONS_HIDE:
            return { ...state, instructions: null };

        case PUBLICDATA_SUCCESS: {
            return {
                ...state,
                publicData: new PublicData(action.payload),
            };
        }
        /*#region APP+ALERTMESSAGE*/
        case APP_MESSAGE_ADD:
            return {
                ...state,
                messages: [action.message],
            };
        case APP_MESSAGE_REMOVE:
            //action.key = action.key;

            return {
                ...state,
                messages: state.messages.filter(
                    (msg) => msg.key !== action.key
                ),
            };
        case ALERT_MESSAGE_TYPE_SHOW:
            return {
                ...state,
                alertMessage: {
                    type: ALERT_MESSAGE_TYPE_SHOW,
                    message: action.message,
                },
            };
        case ALERT_MESSAGE_TYPE_HIDE:
            return {
                ...state,
                alertMessage: { type: ALERT_MESSAGE_TYPE_HIDE, message: null },
            };
        case SHOW_OVERLAY_TYPE:
            return {
                ...state,
                overlay: action.data,
            };
        /*#endregion APP+ALERTMESSAGE*/

        case APP_BASKET_ADD:
            //TODO more logic: check if itrem in basket, save object not just strings, save in local storage
            state.basketItems.push(action.val);
            return {
                ...state,
                basketItems: [...state.basketItems],
                messages: [
                    {
                        key: action.val + new Date().getTime(),
                        type: "info",
                        text:
                            "added " +
                            action.val +
                            CONST._space +
                            "Items in basket:" +
                            state.basketItems.length,
                    },
                ],
            };
        case APP_BASKET_DELETE:
            // eslint-disable-next-line
            for (let i in state.basketItems) {
                if (state.basketItems[i] === action.val) {
                    state.basketItems.split(i, 1);
                    break;
                }
            }
            return { ...state, basketItems: [...state.basketItems] };
        case STAGE_GET.SUCCESS: {
            return { ...state, actualStage: action.payload.stage };
        }
        case APP_SET_MEETING:
            return { ...state, actualMeeting: action.val };
        case APP_SOCIAL_USER_BOARD_OPENED:
            return { ...state, socialUserboardOpened: action.val };
        case LANDING_PAGE_TEMPLATE.SUCCESS:
            return { ...state, landingpage_template: action.payload };
        case LANDING_PAGE_TEMPLATE.ERROR:
            return { ...state, landingpage_template: null };
        case APP_ANALYTICS_GET_ALL: {
            if (action.status === RESPONSE_TYPE.PENDING) {
                return { ...state, analyticsData: null };
            } else if (action.status === RESPONSE_TYPE.SUCCESS) {
                return {
                    ...state,
                    allAnalyticsData: action.payload
                        ? ParseEntries(action.payload)
                        : [],
                };
            }
            return state;
        }
        case APP_DRAWER_SET_MODULE:
            if (!action.module)
                return {
                    ...state,
                    drawerModule: action.module,
                    selectedChat: null,
                };
            return { ...state, drawerModule: action.module };
        case APP_CHAT_SET_OPENED:
            return {
                ...state,
                drawerModule: Modules.messenger,
                selectedChat: action.val,
            };
        case APP_SURVEY_SET_QUESTIONS:
            return { ...state, surveyQuestions: action.questions };
        case APP_MODAL_CALENDAR_ENTRY: {
            return { ...state, modalCalendarEntry: action.entry };
        }
        default:
            return state;
    }
}

export const getAlertMessage = (state) => state.app.alertMessage;
export const getMessages = (state) => state.app.messages;
export const GetOverlay = (state) => state.app.overlay;
export const GetBasket = (state) => state.app.basketItems;
export const GetActualStage = (state) => state.app.actualStage;
export const GetActualMeeting = (state) => state.app.actualMeeting;
export const GetPublicData = (state) => state.app.publicData;
export const GetMedia = (state) => state.app.media;
export const GetInstructions = (state) => state.app.instructions;
export const GetAdminMode = (state) => state.app.admin.IsActive();
export const EntityPickerState = (state) => {
  return { shown: state.app.entityPicker, val: state.app.entityPickerVal };
};
export const GetAnalytics = (state) => state.app.analyticsData;
export const IsSocialUserboardOpened = (state) =>
  state.app.socialUserboardOpened;

export const GetTemplate = state => state.app.landingpage_template;

export const GetAnalyticsAll = state => state.app.allAnalyticsData;

export const GetSelectedDrawerModule = state => state.app.drawerModule;
export const GetSelectedChat = state => state.app.selectedChat;

export const GetSurveyQuestions = state => state.app.surveyQuestions;

