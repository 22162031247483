import {
  FOLDERS_GET,
  FOLDERS_UPDATE,
  FOLDERS_CREATE,
  FOLDERS_DELETE,
  FOLDERS_GET_INWORKSPACE,
} from "../actions/folder";

import { initialState } from "initialState.js";
import { ParseFolder } from "_redux/_models/folder";
import { ParseDocument } from "_redux/_models/Document";

export function foldersReducer(state = initialState, action) {
  switch (action.type) {
    /*#region FOLDERS_GET*/
    case FOLDERS_GET.SUCCESS: {
      let docs = [];
      for (let i in action.payload.docs) {
        let doc = ParseDocument(action.payload.docs[i]);
        if (doc) docs.push(doc);
      }
      return {
        ...state,
        pending: false,
        folders: [...action.payload.folders],
        docs: docs,
        parent: action.payload.parent,
      };
    }
    case FOLDERS_GET.PENDING:
      return { ...state, error: null, pending: true };
    case FOLDERS_GET.ERROR:
      return { ...state, pending: false, error: action.info };
    /*#endregion FOLDERS_GET*/
    case FOLDERS_UPDATE.ERROR: {
      // console.log(action)
      return state;
    }
    case FOLDERS_UPDATE.PENDING: {
      // console.log(action)
      return state;
    }
    case FOLDERS_UPDATE.SUCCESS: {
      // console.log(action.payload)
      let folder = action.payload.folder;

      if (folder) {
        folder.pending = false;
        for (let i in state.folders) {
          if (state.folders[i].uuid === folder.uuid) state.folders[i] = folder;
        }
      }

      return {
        ...state,
        pending: false,
        folders: [...state.folders],
      };
    }

    case FOLDERS_CREATE.SUCCESS: {
      let fol = action.payload.folder;
      if (fol) state.folders.push(fol);
      return {
        ...state,
        pending: false,
        folders: [...state.folders],
      };
    }

    case FOLDERS_DELETE.SUCCESS: {
      let toRemove = [];
      for (let i2 in action.payload.list) {
        for (let i in state.folders) {
          if (action.payload.list[i2] === state.folders[i].uuid)
            toRemove.push(i);
        }
      }
      while (toRemove.length > 0) {
        state.folders.splice(toRemove[0], 1);
        toRemove.splice(0, 1);
      }

      return {
        ...state,
        pending: false,
        folders: [...state.folders],
      };
    }

    // /*#region FOLDERS_GET_INWORKSPACE*/
    // case FOLDERS_GET_INWORKSPACE.SUCCESS: {
    //   let fols = [];
    //   for (let i in action.payload.folders) {
    //     let fol = ParseFolder(action.payload.folder[i]);
    //     if (fol) fols.push(fol);
    //   }

    //   return {
    //     ...state,
    //     pending: false,
    //     folders: fols,
    //   };
    // }

    /*#endregion FOLDERS_GET_INWORKSPACE*/
    default:
      return state;
  }
}
export const IsError = (state) => state.documents.error;
export const IsPending = (state) => state.documents.pending;
export const GetFolders = (state) => state.folders.folders;
export const GetDocs = (state) => state.folders.docs;
export const GetParent = (state) => state.folders.parent;
