import React from 'react';
import classes from "./CallState.module.css";
import Icon from 'ui/assets/Icon';
import { Stop as StopSound } from 'SoundPlayer'
import { getUser as GetMyUser } from "_redux/reducers/login";
import { AcceptCall, HangUpCall, RejectCall } from '../../../_ws/CallWebsocket';
import { States } from '../../../_redux/_models/Call';
import { useSelector } from 'react-redux';

export default function CallState({call, user}){

	const me = useSelector(state => GetMyUser(state));
	const _clicked = (e) => {
    e.preventDefault();
    e.stopPropagation();
  }

	const accept = () =>  {
		if(call.state === States.started)
			AcceptCall(me.uuid, user.uuid);
	}

	const end = () => {
		StopSound();
		if(call.state === States.accepted)
			HangUpCall(me.uuid, user.uuid)
		else if(call.state === States.started)
			RejectCall(me.uuid, user.uuid)
	}

	if (!call || call?.state === States.hangedUp || call?.state === States._default)
		return (null);

	if (!call?.hasUser(user.uuid))
		return (null);

	return (
		<div className={classes.CallState}>
			{call?.state === States.started && call.isToUser(user.uuid) ?
				<Icon className="custom" type={Icon.Types.call_waiting}></Icon>
				: null}
			{call?.state === States.started && call.isFromUser(user.uuid) ?
				<Icon className="custom ia accept" onClick={(e) => { _clicked(e); accept() }} type={Icon.Types.call_accept}></Icon>
				: null}
			<Icon className="custom ia end" onClick={(e) => { _clicked(e); end() }} type={Icon.Types.call_end}></Icon>
		</div>
	);
}

