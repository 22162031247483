import { initialState } from "../../initialState";
import { MODAL_OPEN } from "../actions/modal";

export const openModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case MODAL_OPEN:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};
