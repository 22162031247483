import { Link, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import classes from "./HeaderMobile.module.css";
import ContactsList from "domain/MobileOverlayActions/MobileContactModule/ContactsList";
import SurveyFloatingButton from "domain/MobileOverlayActions/SurveyModule/SurveyFloatingButton";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
    GetPublicData,
    GetSelectedDrawerModule,
    IsSocialUserboardOpened,
} from "_redux/reducers/app";
import { GetActual as GetActualWorkspace } from "_redux/reducers/workspace";
import { CONST } from "Constants";
import i18n from "i18n";
import { Modules } from "domain/MobileOverlayActions/MobileOverlayActions";

export const MobileHeaderActionsPortal = "#mobileHeaderActionsPortal";
export const MobileHeaderTitlePortal = "#mobileHeaderTitlePortal";
export const MobileHeaderLeftActionPortal = "#mobileHeaderLeftActionPortal";


export default function HeaderMobile() {
    const publicData = useSelector((state) => GetPublicData(state));
    const actualWorkspace = useSelector((state) => GetActualWorkspace(state));
    const openedModule = useSelector((state) => GetSelectedDrawerModule(state));
    const socialOpened = useSelector((state) => IsSocialUserboardOpened(state));

    const location = useLocation();

    let logoSrc = publicData.GetLogo();
    let title = actualWorkspace?.name;

    const path = location.pathname;

    if (path === CONST.pathes.user.network) {
        logoSrc = null;
        title = i18n.t("user.network.title");
    }
    if (path === CONST.pathes.user.calendar) {
        logoSrc = null;
        title = i18n.t("calendar.title");
    }
    if (path === CONST.pathes.user.social) {
        logoSrc = null;
        title = "";
    }
    if (
        path === CONST.pathes.terms_use ||
        path === CONST.pathes.imprint ||
        path === CONST.pathes.contact ||
        path === CONST.pathes.data_privacy
    ) {
        logoSrc = null;
        title = i18n.t(path.split("/").join("") + ":title");
    }

    if (openedModule === Modules.messenger) {
        logoSrc = null;
        title = "";
    }

    return (
        <div className={classes.mobileHeader}>
            <div
                className={classes.leftAction}
                id="mobileHeaderLeftActionPortal"
            ></div>
            <div className={classes.center}>
                <div className={classes.logo + " bgimg"}>
                    {/*<Link to={logoRedirectPath}>*/}
                    {logoSrc ? (
                        <img src={logoSrc} alt="Logo" />
                    ) : (
                        <i className="icon-klarland"></i>
                    )}
                    {/*</Link>*/}
                </div>

                <div
                    className={classes.workspaceName}
                    id="mobileHeaderTitlePortal"
                >
                    <div>{title}</div>
                </div>
            </div>
            <div className={classes.actions}>
                <div id="mobileHeaderActionsPortal">
                    {logoSrc && <ContactsList></ContactsList>}
                </div>
            </div>
        </div>
    );
}
