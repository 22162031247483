
export const CALENDAR_GET = {
  PENDING : 'CALENDAR_GET_PENDING',
  SUCCESS : 'CALENDAR_GET_SUCCESS',
  ERROR : 'CALENDAR_GET_ERROR'
}
export const CALENDAR_UPDATE = {
  PENDING : 'CALENDAR_UPDATE_PENDING',
  SUCCESS : 'CALENDAR_UPDATE_SUCCESS',
  ERROR : 'CALENDAR_UPDATE_ERROR'
};
export const CALENDAR_ADD = {
  PENDING : 'CALENDAR_ADD_PENDING',
  SUCCESS : 'CALENDAR_ADD_SUCCESS',
  ERROR : 'CALENDAR_ADD_ERROR'
};
export const CALENDAR_DELETE = {
  PENDING : 'CALENDAR_DELETE_PENDING',
  SUCCESS : 'CALENDAR_DELETE_SUCCESS',
  ERROR : 'CALENDAR_DELETE_ERROR'
};

export const CALENDAR_ADD_MEETING = {
  PENDING : 'CALENDAR_ADD_MEETING_PENDING',
  SUCCESS : 'CALENDAR_ADD_MEETING_SUCCESS',
  ERROR : 'CALENDAR_ADD_MEETING_ERROR'
};


export function _get(type, info, payload) {  
  return {type: CALENDAR_GET[type],info: info,payload: payload};	
}
export function _delete(type, info, payload) {  
  return {type: CALENDAR_DELETE[type],info: info,payload: payload};	
}
export function _update(type, info, payload) {  
  return {type: CALENDAR_UPDATE[type],info: info,payload: payload};	
}
export function _add(type, info, payload) {  
  return {type: CALENDAR_ADD[type],info: info,payload: payload};	
}
export function _addMeeting(type, info, payload) {  
  return {type: CALENDAR_ADD_MEETING[type],info: info,payload: payload};	
}
