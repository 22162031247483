import { GenericAction } from "./api";

export const SOCIAL_MESSAGE_GET_IN_PINBOAD = {
  PENDING: "SM_GET_PIN_PENDING",
  SUCCESS: "SM_GET_PIN_SUCCESS",
  ERROR: "SM_GET_PIN_ERROR",
};
export const SOCIAL_MESSAGE_SET_TO_PINBOAD = {
  PENDING: "SM_SET_PIN_PENDING",
  SUCCESS: "SM_SET_PIN_SUCCESS",
  ERROR: "SM_SET_PIN_ERROR",
};
export const SOCIAL_MESSAGE_CREATE = {
  PENDING: "SM_CREATE_PENDING",
  SUCCESS: "SM_CREATE_SUCCESS",
  ERROR: "SM_CREATE_ERROR",
};
export const SOCIAL_MESSAGE_DELETE = {
  PENDING: "SM_DELETE_PENDING",
  SUCCESS: "SM_DELETE_SUCCESS",
  ERROR: "SM_DELETE_ERROR",
};
export const SOCIAL_MESSAGE_UPDATE = {
  PENDING: "SM_UPDATE_PENDING",
  SUCCESS: "SM_UPDATE_SUCCESS",
  ERROR: "SM_UPDATE_ERROR",
};
export const SOCIAL_LIKE_CREATE = {
  PENDING: "SL_CREATE_PENDING",
  SUCCESS: "SL_CREATE_SUCCESS",
  ERROR: "SL_CREATE_ERROR",
};
export const SOCIAL_LIKE_SET = {
  PENDING: "SL_SET_PENDING",
  SUCCESS: "SL_SET_SUCCESS",
  ERROR: "SL_SET_ERROR",
};
export const SOCIAL_LIKE_GET = {
  PENDING: "SL_GET_PENDING",
  SUCCESS: "SL_GET_SUCCESS",
  ERROR: "SL_GET_ERROR",
};
export const SOCIAL_MESSAGE_COMMENTS_GET = {
  PENDING: "SM_COMMENTS_GET_PENDING",
  SUCCESS: "SM_COMMENTS_GET_SUCCESS",
  ERROR: "SM_COMMENTS_GET_ERROR",
};
export const SOCIAL_MESSAGE_USER = {
  PENDING: "SOCIAL_MESSAGE_USER_PENDING",
  SUCCESS: "SOCIAL_MESSAGE_USER_SUCCESS",
  ERROR: "SOCIAL_MESSAGE_USER_ERROR",
};

export const SOCIAL_MESSAGE_GET={
  PENDING: 'SOCIAL_MESSAGE_GET_PENDING',
  SUCCESS: 'SOCIAL_MESSAGE_GET_SUCCESS',
  ERROR: 'SOCIAL_MESSAGE_GET_ERROR'
}

export const SOCIAL_MESSAGE_UNFOLLOW={
  PENDING: 'SOCIAL_MESSAGE_UNFOLLOW_PENDING',
  SUCCESS: 'SOCIAL_MESSAGE_UNFOLLOW_SUCCESS',
  ERROR: 'SOCIAL_MESSAGE_UNFOLLOW_ERROR'
}
export const SOCIAL_MESSAGE_FOLLOW={
  PENDING: 'SOCIAL_MESSAGE_FOLLOW_PENDING',
  SUCCESS: 'SOCIAL_MESSAGE_FOLLOW_SUCCESS',
  ERROR: 'SOCIAL_MESSAGE_FOLLOW_ERROR'
}
export const SOCIAL_MESSAGE_HIDE={
  PENDING: 'SOCIAL_MESSAGE_HIDE_PENDING',
  SUCCESS: 'SOCIAL_MESSAGE_HIDE_SUCCESS',
  ERROR: 'SOCIAL_MESSAGE_HIDE_ERROR'
}

export const SOCIAL_ACTUAL_SHARING = "SOCIAL_ACTUAL_SHARING";
export const SOCIAL_MESSAGE_VIEW_ADD = "SOCIAL_MESSAGE_VIEW_ADD";



export function _getInPinboard(type, info, payload) {
  const action = SOCIAL_MESSAGE_GET_IN_PINBOAD;
  return GenericAction(
    type,
    info,
    payload,
    action.PENDING,
    action.SUCCESS,
    action.ERROR
  );
}
export function _setToPinboard(type, info, payload) {
  const action = SOCIAL_MESSAGE_SET_TO_PINBOAD;
  return GenericAction(
    type,
    info,
    payload,
    action.PENDING,
    action.SUCCESS,
    action.ERROR
  );
}


export function _getMessage(type, info, payload) {
  const action = SOCIAL_MESSAGE_GET;
  return GenericAction(type, info, payload, action.PENDING, action.SUCCESS, action.ERROR);
}

export function _createMessage(type, info, payload) {

  const action = SOCIAL_MESSAGE_CREATE;
  return GenericAction(
    type,
    info,
    payload,
    action.PENDING,
    action.SUCCESS,
    action.ERROR
  );
}

export function _deleteMessage(type, info, payload) {
  const action = SOCIAL_MESSAGE_DELETE;
  return GenericAction(
    type,
    info,
    payload,
    action.PENDING,
    action.SUCCESS,
    action.ERROR
  );
}
export function _updateMessage(type, info, payload) {
  const action = SOCIAL_MESSAGE_UPDATE;
  return GenericAction(
    type,
    info,
    payload,
    action.PENDING,
    action.SUCCESS,
    action.ERROR
  );
}

export function _createLike(type, info, payload) {
  const action = SOCIAL_LIKE_CREATE;
  return GenericAction(
    type,
    info,
    payload,
    action.PENDING,
    action.SUCCESS,
    action.ERROR
  );
}

export function _setLike(type, info, payload) {
  const action = SOCIAL_LIKE_SET;
  return GenericAction(
    type,
    info,
    payload,
    action.PENDING,
    action.SUCCESS,
    action.ERROR
  );
}

export function _getLike(type, info, payload) {
  const action = SOCIAL_LIKE_GET;
  return GenericAction(
    type,
    info,
    payload,
    action.PENDING,
    action.SUCCESS,
    action.ERROR
  );
}

export function _getMessageComments(type, info, payload) {
  const action = SOCIAL_MESSAGE_COMMENTS_GET;
  return GenericAction(
    type,
    info,
    payload,
    action.PENDING,
    action.SUCCESS,
    action.ERROR
  );
}

export function _getUserMessages(type, info, payload) {
  const action = SOCIAL_MESSAGE_USER;
  return GenericAction(
    type,
    info,
    payload,
    action.PENDING,
    action.SUCCESS,
    action.ERROR
  );
}

export function _getNewUserMessages(type, info, payload) {
  const action = SOCIAL_MESSAGE_USER;
  return GenericAction(
    type,
    info,
    payload,
    action.PENDING,
    action.SUCCESS,
    action.ERROR
  );
}

export function _unfollow(type, info, payload) {
  const action = SOCIAL_MESSAGE_UNFOLLOW;
  return GenericAction(
    type,
    info,
    payload,
    action.PENDING,
    action.SUCCESS,
    action.ERROR
  );
}

export function _follow(type, info, payload) {
  const action = SOCIAL_MESSAGE_FOLLOW;
  return GenericAction(
    type,
    info,
    payload,
    action.PENDING,
    action.SUCCESS,
    action.ERROR
  );
}

export function _hide(type, info, payload) {
  const action = SOCIAL_MESSAGE_HIDE;
  console.log(type, info, payload)
  return GenericAction(
    type,
    info,
    payload,
    action.PENDING,
    action.SUCCESS,
    action.ERROR
  );
}

export function addViewToMessage(msg_id) {
  return { type: SOCIAL_MESSAGE_VIEW_ADD, val:msg_id };
}


export function setActualSharing(entity, options = null) {
  return { type: SOCIAL_ACTUAL_SHARING, entity: entity, options: options };
}

