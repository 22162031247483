import { RESPONSE_TYPE, _setToken } from "./../api/_api.js";

export const AUTH_PENDING = "AUTH_PENDING";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_ERROR = "AUTH_ERROR";

export const LOGIN_PENDING = "LOGIN_PENDING";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";

export const LOGOUT_PENDING = "LOGOUT_PENDING";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_ERROR = "LOGOUT_ERROR";

export const PASS_RESET_REQUEST_SUCCESS = "PASS_RESET_REQUEST_SUCCESS";
export const PASS_RESET_REQUEST_ERROR = "PASS_RESET_REQUEST_ERROR";
export const PASS_RESET_REQUEST_PENDING = "PASS_RESET_REQUEST_PENDING";

export const PASS_RESET_SUCCESS = "PASS_RESET_SUCCESS";
export const PASS_RESET_ERROR = "PASS_RESET_ERROR";
export const PASS_RESET_PENDING = "PASS_RESET_PENDING";

export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_ERROR = "REGISTER_ERROR";
export const REGISTER_PENDING = "REGISTER_PENDING";

export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_UPDATE_ERROR = "USER_UPDATE_ERROR";
export const USER_UPDATE_PENDING = "USER_UPDATE_PENDING";

export const USER_INVITE_SUCCESS = "USER_INVITE_SUCCESS";
export const USER_INVITE_ERROR = "USER_INVITE_ERROR";
export const USER_INVITE_PENDING = "USER_INVITE_PENDING";

export const CONFIRM_EMAIL_PENDING = "CONFIRM_EMAIL_PENDING";
export const CONFIRM_EMAIL_SUCCESS = "CONFIRM_EMAIL_SUCCESS";
export const CONFIRM_EMAIL_ERROR = "CONFIRM_EMAIL_ERROR";

export const REQUEST_CONFIRM_EMAIL_SUCCESS = "REQUEST_CONFIRM_EMAIL_SUCCESS";
export const REQUEST_CONFIRM_EMAIL_ERROR = "REQUEST_CONFIRM_EMAIL_ERROR";
export const REQUEST_CONFIRM_EMAIL_PENDING = "REQUEST_CONFIRM_EMAIL_PENDING";

export const USER_GET_ME_SUCCESS = "USER_GET_ME_SUCCESS";
export const USER_GET_ME_ERROR = "USER_GET_ME_ERROR";
export const USER_GET_ME_PENDING = "USER_GET_ME_PENDING";

export const UPDATE_USER_INSTRUCTIONS = {
  PENDING: "UPDATE_USER_INSTRUCTIONS_PENDING",
  SUCCESS: "UPDATE_USER_INSTRUCTIONS_SUCCESS",
  ERROR: "UPDATE_USER_INSTRUCTIONS_ERROR",
};

export function fetchRequestConfirmEmail(type, info, payload) {
  if (type === RESPONSE_TYPE.PENDING)
    return { type: CONFIRM_EMAIL_PENDING, payload: info };
  else if (type === RESPONSE_TYPE.SUCCESS)
    return {
      type: REQUEST_CONFIRM_EMAIL_SUCCESS,
      info: info,
      payload: payload,
    };
  else if (type === RESPONSE_TYPE.ERROR)
    return { type: REQUEST_CONFIRM_EMAIL_ERROR, info: info, payload: payload };
}

export function fetchConfirmEmail(type, info, payload) {
  if (type === RESPONSE_TYPE.PENDING)
    return { type: CONFIRM_EMAIL_PENDING, payload: info };
  else if (type === RESPONSE_TYPE.SUCCESS)
    return { type: CONFIRM_EMAIL_SUCCESS, info: info, payload: payload };
  else if (type === RESPONSE_TYPE.ERROR)
    return { type: CONFIRM_EMAIL_ERROR, info: info, payload: payload };
}

export function _invite(type, info, payload) {
  if (type === RESPONSE_TYPE.PENDING)
    return { type: USER_INVITE_PENDING, payload: info };
  else if (type === RESPONSE_TYPE.SUCCESS)
    return { type: USER_INVITE_SUCCESS, info: info, payload: payload };
  else if (type === RESPONSE_TYPE.ERROR)
    return { type: USER_INVITE_ERROR, info: info, payload: payload };
}

export function fetchAuth(type, info, payload) {
  if (type === RESPONSE_TYPE.PENDING) {
    return { type: AUTH_PENDING, payload: info }; //login pending location
  } else if (type === RESPONSE_TYPE.SUCCESS) {
    _setToken(payload.token);
    return {
      type: AUTH_SUCCESS,
      info: info,
      payload: payload,
    };
  } else if (type === RESPONSE_TYPE.ERROR) {
    return {
      type: AUTH_ERROR,
      info: info,
      payload: payload,
    };
  }
}

export function fetchLogin(type, info, payload) {
  if (type === RESPONSE_TYPE.PENDING) {
    return { type: LOGIN_PENDING };
  } else if (type === RESPONSE_TYPE.SUCCESS) {
    _setToken(payload.token);
    return {
      type: LOGIN_SUCCESS,
      info: info,
      payload: payload,
    };
  } else if (type === RESPONSE_TYPE.ERROR) {
    return {
      type: LOGIN_ERROR,
      info: info,
      payload: payload,
    };
  }
}

export function fetchLogout(type, info, payload) {
  if (type === RESPONSE_TYPE.PENDING) {
    return { type: LOGOUT_PENDING };
  } else if (type === RESPONSE_TYPE.SUCCESS) {
    _setToken(payload.token);
    return {
      type: LOGOUT_SUCCESS,
      info: info,
      payload: payload,
    };
  } else if (type === RESPONSE_TYPE.ERROR) {
    return {
      type: LOGOUT_ERROR,
      info: info,
      payload: payload,
    };
  }
}

export function fetchPassResetRequest(type, info, payload) {
  if (type === RESPONSE_TYPE.PENDING) {
    return { type: PASS_RESET_REQUEST_PENDING };
  } else if (type === RESPONSE_TYPE.SUCCESS) {
    return {
      type: PASS_RESET_REQUEST_SUCCESS,
      info: info,
      payload: payload,
    };
  } else if (type === RESPONSE_TYPE.ERROR) {
    return {
      type: PASS_RESET_REQUEST_ERROR,
      info: info,
      payload: payload,
    };
  }
}

export function fetchRegister(type, info, payload) {
  if (type === RESPONSE_TYPE.PENDING) {
    return { type: REGISTER_PENDING };
  } else if (type === RESPONSE_TYPE.SUCCESS) {
    return {
      type: REGISTER_SUCCESS,
      info: info,
      payload: payload,
    };
  } else if (type === RESPONSE_TYPE.ERROR) {
    return {
      type: REGISTER_ERROR,
      info: info,
      payload: payload,
    };
  }
}

export function fetchPassReset(type, info, payload) {
  if (type === RESPONSE_TYPE.PENDING) {
    return { type: PASS_RESET_PENDING };
  } else if (type === RESPONSE_TYPE.SUCCESS) {
    return {
      type: PASS_RESET_SUCCESS,
      info: info,
      payload: payload,
    };
  } else if (type === RESPONSE_TYPE.ERROR) {
    return {
      type: PASS_RESET_ERROR,
      info: info,
      payload: payload,
    };
  }
}

export function fetchUserUpdate(type, info, payload) {
  if (type === RESPONSE_TYPE.PENDING) {
    return { type: USER_UPDATE_PENDING };
  } else if (type === RESPONSE_TYPE.SUCCESS) {
    return {
      type: USER_UPDATE_SUCCESS,
      info: info,
      payload: payload,
    };
  } else if (type === RESPONSE_TYPE.ERROR) {
    return {
      type: USER_UPDATE_ERROR,
      info: info,
      payload: payload,
    };
  }
}

export function fetchMyUser(type, info, payload) {
  if (type === RESPONSE_TYPE.PENDING) {
    return { type: USER_GET_ME_PENDING };
  } else if (type === RESPONSE_TYPE.SUCCESS) {
    return {
      type: USER_GET_ME_SUCCESS,
      info: info,
      payload: payload,
    };
  } else if (type === RESPONSE_TYPE.ERROR) {
    return {
      type: USER_GET_ME_ERROR,
      info: info,
      payload: payload,
    };
  }
}

export function _updateUserInstructions(type, info, payload) {
  return {
    type: UPDATE_USER_INSTRUCTIONS[type],
    info: info,
    payload: payload,
  };
}
