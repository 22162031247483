import { CONST } from "../../Constants";
import { ParseDateTime } from "../../KlarHelper";

export class SocialMessage {
  constructor() {
    this.user_id = CONST._empty;
    this.entity_id =  CONST._empty;
    this.entity_type = CONST._empty


    this.title = CONST._empty;
    this.details = CONST._empty;
    this.uuid = CONST._empty;
    this.comments = 0;
    this.likes = 0;
    this.attachments = [];
    this.cum_likes = 0;
    this.cum_messages = 0;
    this.creator_name = CONST._empty;
    this.creator_claim = CONST._empty;
    this.creator_uuid = CONST._empty;
    this.creator_type = CONST._empty;

    this.entity_name = CONST._empty;
    this.entity_claim = CONST._empty;
    this.entity_url = CONST._empty;
    this.entity_thumb = CONST._empty;

    this.created_at = ParseDateTime("1970-01-01T00:00:1.000000Z")
    this.__type = "social_message";
  }

  isComment(){
    return this.entity_type === 'social_messages';
  }  
}

export function ParseSocialMessage(raw){
  let msg = new SocialMessage();
  for(let i in raw){
    msg[i] = raw[i];
  }
  return msg;
}

