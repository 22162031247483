import React from "react";
import styled from "styled-components/macro";
//import * as S from "../styles";
import { TextInput } from "../styles";

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div`
  display: inline-block;
  width: ${(props) => (props.width ? props.width : "16px")};
  height: ${(props) => (props.height ? props.height : "16px")};
  background: ${(props) =>
    props.checked
      ? "var(--main)"
      : props.bgColor
      ? props.bgColor
      : "var(--gray-light)"};
  border-radius: 3px;
  border: 1px solid var(--bg-dark);
  transition: all 150ms;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px var(--main-semi);
  }

  ${Icon} {
    visibility: ${(props) => (props.checked ? "visible" : "hidden")};
  }
`;

export default function Checkbox({ className, checked, value, ...props }) {
  return (
    <label>
      <CheckboxContainer className={className}>
        <HiddenCheckbox checked={checked} value={value} {...props} />
        <StyledCheckbox
          checked={checked}
          width={props.width}
          height={props.height}
          bgColor={props.bgColor}
        >
          <Icon viewBox="0 0 24 24">
            <polyline points="20 6 9 17 4 12" />
          </Icon>
        </StyledCheckbox>
      </CheckboxContainer>
    </label>
  );
}

export function LabeledCheckbox(props) {
  return (
    <label key={props._key} className={props.className}>
      <Checkbox {...props} />
      <span
        style={{
          color: props.labelColor ? props.labelColor : "var(--gray-light)",
          marginLeft: 6,
        }}
      >
        {props.label}
      </span>
    </label>
  );
}

export function InputCheckbox(props) {
  return (
    <div>
      <Checkbox {...props} />
      <span style={{ marginLeft: "6px" }} />
      <TextInput
        bgColor={props.bgColor}
        color={props.color}
        value={props.value}
        maxWidth="82%"
      />
    </div>
  );
}
