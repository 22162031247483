import { PAGE_GET, PAGE_SET, PAGE_CREATE } from "./../actions/page";
import { initialState } from "initialState.js";
import { ParsePage } from "_redux/_models/Page";

export function pageReducer(state = initialState, action) {
  switch (action.type) {
    case PAGE_GET.SUCCESS: {
      //console.log("Damson", action.payload);

      return {
        ...state,
        pending: false,
        actualPage: ParsePage(action.payload.page),
      };
    }
    case PAGE_SET.SUCCESS: {
      return state;
    }
    case PAGE_CREATE.SUCCESS: {
      return state;
    }
    default:
      return state;
  }
}
export const IsError = (state) => state.page.error;
export const IsPending = (state) => state.page.pending;
export const GetPage = (state) => state.page.actualPage;
