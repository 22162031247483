import React from "react";
import { Paragraph, Spinner } from "../styles";
import i18n from "i18next";

export default function LabeledSpinner(props) {
  return (
    <div style={{ textAlign: "center" }}>
      <Spinner {...props} style={{ marginBottom: 10, margin: "0 auto" }} />
      <br/>
      <Paragraph>{props.label ? props.label : i18n.t("please_wait")}</Paragraph>
    </div>
  );
}
