import { _getNewUserMessages } from "_redux/api/social";
import { store } from "..";
import { notifyListeners } from "./Websocket";

export function Init(socket) {
    /*socket.on(Events.answerConnexionRequest, (data) => {
        notifyListeners(Events.answerConnexionRequest, data);
    });

    socket.on(Events.sendConnexionRequest, (data) => {
        notifyListeners(Events.sendConnexionRequest, data);
    });
    */
    socket.on(Events.socialNotificationSignal, (data) => {
        console.log(data);
        const msgs = store.getState().social.messages;

        console.log(store.getState().social.messages);
        store.dispatch(
            _getNewUserMessages({
                timestamp:
                    msgs?.length > 0
                        ? msgs[0].created_at
                        : "2023-08-28T00:00:00",
            })
        );
        //notifyListeners(Events.socialNotificationSignal, data);
    });
}

export const Events = {
    socialNotificationSignal: "socialNotificationSignal",
};
