export const STAGE_GET={PENDING: 'STAGE_GET_PENDING',SUCCESS: 'STAGE_GET_SUCCESS',ERROR: 'STAGE_GET_ERROR'}
export const STAGE_CREATE={PENDING: 'STAGE_CREATE_PENDING',SUCCESS: 'STAGE_CREATE_SUCCESS',ERROR: 'STAGE_CREATE_ERROR'}
export const STAGE_UPDATE={PENDING: 'STAGE_UPDATE_PENDING',SUCCESS:'STAGE_UPDATE_SUCCESS',ERROR:'STAGE_UPDATE_ERROR'}
export const STAGE_GET_AVAILABLE={PENDING:'STAGE_GET_AVAILABLE_PENDING',SUCCESS:'STAGE_GET_AVAILABLE_SUCCESS',ERROR:'STAGE_GET_AVAILABLE_ERROR'}
export const STAGE_DELETE={PENDING:'STAGE_DELETE_PENDING',SUCCESS:'STAGE_DELETE_SUCCESS',ERROR:'STAGE_DELETE_ERROR'}

export function _get(type, info, payload) {  
  return {type: STAGE_GET[type],info: info,payload: payload};	
}
export function _getAvailable(type, info, payload) {  
  return {type: STAGE_GET_AVAILABLE[type],info: info,payload: payload};	
}
export function _delete(type, info, payload) {  
  return {type: STAGE_DELETE[type],info: info,payload: payload};	
}
export function _update(type, info, payload) {  
  return {type: STAGE_UPDATE[type],info: info,payload: payload};	
}
export function _create(type, info, payload) {  
  return {type: STAGE_CREATE[type],info: info,payload: payload};	
}