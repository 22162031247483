import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Chip, Paragraph, Space } from "../../../../styles";
import {
  AnswerInput,
  InputResponsesWrapper,
  QuestionCardActions,
  QuestionIndicatorContainer,
  QuestionsContainer,
} from "../styles";
import { CardProgressBar } from "../../../../components/ProgressBar";
import { getUser } from "../../../../_redux/reducers/login";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { LabeledCheckbox } from "../../../../components/Checkbox";
import { byId, bySortId } from "../index";
import { _surveyUserAnswer as SurveyUserAnswer } from "../../../../_redux/api/survey";
import LabeledSpinner from "../../../../components/LabeledSpinner";
import fetch from "cross-fetch";
import { GetActual as SelectedWorkspace } from "../../../../_redux/reducers/workspace";
import {
  _API_KEY,
  _getToken,
  Endpoints,
  GetApiURL,
} from "../../../../_redux/api/_api";
import {
  CONST,
  SURVEY_QUESTION_TYPE,
  SURVEY_STATE,
} from "../../../../Constants";
import i18n from "../../../../i18n";
import { LanguageSelector } from "../../../../components/LanguageSelector";
import { LabelContainer } from "../../../Admin/SelectionStages/styles";
import Icon from "ui/assets/Icon";
import { GetDomain } from "KlarHelper";

function QuestionCard(props) {
  const { languageSelected, setLanguageSelected, selectedWorkspace, question } =
    props;
  const [choices, setChoices] = useState(props.question.choices.sort(bySortId));
  const [answers, setAnswers] = useState([]);
  const [totalVotes, setTotalVotes] = useState(0);
  const [answerSubmitted, setAnswerSubmitted] = useState(false);
  const [inputResponses, setInputResponses] = useState([]);
  const inputRef = useRef(null);

  const handleCopyLink = () => {
    let copyLink = `${window.location.origin}/${GetDomain()}`;
    if (selectedWorkspace) {
      copyLink += `/${selectedWorkspace.public_url}/#/survey=${question.uuid}`;
    }
    navigator.clipboard.writeText(copyLink);
  };

  const fetchResults = async () => {
    const response = await fetch(
      GetApiURL() + Endpoints.survey_result_get.path,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": languageSelected,
          "x-api-key": _API_KEY,
          Authorization: "Bearer " + _getToken(),
        },
        body: JSON.stringify({
          survey: {
            uuid: props.question.survey_id,
          },
        }),
      }
    );
    const surveyData = await response.json();
    return surveyData.data.survey.filter((result) => {
      return result.uuid === props.question.uuid;
    })[0];
  };

  useEffect(() => {
    if (
      props.user.uuid === props.idOfSurveyCreator ||
      props.question.state === SURVEY_STATE.PUBLISHED.key ||
      props.question.state === SURVEY_STATE.CLOSED.key
    ) {
      fetchResults().then((data) => {
        setTotalVotes(0);
        setChoices(data.choices.sort(bySortId));
        if (data.responses) {
          setInputResponses(data.responses.sort(byId));
        }
        for (const choice of data.choices) {
          setTotalVotes((totalVotes) => totalVotes + choice.count);
        }
      });
    }
  }, [props.question]);

  const handleAnswerChange = (e) => {
    let eVal = e.target.value;
    if (answers.some((answer) => answer.choice_id === eVal)) {
      setAnswers(answers.filter((answer) => answer?.choice_id !== eVal));
    } else if (props.question.type === SURVEY_QUESTION_TYPE.SINGLE_CHOICE.key) {
      setAnswers([{ choice_id: eVal }]);
    } else {
      setAnswers((answers) => [...answers, { choice_id: eVal }]);
    }
  };

  const submitAnswer = (e) => {
    setAnswerSubmitted(true);
    let reqBody =
      props.question?.type === "text"
        ? {
            survey: {
              uuid: props.question.survey_id,
              questions: [
                {
                  uuid: props.question.uuid,
                  choices: [],
                  response_text: inputRef?.current?.value || "",
                },
              ],
            },
          }
        : {
            survey: {
              uuid: props.question.survey_id,
              questions: [
                {
                  uuid: props.question.uuid,
                  choices: answers,
                },
              ],
            },
          };

    props.SurveyUserAnswer(reqBody, props.languageSelected);
  };

  return (
    <Card indicator={props.question.state}>
      {answerSubmitted ? (
        <LabeledSpinner />
      ) : (
        <>
          <QuestionIndicatorContainer>
            <Chip color={props.question.state}>
              {
                Object.values(SURVEY_STATE).filter(
                  (item) => item.key === props.question.state
                )[0].label
              }
            </Chip>
            {props.user.uuid === props.idOfSurveyCreator &&
              props.question.state === SURVEY_STATE.ACTIVE.key && (
                <Icon
                  type={Icon.Types.link}
                  className={CONST.classes.ia}
                  title="copy"
                  onClick={handleCopyLink}
                ></Icon>
              )}
          </QuestionIndicatorContainer>
          {props.user.uuid === props.idOfSurveyCreator && (
            <LabelContainer>
              <LanguageSelector
                isDark
                languageSelected={languageSelected}
                setLanguageSelected={setLanguageSelected}
              />
            </LabelContainer>
          )}
          <Paragraph color="var(--bg-dark)" bold>
            {props.question.name}
          </Paragraph>
          {props.question.type !== "text" && (
            <QuestionsContainer>
              {props.user.uuid === props.idOfSurveyCreator ||
              props.question.state === SURVEY_STATE.PUBLISHED.key
                ? choices.map((choice, i) => (
                    <div key={i}>
                      <Paragraph
                        bold
                        margin="0px"
                        color="var(--bg-dark)"
                        size="16px"
                      >
                        {choice.name}
                      </Paragraph>
                      <CardProgressBar
                        votes={choice.count}
                        total={totalVotes}
                      />
                    </div>
                  ))
                : choices.map((choice, i) => (
                    <LabeledCheckbox
                      label={choice.name}
                      value={choice.uuid}
                      width="30px"
                      height="30px"
                      checked={answers.some(
                        (answer) => answer.choice_id === choice.uuid
                      )}
                      onChange={(e) => handleAnswerChange(e)}
                      bgColor="var(--bg-dark)"
                      labelColor="var(--bg-dark)"
                      _key={i}
                      key={i}
                    />
                  ))}
            </QuestionsContainer>
          )}
          {props.question?.type === "text" && (
            <QuestionsContainer>
              {props.user.uuid === props.idOfSurveyCreator ||
              props.question.state === SURVEY_STATE.PUBLISHED.key ? (
                <InputResponsesWrapper>
                  {inputResponses.map((response) => (
                    <div key={response.id}>{response.vote_text}</div>
                  ))}
                </InputResponsesWrapper>
              ) : (
                <div>
                  <AnswerInput type="text" ref={inputRef} />
                </div>
              )}
            </QuestionsContainer>
          )}
          {props.user.uuid !== props.idOfSurveyCreator &&
          props.question.state !== SURVEY_STATE.PUBLISHED.key ? (
            <>
              <Space />
              <QuestionCardActions>
                <Button
                  width="100%"
                  color="var(--bg-dark)"
                  onClick={(e) => submitAnswer(e)}
                >
                  {i18n.t("button.submit")}
                </Button>
              </QuestionCardActions>
            </>
          ) : props.user.uuid === props.idOfSurveyCreator ? (
            <>
              <Space />
              <QuestionCardActions borderTop>
                {props.question.state === SURVEY_STATE.ACTIVE.key ? (
                  <>
                    <Button
                      width="100%"
                      paddingSides="5px"
                      color="var(--bg-dark)"
                      onClick={(e) =>
                        props.changeQuestionState(
                          props.question,
                          SURVEY_STATE.DRAFT
                        )
                      }
                    >
                      {i18n.t("button.edit")}
                    </Button>
                    <Space />
                    <Button
                      width="100%"
                      paddingSides="5px"
                      color="var(--bg-dark)"
                      onClick={(e) =>
                        props.changeQuestionState(
                          props.question,
                          SURVEY_STATE.CLOSED
                        )
                      }
                    >
                      {i18n.t("button.end")}
                    </Button>
                  </>
                ) : props.question.state === SURVEY_STATE.CLOSED.key ? (
                  <Button
                    width="100%"
                    paddingSides="5px"
                    color="var(--bg-dark)"
                    onClick={(e) => {
                      props.changeQuestionState(
                        props.question,
                        SURVEY_STATE.PUBLISHED
                      );
                    }}
                  >
                    {i18n.t("button.publish")}
                  </Button>
                ) : (
                  <Button
                    width="100%"
                    paddingSides="5px"
                    color="var(--bg-dark)"
                    onClick={(e) =>
                      props.changeQuestionState(
                        props.question,
                        SURVEY_STATE.CLOSED
                      )
                    }
                  >
                    {i18n.t("button.unpublish")}
                  </Button>
                )}
              </QuestionCardActions>
            </>
          ) : null}
        </>
      )}
    </Card>
  );
}

const mapStateToProps = (state) => ({
  user: getUser(state),
  selectedWorkspace: SelectedWorkspace(state),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ SurveyUserAnswer }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(QuestionCard);
