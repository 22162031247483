export const MEETING_GET = {
  PENDING: "MEETING_GET_PENDING",
  SUCCESS: "MEETING_GET_SUCCESS",
  ERROR: "MEETING_GET_ERROR",
};
export const MEETING_CREATE = {
  PENDING: "MEETING_CREATE_PENDING",
  SUCCESS: "MEETING_CREATE_SUCCESS",
  ERROR: "MEETING_CREATE_ERROR",
};
export const MEETING_UPDATE = {
  PENDING: "MEETING_UPDATE_PENDING",
  SUCCESS: "MEETING_UPDATE_SUCCESS",
  ERROR: "MEETING_UPDATE_ERROR",
};
export const MEETING_GET_AVAILABLE = {
  PENDING: "MEETING_GET_AVAILABLE_PENDING",
  SUCCESS: "MEETING_GET_AVAILABLE_SUCCESS",
  ERROR: "MEETING_GET_AVAILABLE_ERROR",
};
export const MEETING_GET_PAST = {
  PENDING: "MEETING_GET_PAST_PENDING",
  SUCCESS: "MEETING_GET_PAST_SUCCESS",
  ERROR: "MEETING_GET_PAST_ERROR",
};
export const MEETING_DELETE = {
  PENDING: "MEETING_DELETE_PENDING",
  SUCCESS: "MEETING_DELETE_SUCCESS",
  ERROR: "MEETING_DELETE_ERROR",
};
export const MEETING_GET_USER_BY_STREAM = {
  PENDING: "MEETING_GET_USER_BY_STREAM_PENDING",
  SUCCESS: "MEETING_GET_USER_BY_STREAM_SUCCESS",
  ERROR: "MEETING_GET_USER_BY_STREAM_ERROR",
};

export const MEETING_GET_USERS = {
  PENDING: "MEETING_GET_USERS_PENDING",
  SUCCESS: "MEETING_GET_USERS_SUCCESS",
  ERROR: "MEETING_GET_USERS_ERROR",
};
export const MEETING_SET_USERS = {
  PENDING: "MEETING_SET_USERS_PENDING",
  SUCCESS: "MEETING_SET_USERS_SUCCESS",
  ERROR: "MEETING_SET_USERS_ERROR",
};
export const MEETING_GET_USERS1 = {
  PENDING: "MEETING_GET_USERS1_PENDING",
  SUCCESS: "MEETING_GET_USERS1_SUCCESS",
  ERROR: "MEETING_GET_USERS1_ERROR",
};

export const MEETING_SET_RESPONSE = {
  PENDING: "MEETING_SET_RESPONSE_PENDING",
  SUCCESS: "MEETING_SET_RESPONSE_SUCCESS",
  ERROR: "MEETING_SET_RESPONSE_ERROR",
};

export const RESET_CURRENT_MEETING = "RESET_CURRENT_MEETING";

export function _getUsers1(type, info, payload) {
  return { type: MEETING_GET_USERS1[type], info: info, payload: payload };
}
export function _setUsers(type, info, payload) {
  return { type: MEETING_SET_USERS[type], info: info, payload: payload };
}
export function _getUsers(type, info, payload) {
  return { type: MEETING_GET_USERS[type], info: info, payload: payload };
}
export function _getUserByStreamId(type, info, payload) {
  return {
    type: MEETING_GET_USER_BY_STREAM[type],
    info: info,
    payload: payload,
  };
}
export function _getAvailable(type, info, payload) {
  return { type: MEETING_GET_AVAILABLE[type], info: info, payload: payload };
}
export function _getPast(type, info, payload) {
  return { type: MEETING_GET_PAST[type], info: info, payload: payload };
}
export function _get(type, info, payload) {
  return { type: MEETING_GET[type], info: info, payload: payload };
}
export function _delete(type, info, payload) {
  return { type: MEETING_DELETE[type], info: info, payload: payload };
}
export function _update(type, info, payload) {
  return { type: MEETING_UPDATE[type], info: info, payload: payload };
}
export function _create(type, info, payload) {
  return { type: MEETING_CREATE[type], info: info, payload: payload };
}
export function _setResponse(type, info, payload) {
  return { type: MEETING_SET_RESPONSE[type], info: info, payload: payload };
}

export const setHost = () => {
  return { type: "USER_IS_HOST", payload: true };
};
export const setLobby = (data) => {
  return { type: "SET_LOBBY", payload: data };
};

export const _resetCurrentMeeting = () => {
  return { type: RESET_CURRENT_MEETING, payload: null };
};
