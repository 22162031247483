import React from "react";
import { CONST, _ } from "Constants";
import i18n from "i18n";
import Icon from "ui/assets/Icon";
import UserRow from "./UserRow";

import classes from "./MobileInAppMessenger.module.css";

import MobileMessengerChat from "./MessengerChat/MobileMessengerChat";

import {
  DrawerContent,
  DrawerHeader,
  MobileDrawerBody,
  ChatOverlay,
  MobileOverlayActionsDrawer
} from "../styles";
import { Composition } from "atomic-layout";
import GroupRow from "./GroupRow";
import MobileGroupChat from "./GroupChat/MobileGroupChat";
import CreateGroupChat from "./CreateGroupChat";
import { Button } from "../../../styles";
import UserImageAtomic from "./UserImageAtomic";
import MessengerLists from "./MessengerLists";

import { Portal } from "components/Portal";
import {
    MobileHeaderActionsPortal,
    MobileHeaderTitlePortal,
} from "ui/HeaderMobile/HeaderMobile";

function MobileInAppMessengerView({
    active,
    search,
    user,
    state,
    setState,
    toggleDrawer,
    toggleCreateGroup,
    toggleEditGroup,
    actualMeeting,
    groupInformation,
    selectGroupChat,
    groups,
    selectUser,
    call,
    userList,
    selectedChat,
    soundState,
    connectionUsers,
}) {
    return (
        <div
            className={
                classes.root +
                _ +
                (active ? classes.wrapperActive : classes.wrapper)
            }
        >
            {active && (
                <>
                    <Portal
                        selector={MobileHeaderActionsPortal}
                        replaceContent={false}
                    >
                        <div>
                            <div className={classes.headerActionButtons}>
                                <i
                                    className={`icon-bell${
                                        soundState.soundOn ? "" : "-off"
                                    } ia`}
                                    onClick={() =>
                                        soundState.SetSoundOn(
                                            !soundState.soundOn
                                        )
                                    }
                                ></i>
                                <i
                                    className={
                                        classes.addGroupBtn + " icon-plus ia"
                                    }
                                    onClick={() => toggleCreateGroup()}
                                ></i>
                            </div>
                        </div>
                    </Portal>
                    <Portal
                        selector={MobileHeaderTitlePortal}
                        replaceContent={false}
                    >
                        <span>{i18n.t("messenger.title")}</span>
                    </Portal>
                </>
            )}
            <MobileDrawerBody active={active}>
                {state.createGroup ? (
                    <CreateGroupChat
                        userList={userList}
                        toggleCreateGroup={toggleCreateGroup}
                        groupToEdit={state.groupToEdit}
                    />
                ) : (
                    <MessengerLists
                        user={user}
                        state={state}
                        setState={setState}
                        toggleCreateGroup={toggleCreateGroup}
                        toggleEditGroup={toggleEditGroup}
                        actualMeeting={actualMeeting}
                        groupInformation={groupInformation}
                        selectGroupChat={selectGroupChat}
                        groups={groups}
                        selectUser={selectUser}
                        call={call}
                        userList={userList}
                        selectedChat={selectedChat}
                        connectionUsers={connectionUsers}
                    ></MessengerLists>
                )}
            </MobileDrawerBody>

            <ChatOverlay active={selectedChat != null} noTransition={true}>
                {selectedChat?.scope === "group" && (
                    <MobileGroupChat
                        meeting={selectedChat?.entity}
                        onClose={() => selectGroupChat(selectedChat?.entity)}
                        messages={state.messages}
                    />
                )}
                {selectedChat?.scope === "user" && (
                    <MobileMessengerChat
                        user={selectedChat?.entity}
                        call={call}
                        messages={state.messages}
                    />
                )}
            </ChatOverlay>
        </div>
    );
}


export default MobileInAppMessengerView;
