import React, { Component } from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { CONST } from "Constants";
import i18n from "i18n";
import { EditSurveyButton } from "./styles";
import { Paragraph, Space } from "../../../../styles";
import { Composition } from "atomic-layout";
import {
  _surveyCreate as SurveyCreate,
  _surveyDelete as SurveyDelete,
  _surveyGet as SurveyGet,
  _surveyUpdate as SurveyUpdate,
  _surveyUserGetAll as SurveyUserGetAll,
} from "../../../../_redux/api/survey";
import LabeledSpinner from "../../../../components/LabeledSpinner";
import { GetSurveyInformation } from "../../../../_redux/reducers/survey";
import { GetActual as SelectedWorkspace } from "../../../../_redux/reducers/workspace";
import { GetActual as CurrentMeeting } from "../../../../_redux/reducers/meeting";
import LabeledTextInput from "../../../../components/LabeledTextInput";
//import LabeledSelect from "../../../../components/LabeledSelect";
//import LabeledDatePicker from "../../../../components/LabeledDatePicker";
import { DateTime } from "luxon";
import { ParseDateTime } from "../../../../KlarHelper";
import { LanguageSelector } from "../../../../components/LanguageSelector";

const SURVEY_TYPE = {
  survey: "survey",
  qna: "qna",
};

const SURVEY_VISIBILITY = {
  public: "public",
  private: "private",
};

class CreateSurvey extends Component {
  constructor(props) {
    super(props);

    this.ref = React.createRef();

    this.state = {
      actualList: 0,
      search: "",
      selectedUsers: new Map(),
      startingUsers: new Map(),
      surveyTitle: null,
      surveyVisibility: SURVEY_VISIBILITY.private,
      surveyType: SURVEY_TYPE.survey,
      endDate: null,
      step: 0,
      loading: false,
      showDelete: false,
    };
    this.createSurvey = this.createSurvey.bind(this);
    this.save = this.save.bind(this);
    this.updateSurvey = this.updateSurvey.bind(this);
    this.deleteSurvey = this.deleteSurvey.bind(this);
    this.toggleDeleteSurvey = this.toggleDeleteSurvey.bind(this);
    this.inputChanged = this.inputChanged.bind(this);
    this.setDate = this.setDate.bind(this);
  }

  componentDidMount() {
    this.componentDidUpdate();
    if (this.props.surveyToEdit) {
      this.props.SurveyGet(
        { survey: { uuid: this.props.surveyToEdit.uuid } },
        this.props.languageSelected
      );
    }
  }

  inputChanged(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  setDate(date) {
    this.setState({ endDate: DateTime.fromJSDate(date) });
  }

  createSurvey() {
    let reqObj = {
      survey: {
        name: this.state.surveyTitle,
        // type: this.state.surveyType,
        type: SURVEY_TYPE.survey,
        // visibility: this.state.surveyVisibility,
        visibility: SURVEY_VISIBILITY.public,
        // end_date: this.state.endDate,
        workspace_id: this.props.selectedWorkspace.uuid,
        meeting_id: this.props.currentMeeting
          ? this.props.currentMeeting.uuid
          : "",
      },
    };
    this.props.SurveyCreate(reqObj).then((res) => {
      this.setState({ ...this.state, loading: false });
      this.props.toggleCreateSurvey(true);
    });
  }

  updateSurvey() {
    this.props
      .SurveyUpdate(
        {
          survey: {
            uuid: this.props.surveyInformation.survey.uuid,
            name: this.state.surveyTitle,
            type: this.state.surveyType,
            // end_date: this.state.endDate,
            visibility: this.state.surveyVisibility,
          },
        },
        this.props.languageSelected
      )
      .then((res) => {
        this.setState({ ...this.state, loading: false });
        this.props.toggleCreateSurvey(true);
      });
  }

  deleteSurvey() {
    this.props
      .SurveyDelete({
        survey: {
          uuid: this.props.surveyInformation.survey.uuid,
        },
      })
      .then((res) => {
        this.setState({ ...this.state, loading: false });
        this.props.toggleCreateSurvey(true);
      });
  }

  toggleDeleteSurvey() {
    this.setState({
      ...this.state,
      showDelete: !this.state.showDelete,
    });
  }

  save() {
    if (!this.props.surveyToEdit) {
      this.setState({ ...this.state, loading: true });
      this.createSurvey();
    } else if (this.props.surveyToEdit && this.props.surveyInformation) {
      this.setState({ ...this.state, loading: true });
      if (
        this.state.surveyTitle !== this.props.surveyInformation.survey.name ||
        this.state.surveyType !== this.props.surveyInformation.survey.type ||
        this.state.surveyVisibility !==
          this.props.surveyInformation.survey.visibility ||
        this.state.endDate !== this.props.surveyInformation.survey.end_date
      ) {
        // ! Here also other parts of the survey can be updated
        this.updateSurvey();
      } else {
        this.props.toggleCreateGroup();
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps?.languageSelected &&
      this.props.languageSelected &&
      this.props.languageSelected !== prevProps.languageSelected &&
      this.props.surveyToEdit.uuid
    ) {
      this.props.SurveyGet(
        { survey: { uuid: this.props.surveyToEdit.uuid } },
        this.props.languageSelected
      );
    }
  }

  render() {
    const buttonLayout = `
      back advance
      delete delete
    `;

    if (
      this.props.surveyInformation &&
      this.props.surveyToEdit &&
      this.props.surveyInformation !== this.previousSurvey
    ) {
      this.previousSurvey = this.props.surveyInformation;

      this.setState({
        ...this.state,
        surveyTitle: this.props.surveyInformation.survey.name,
        surveyVisibility: this.props.surveyInformation.survey.visibility,
        surveyType: this.props.surveyInformation.survey.type,
        endDate: ParseDateTime(this.props.surveyInformation.survey.end_date),
      });
    }

    if (
      this.props.surveyInformation &&
      !this.props.surveyToEdit &&
      this.state.surveyTitle
    ) {
      this.setState({
        actualList: 0,
        search: "",
        selectedUsers: new Map(),
        startingUsers: new Map(),
        surveyTitle: "",
        surveyVisibility: SURVEY_VISIBILITY.private,
        surveyType: SURVEY_TYPE.survey,
        endDate: null,
        step: 0,
        loading: false,
        showDelete: false,
      });
    }
    return (
      <>
        <ul className="drawerNav">
          <li
            className={
              CONST.classes.ia +
              (this.state.actualList === 0
                ? CONST.classes._active
                : CONST._empty)
            }
          >
            <div className="text-container">
              <div className="text">
                {this.props.surveyToEdit
                  ? i18n.t("survey.edit.title")
                  : i18n.t("survey.create.title")}
              </div>
            </div>
            <div className="arrow-container">
              <div className="arrow"></div>
            </div>
          </li>
        </ul>
        {this.state.loading ? (
          <LabeledSpinner />
        ) : this.state.showDelete ? (
          <div className="list" style={{ padding: 15 }}>
            <Paragraph color="var(--bg-dark)" align="center" bold>
              {i18n.t("survey.delete.title")}
            </Paragraph>
            <Composition
              areas={buttonLayout}
              justifyContent="space-between"
              paddingTop="15px"
            >
              {(Areas) => (
                <>
                  <Areas.Back>
                    <EditSurveyButton
                      color="var(--bg-dark)"
                      onClick={this.toggleDeleteSurvey}
                    >
                      {i18n.t("button.abort")}
                    </EditSurveyButton>
                  </Areas.Back>
                  <Areas.Advance>
                    <EditSurveyButton
                      color="var(--bg-dark)"
                      onClick={this.deleteSurvey}
                    >
                      {i18n.t("button.delete")}
                    </EditSurveyButton>
                  </Areas.Advance>
                </>
              )}
            </Composition>
          </div>
        ) : (
          <div className="list" style={{ padding: 15 }}>
            {this.props?.surveyToEdit?.uuid && (
              <LanguageSelector
                languageSelected={this.props.languageSelected}
                setLanguageSelected={this.props.setLanguageSelected}
                isDark
              />
            )}
            <LabeledTextInput
              placeholder={i18n.t("survey.edit.title.label")}
              label={i18n.t("survey.edit.title.label")}
              labelColor="var(--bg-dark)"
              color="var(--bg-dark)"
              bgColor="var(--gray-light)"
              width="100%"
              name="surveyTitle"
              value={this.state.surveyTitle}
              onChange={(e) => this.inputChanged(e)}
            />
            <Space />
            {/*<LabeledDatePicker*/}
            {/*  labelColor="var(--bg-dark)"*/}
            {/*  color="var(--bg-dark)"*/}
            {/*  bgColor="var(--gray-light)"*/}
            {/*  width="100%"*/}
            {/*  showTimeSelect*/}
            {/*  showMonthDropdown*/}
            {/*  timeFormat="HH:mm"*/}
            {/*  dateFormat="MMMM d, yyyy HH:mm"*/}
            {/*  width="350px"*/}
            {/*  label={"End Date"}*/}
            {/*  onChange={this.setDate}*/}
            {/*  selected={*/}
            {/*    !this.state.endDate?.invalid*/}
            {/*      ? this.state.endDate?.toJSDate()*/}
            {/*      : null*/}
            {/*  }*/}
            {/*/>*/}
            {/*<LabeledSelect*/}
            {/*  label={i18n.t("survey.edit.type.label")}*/}
            {/*  labelColor="var(--bg-dark)"*/}
            {/*  color="var(--bg-dark)"*/}
            {/*  bgColor="var(--gray-light)"*/}
            {/*  width="100%"*/}
            {/*  value={this.state.surveyType}*/}
            {/*  name="surveyType"*/}
            {/*  onChange={(e) => this.inputChanged(e)}*/}
            {/*  options={[SURVEY_TYPE.survey, SURVEY_TYPE.qna]}*/}
            {/*/>*/}
            {/*<Space />*/}
            {/*<LabeledSelect*/}
            {/*  label={i18n.t("survey.edit.visibility.label")}*/}
            {/*  labelColor="var(--bg-dark)"*/}
            {/*  color="var(--bg-dark)"*/}
            {/*  bgColor="var(--gray-light)"*/}
            {/*  width="100%"*/}
            {/*  value={this.state.surveyVisibility}*/}
            {/*  name="surveyVisibility"*/}
            {/*  onChange={(e) => this.inputChanged(e)}*/}
            {/*  options={[SURVEY_VISIBILITY.public, SURVEY_VISIBILITY.private]}*/}
            {/*/>*/}
            <Space />
            <Composition areas={buttonLayout} justifyContent="space-between">
              {(Areas) => (
                <>
                  <Areas.Back>
                    <EditSurveyButton
                      color="var(--bg-dark)"
                      onClick={this.props.toggleCreateSurvey}
                    >
                      {i18n.t("button.abort")}
                    </EditSurveyButton>
                  </Areas.Back>
                  <Areas.Advance>
                    <EditSurveyButton
                      color="var(--bg-dark)"
                      onClick={this.save}
                    >
                      {this.props.surveyToEdit
                        ? i18n.t("button.save")
                        : i18n.t("button.create")}
                    </EditSurveyButton>
                  </Areas.Advance>
                  <Areas.Delete>
                    <EditSurveyButton
                      color="var(--bg-dark)"
                      onClick={this.toggleDeleteSurvey}
                      style={{ width: "100%", marginTop: "15px" }}
                    >
                      {i18n.t("button.delete_survey")}
                    </EditSurveyButton>
                  </Areas.Delete>
                </>
              )}
            </Composition>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  surveyInformation: GetSurveyInformation(state),
  selectedWorkspace: SelectedWorkspace(state),
  currentMeeting: CurrentMeeting(state),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      SurveyCreate,
      SurveyGet,
      SurveyUpdate,
      SurveyDelete,
      SurveyUserGetAll,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CreateSurvey);
