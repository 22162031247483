export const INSTRUCTIONS_COMPONENT_STATS = "INSTRUCTIONS_COMPONENT_STATS";
export const CURRENT_INSTRUCTION_LOCATIONS = "CURRENT_INSTRUCTION_LOCATIONS";

export function _setComponentStats(data) {
  return {
    type: INSTRUCTIONS_COMPONENT_STATS,
    data: data,
  };
}

export function _setCurrentInstructionLocations(data) {
  return {
    type: CURRENT_INSTRUCTION_LOCATIONS,
    data: data,
  };
}
