import { CONST } from "./Constants";

let agreed_comfort = GetLocalItem(CONST._cookies.agreed_comfort);


export function SetLocalItem(key, value)
{
	if(key === CONST._cookies.agreed_comfort)
		agreed_comfort = value;

	if(key.split(".")[0] === "comfort" && agreed_comfort === CONST._empty)
	{
		SetLocalItem(CONST._cookies.agreed_comfort, Math.round(new Date().getTime()/1000));
		return;
	}
	localStorage.setItem(key, value);
}

export function GetLocalItem(key)
{
	let v = localStorage.getItem(key);
	return v?v: CONST._empty;
}