import React, { useEffect, useRef, useState } from "react";
import { _ } from "Constants";

import classes from "./UserInfoPopup.module.css";
import { Button, Spinner } from "styles";
import UsersConnectionState from "./../UsersConnectionState";
import { ConnectionTypes, User } from "_redux/_models/User";
import { FindUserByEmail, AddUser } from "_redux/reducers/users";
import { _getUserDetailed } from "_redux/api/users";
import { useDispatch } from "react-redux";
import {
    SendConnexionAnswer,
    SendConnexionRequest,
} from "_ws/ConnexionWebsocket";
import i18n from "i18n";
import { follow, getFollowingState, unfollow } from "_redux/api/social";

export const GetNetworkStateTranslationKey = (state) => {
    if (state === User.ConnectionTypes.REQUESTED_ME)
        return "user.network.connectstate.waiting";
    if (state === User.ConnectionTypes.REQUESTED_I)
        return "user.network.connectstate.requested";
    if (state === User.ConnectionTypes.CONNECTED)
        return "user.network.connectstate.you_are_connected";
    return "";
};

export default function UserInfoPopup({ user, me, className = _ }) {
    const dispatch = useDispatch();
    const [state, setState] = useState({
        socialState: null,
        loadedUser: null,
    });

    const changeFollowState = (subscribed) => {
        if (subscribed) {
            follow({ entity_id: user.uuid, entity_type: "users" }).then((e) => {
                //           console.log("follow", e);
                setState({ ...state, socialState: e.data });
            });
        } else {
            unfollow({ entity_id: user.uuid, entity_type: "users" }).then(
                (e) => {
                    // console.log("unfollow", e);
                    setState({ ...state, socialState: e.data });
                }
            );
        }
    };
    useEffect(() => {
        if (state.socialState == null) {
            getFollowingState({ entity_id: user.uuid }).then((e) => {
                setState({ ...state, socialState: e.data });
            });
        }

        if (!state.loadedUser) {
            //{{url}}/users/details
            //console.log(FindUserByEmail(user?.email))

            _getUserDetailed(
                { email: user.email, uuid: user.uuid },
                dispatch
            ).then((e) => {
                if (e.success && e.data) {
                    // console.log(e.data)
                    AddUser(e.data);
                    setState({ ...state, loadedUser: e.data });
                } else {
                    setState({
                        ...state,
                        loadedUser: {
                            ...user,
                            company: "FoundCompany GmbH",
                            firstname: "Max",
                            lastname: "Mustermmann",
                            position: "Developer",
                        },
                    });
                }
            });
        }
    }, [state]);

    return (
        <div className={classes.root + _ + className}>
            <div className={classes.content}>
                {state.loadedUser ? (
                    <>
                        <div className={classes.meta}>
                            {state.loadedUser.position} -{" "}
                            {state.loadedUser.company}
                        </div>
                        <div className={classes.actions}>
                            {/*state.loadedUser.lang} {state.loadedUser.country*/}
                            {!user?.connectionType && (
                                <div
                                    onClick={() =>
                                        SendConnexionRequest(
                                            state.loadedUser.email
                                        )
                                    }
                                >
                                    {i18n.t("user.network.connect_with", {
                                        name: state.loadedUser.firstname,
                                    })}
                                    <i
                                        className="color_main icon-social"
                                        title={i18n.t("user.network.connect")}
                                    ></i>
                                </div>
                            )}
                            {user?.connectionType && (
                                <div>
                                    <div>
                                        {i18n.t(
                                            GetNetworkStateTranslationKey(
                                                user?.connectionType,
                                                {
                                                    name: state.loadedUser
                                                        .firstname,
                                                }
                                            )
                                        )}
                                    </div>
                                    <div
                                        className="danger"
                                        onClick={() =>
                                            SendConnexionAnswer(
                                                state.loadedUser.email,
                                                false
                                            )
                                        }
                                    >
                                        {i18n.t("user.network.disconnect")}
                                        <i className="icon-cancel"></i>
                                    </div>
                                </div>
                            )}
                            {state.socialState?.subscribed != null && (
                                <div
                                    className={classes.socialState}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        changeFollowState(
                                            !state.socialState?.subscribed
                                        );
                                    }}
                                >
                                    {state.socialState?.subscribed ? (
                                        <>
                                            <div>
                                                {i18n.t("social.following", {
                                                    name: state.loadedUser
                                                        .firstname,
                                                })}
                                            </div>
                                            <div className="danger">
                                                {i18n.t("social.unfollow")}
                                                <i className="icon-social-unfollow_1"></i>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div>
                                                {i18n.t("social.not_following")}
                                            </div>
                                            <div className="color_main">
                                                {i18n.t("social.follow", {
                                                    name: state.loadedUser
                                                        .firstname,
                                                })}
                                                <i className="icon-social-follow"></i>
                                            </div>
                                        </>
                                    )}
                                </div>
                            )}
                        </div>
                    </>
                ) : (
                    <Spinner
                        size="var(--margin-3)"
                        lineWidth="var(--margin-02)"
                    />
                )}
            </div>
        </div>
    );
}
