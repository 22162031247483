import {
  _surveyGet as surveyGet,
  _surveyResultGet as surveyResultGet,
  _surveyQuestionVote as surveyQuestionVote,
  _surveyUserGetAll as surveyUserGetAll,
  _surveyUserAnswer as surveyUserAnswer,
  _surveyWorkspaceGetAll as surveyWorkspaceGetAll,
  _surveyUpdate as surveyUpdate,
  _surveyQuestionUpdate as surveyQuestionUpdate,
  _surveyQuestionOptionUpdate as surveyQuestionOptionUpdate,
  _surveyDelete as surveyDelete,
  _surveyQuestionDelete as surveyQuestionDelete,
  _surveyQuestionOptionDelete as surveyQuestionOptionDelete,
  _surveyQuestionCreate as surveyQuestionCreate,
  _surveyQuestionOptionCreate as surveyQuestionOptionCreate,
  _surveyCreate as surveyCreate,
} from "./../actions/survey";

import { _doRequest, Endpoints } from "./_api.js";

export function _surveyGet(data, languageSelected) {
  return _doRequest(
    Endpoints.survey_get,
    surveyGet,
    data,
    null,
    languageSelected
  );
}

export function _surveyResultGet(data, languageSelected) {
  return _doRequest(
    Endpoints.survey_result_get,
    surveyResultGet,
    data,
    null,
    languageSelected
  );
}

export function _surveyQuestionVote(data) {
  return _doRequest(Endpoints.survey_question_vote, surveyQuestionVote, data);
}

export function _surveyUserGetAll(data, languageSelected) {
  return _doRequest(
    Endpoints.survey_user_get_all,
    surveyUserGetAll,
    data,
    null,
    languageSelected
  );
}

export function _surveyUserAnswer(data, languageSelected) {
  return _doRequest(
    Endpoints.survey_user_answer,
    surveyUserAnswer,
    data,
    null,
    languageSelected
  );
}

export function _surveyWorkspaceGetAll(data, languageSelected) {
  return _doRequest(
    Endpoints.survey_workspace_get_all,
    surveyWorkspaceGetAll,
    data,
    null,
    languageSelected
  );
}

export function _surveyUpdate(data, languageSelected) {
  return _doRequest(
    Endpoints.survey_update,
    surveyUpdate,
    data,
    null,
    languageSelected
  );
}

export function _surveyQuestionUpdate(data, languageSelected) {
  return _doRequest(
    Endpoints.survey_question_update,
    surveyQuestionUpdate,
    data,
    null,
    languageSelected
  );
}

export function _surveyQuestionOptionUpdate(data, languageSelected) {
  return _doRequest(
    Endpoints.survey_question_option_update,
    surveyQuestionOptionUpdate,
    data,
    null,
    languageSelected
  );
}

export function _surveyDelete(data) {
  return _doRequest(Endpoints.survey_delete, surveyDelete, data);
}

export function _surveyQuestionDelete(data) {
  return _doRequest(
    Endpoints.survey_question_delete,
    surveyQuestionDelete,
    data
  );
}

export function _surveyQuestionOptionDelete(data) {
  return _doRequest(
    Endpoints.survey_question_option_delete,
    surveyQuestionOptionDelete,
    data
  );
}

export function _surveyQuestionCreate(data) {
  return _doRequest(
    Endpoints.survey_question_create,
    surveyQuestionCreate,
    data
  );
}

export function _surveyQuestionOptionCreate(data, languageSelected) {
  return _doRequest(
    Endpoints.survey_question_option_create,
    surveyQuestionOptionCreate,
    data,
    languageSelected
  );
}

export function _surveyCreate(data) {
  return _doRequest(Endpoints.survey_create, surveyCreate, data);
}
