import styled from "styled-components/macro";
import { Button } from "../../../../styles";

export const StyledChatBackground = styled.div`
  background-color: var(--white);
  display: block;
  flex: 1 1 auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
`;

export const UserRowStyles = styled.div`
  display: flex;
  align-items: center;
  padding-left: 15px;
`;

export const EditSurveyButton = styled(Button)`
  padding-left: 20px;
  padding-right: 20px;
`;
