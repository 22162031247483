import React from "react";
import classes from "./SurveyFloatingButton.module.css";
import { useSelector } from "react-redux";
import {
    GetSelectedDrawerModule,
    GetSurveyQuestions,
} from "_redux/reducers/app";
import { useDispatch } from "react-redux";
import { setDrawerModule } from "_redux/api/_app";
import { Modules } from "domain/MobileOverlayActions/MobileOverlayActions";
import { Badge } from "ui/assets/Badge/Badge";

export default function SurveyFloatingButton() {
    const dispatch = useDispatch();
    const openedModule = useSelector((state) => GetSelectedDrawerModule(state));
    const surveyQuestions = useSelector((state) => GetSurveyQuestions(state));

    const toggleOpen = (module) => {
        if (openedModule !== module) {
            dispatch(setDrawerModule(module));
        } else {
            //dispatch(setDrawerModule(null));
            //setLeftContactMenuOpen(null);
        }
    };

    //console.log("surveyQuestions", surveyQuestions, openedModule);
    if (openedModule === Modules.survey || surveyQuestions?.length === 0)
        return;

    return (
        <div
            className={"ia " + classes.surveyButton}
            onClick={() => toggleOpen(Modules.survey)}
        >
            {surveyQuestions?.length > 0 && (
                <Badge className={classes.badge}>
                    {surveyQuestions?.length}
                </Badge>
            )}
            <i className="icon-survey"></i>
        </div>
    );
}