import { APP_PRICING_CHANGE } from "../actions/pricing";

const initialState = {
  total_allowed_items: 0,
  total_used_items: 0,
  pr001_pricing: "",
  url: "",
};

export const pricingReducer = (state = initialState, action) => {
  switch (action.type) {
    case APP_PRICING_CHANGE:
      return {
        ...state,
        total_allowed_items: parseInt(
          action?.payload?.data?.total_allowed_items || 0
        ),
        total_used_items: action?.payload?.data?.total_used_items || 0,
        pr001_pricing: action?.payload?.pr001_pricing || action?.payload?.id,
        url: action?.payload?.data.url || "https://klarland.com/pricing-2/",
      };

    default:
      return state;
  }
};
export const GetPricing = (state) => state.pricing;
