import { store } from "..";
import { SendSocketIoRawMessage, notifyListeners } from "./Websocket";
import { _setGroup , _setActualGroup } from "../_redux/actions/websocket";


let remoteCursor;
//eslint-disable-next-line
let sharingState = null;
let sharingGroupId = null;
let user_id = null;
export function Init(socket, _user_id){
  user_id = _user_id;
  socket.on(Events.shareSetSender, (data)=>{
    store.dispatch(_setGroup({group_id:data.group_id, my_uuid:user_id, stats:data.stats}));
    if(user_id === data.sharer_id){
      sharingState = data.action != 'unset'?'share':null;
      sharingGroupId = data.action != 'unset'?data.group_id:null;
      store.dispatch(_setActualGroup({group_id:data.action != 'unset'?data.group_id:null}));  
    }
    if(remoteCursor){
      document.body.removeChild(remoteCursor);
      remoteCursor = null;
    }
    notifyListeners(Events.shareSetSender, data);    
  })
  socket.on(Events.shareSetListener, (data)=>{
    store.dispatch(_setGroup({group_id:data.group_id, my_uuid:user_id, stats:data.stats}));
    if(user_id === data.listener_id){
      //console.log(data)
      sharingState = data.action != 'unset'?'listen':null;
      sharingGroupId = data.action != 'unset'?data.group_id:null;

      store.dispatch(_setActualGroup({group_id:data.action != 'unset'?data.group_id:null}));  
    }
    notifyListeners(Events.shareSetListener, data);    
  }); 

  socket.on(Events.shareU2uSetSender, (data)=>{ 
    console.log("is deprecated and should be never called")   
    store.dispatch(_setGroup({group_id:data.group_id, my_uuid:user_id, stats:data.stats}));
    if(user_id === data.sharer_id){      
      sharingState = data.action != 'unset'?'share':null;
      sharingGroupId = data.action != 'unset'?data.group_id:null;
      store.dispatch(_setActualGroup({group_id:data.action != 'unset'?data.group_id:null}));  
    }
    if(remoteCursor){
      document.body.removeChild(remoteCursor);
      remoteCursor = null;
    }
    notifyListeners(Events.shareU2uSetSender, data);    
  })

  socket.on(Events.shareU2uAddListener, (data)=>{
    console.log("is deprecated and should be never called")   
    store.dispatch(_setGroup({group_id:data.group_id, my_uuid:user_id, stats:data.stats}));
    if(user_id === data.listener_id){
      sharingState = data.action != 'unset'?'listen':null;
      sharingGroupId = data.action != 'unset'?data.group_id:null;
      store.dispatch(_setActualGroup({group_id:data.action != 'unset'?data.group_id:null}));  
    }
    notifyListeners(Events.shareU2uAddListener, data);    
  })

  socket.on(Events.shareUpdateGroup, (data)=>{
    store.dispatch(_setGroup({group_id:data.group_id, my_uuid:user_id, stats:data.stats}));
    notifyListeners(Events.shareUpdateGroup, data);    
  })

  
  socket.on(Events.shareGetState, (data)=>{    
    notifyListeners(Events.shareGetState, data);    
  });


  socket.on(Events.share, (data)=>{
    
    if(store.getState().websocket.actualGroup === data.group_id){
      
      notifyListeners(Events.share, data);      
      if(data.msg?.cursor){
        if(!remoteCursor)
        {
          remoteCursor = document.createElement("div");
          remoteCursor.classList.add("remote_cursor");
          document.body.appendChild(remoteCursor);
          remoteCursor.style.position = 'absolute';
          remoteCursor.style.width = '20px';
          remoteCursor.style.height = '20px';
          remoteCursor.style.top = 10+'px';
          remoteCursor.style.left = 10+'px';
          remoteCursor.style.borderRadius = '50%';
          remoteCursor.style.backgroundColor = 'red';
        }

        remoteCursor.style.left = (data.msg.x*window.innerWidth)+'px';
        remoteCursor.style.top = (data.msg.y*window.innerHeight)+'px';      
      }
      else if(data.msg?.url != null){
        
      }
    }
  })

}

export function SetSharingState(state, group_id)
{
  sharingState = state;
  sharingGroupId = group_id;
}


export function GetShareState({group_id})
{  
  SendSocketIoRawMessage(Events.shareGetState,{group_id:group_id})   
}

export function SetSharer({group_id, user_id, action=true, user_name=null})
{
  //console.log(Events.shareSetSender,{group_id:group_id, sharer_id:user_id, action:action?"set":"unset", user_name:user_name});
  SendSocketIoRawMessage(Events.shareSetSender,{group_id:group_id, sharer_id:user_id, action:action?"set":"unset", user_name:user_name})   
}
export function SetListener({group_id, user_id, action=true})
{
  //console.log(Events.shareSetListener,{group_id:group_id, listener_id:user_id, action:action?"set":"unset"});
  SendSocketIoRawMessage(Events.shareSetListener,{group_id:group_id, listener_id:user_id, action:action?"set":"unset"})   
}

export function SetSharerU2U({other_id, user_id, action=true, user_name=null})
{
  //console.log("is deprecated and should be never called")   
  SendSocketIoRawMessage(Events.shareU2uSetSender,{user_id:other_id, sharer_id:user_id, action:action?"set":"unset", user_name:user_name})   
}
export function SetListenerU2U({other_id, user_id, action=true})
{
  //console.log("is deprecated and should be never called")   
  SendSocketIoRawMessage(Events.shareU2uAddListener,{user_id:other_id, listener_id:user_id, action:action?"set":"unset"})   
}


export function Share({group_id=null, msg})
{
  if(group_id == null)
    group_id = sharingGroupId;
  
  //if(group_id == null)
    //console.error("No group id", msg);
  if(group_id!=null)
    SendSocketIoRawMessage(Events.share,{group_id:group_id, msg:msg})     
}

export const Events = {
  share: 'share',
  shareSetSender: 'shareSetSender',
  shareGetState: 'shareGetState',
  
  shareSetListener: 'shareSetListener',
  shareU2uSetSender:'shareU2uSetSender',
  shareU2uAddListener:'shareU2uAddListener',
  shareUpdateGroup:'shareUpdateGroup'
  
}