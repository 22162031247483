import React, { useEffect, useState } from "react";

import { CONST, _ } from "Constants";
import classes from "./MessengerLists.module.css";
import GroupRow from "./GroupRow";
import { Button } from "styles";
import i18n from "i18n";
import Icon from "ui/assets/Icon";
import UserRow from "./UserRow";
import { GetActual as GetActualWorkspace } from "_redux/reducers/workspace";
import { useSelector } from "react-redux";
import { User, _GetName } from "_redux/_models/User";
import { FindUserByUuid } from "_redux/reducers/users";
import { IsMobile } from "Utils";


const search = (entry, needle) => {
  if(!needle)
    return true;
  needle = needle.toLowerCase();
  return (entry?.firstname
      ?.toLowerCase()
      .includes(needle) ||
    entry?.lastname?.toLowerCase().includes(needle)
  );
};

const sortUserList = (users) => {
    // console.log(users);
    users.sort((a, b) => {
        return _GetName(a.users).localeCompare(_GetName(b.users));
    });

    return users;
};

export default function MessengerLists({
    user,
    state,
    setState,
    toggleCreateGroup,
    toggleEditGroup,
    actualMeeting,
    groupInformation,
    selectGroupChat,
    groups,
    selectUser,
    call,
    userList,
    selectedChat,
    connectionUsers,
}) {
    const [searchOpened, setSearchOpened] = useState(false);
    const [openedGroup, setOpenedGroup] = useState(null);
    const [actualMeetingGroupInformation, setAtualMeetingGroupInformation] =
        useState(null);

    const actualWorkspace = useSelector((state) => GetActualWorkspace(state));
    const allGroups = groups.filter(
        (g) => g.groups?.type != CONST.chat.workspace
    );
    const workspaceGroups = groups.filter(
        (g) => g.groups?.type === CONST.chat.workspace
    );
    const _groups = [workspaceGroups, allGroups];

    const GetWorkspaceThumb = (entry) => {
        if (!actualWorkspace?.publicData?.thumb) return null;
        return (
            actualWorkspace?.publicData?.url +
            actualWorkspace?.publicData?.thumb
        );
    };

    useEffect(() => {
        if (
            actualMeeting &&
            actualMeeting?.message_group_id === groupInformation?.uuid
        )
            setAtualMeetingGroupInformation(groupInformation);
    }, [actualMeeting, groupInformation]);

    const hasRequests =
        connectionUsers[User.ConnectionTypes.REQUESTED_ME].length > 0;
    const hasWaiting =
        process.env.REACT_APP_ENV === "dev" &&
        connectionUsers[User.ConnectionTypes.REQUESTED_I].length > 0;
    //console.log(connectionUsers)
    return (
        <div className={classes.root}>
            {/*<ul className="drawerNav">
        <li className={CONST.classes._active}>
          <div className="text-container">
            {i18n.t("messenger.my_contacts")}
          </div>
          <div className="arrow-container">
            <div className="arrow"></div>
          </div>
        </li>
        </ul>*/}

            {actualMeetingGroupInformation && (
                <div className={classes.section}>
                    <p className={classes.sectionTitle}>Meeting</p>
                    <GroupRow
                        selected={
                            selectedChat?.entity?.uuid ===
                            actualMeetingGroupInformation?.uuid
                        }
                        onClick={() =>
                            selectGroupChat(actualMeetingGroupInformation)
                        }
                        uuid={actualMeetingGroupInformation?.uuid}
                        name={actualMeetingGroupInformation.name}
                    />
                </div>
            )}
            <div className={classes.section}>
                {_groups.map((groups, j) => {
                    return (
                        <React.Fragment key={j}>
                            {j > 0 && (
                                <div className={classes.sectionTitle}>
                                    {i18n.t("messenger.group_chat.title")}
                                    {!IsMobile() && (
                                        <Button
                                            onClick={toggleCreateGroup}
                                            type="main"
                                        >
                                            <i className="icon-plus"></i>
                                            {i18n.t(
                                                "messenger.group_chat.create.btn"
                                            )}
                                        </Button>
                                    )}
                                </div>
                            )}

                            {groups.map((entry, i) => {
                                return (
                                    <div
                                        key={i}
                                        className={
                                            j === 0 ? classes.workspaceRow : _
                                        }
                                    >
                                        <GroupRow
                                            img={
                                                j === 0
                                                    ? GetWorkspaceThumb(entry)
                                                    : null
                                            }
                                            selected={
                                                selectedChat?.entity
                                                    ?.message_group_id ===
                                                entry.message_group_id
                                            }
                                            className={
                                                state.selectedGroupChat
                                                    ? CONST.classes.active
                                                    : CONST.classes._empty
                                            }
                                            onClick={() => {
                                                selectGroupChat(entry);
                                                setOpenedGroup(
                                                    openedGroup != entry
                                                        ? entry
                                                        : null
                                                );
                                            }}
                                            name={entry.groups.name}
                                            uuid={entry.message_group_id}
                                        >
                                            {entry.permission === "admin" && (
                                                <i
                                                    className={
                                                        classes.editGroupIcon +
                                                        " icon-setting-outline ia"
                                                    }
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        toggleEditGroup(entry);
                                                    }}
                                                ></i>
                                            )}
                                            {!IsMobile() &&
                                                entry?.message_group_id ===
                                                    groupInformation?.uuid &&
                                                (openedGroup === entry ? (
                                                    <i
                                                        className={
                                                            classes.groupCollapse +
                                                            _ +
                                                            classes.close +
                                                            " icon-chevron-mini"
                                                        }
                                                    ></i>
                                                ) : (
                                                    <i
                                                        className={
                                                            classes.groupCollapse +
                                                            _ +
                                                            classes.open +
                                                            " icon-chevron-mini"
                                                        }
                                                    ></i>
                                                ))}
                                        </GroupRow>
                                        {openedGroup === entry &&
                                            entry?.message_group_id ===
                                                groupInformation?.uuid && (
                                                <div
                                                    className={
                                                        classes.groupUserList
                                                    }
                                                >
                                                    {sortUserList(
                                                        groupInformation?.users
                                                    ).map(
                                                        (u, i) =>
                                                            user.uuid !=
                                                                u.users
                                                                    ?.uuid && (
                                                                <UserRow
                                                                    user={
                                                                        FindUserByUuid(
                                                                            u
                                                                                .users
                                                                                .uuid
                                                                        )
                                                                            ? FindUserByUuid(
                                                                                  u
                                                                                      .users
                                                                                      .uuid
                                                                              )
                                                                            : u.users
                                                                    }
                                                                    key={i}
                                                                    showNewMessages={
                                                                        false
                                                                    }
                                                                    forceShow={
                                                                        true
                                                                    }
                                                                ></UserRow>
                                                            )
                                                    )}
                                                </div>
                                            )}
                                    </div>
                                );
                            })}
                        </React.Fragment>
                    );
                })}
            </div>
            <div className={classes.section}>
                <p className={classes.sectionTitle}>
                    {i18n.t("messenger.private_chat.title")}
                    {searchOpened ? (
                        <input
                            className={classes.search}
                            type="text"
                            value={state.search}
                            onChange={(e) =>
                                setState({ ...state, search: e.target.value })
                            }
                            placeholder={i18n.t("search.placeholder")}
                            onBlur={() => {
                                setSearchOpened(false);
                                setState({ ...state, search: "" });
                            }}
                        ></input>
                    ) : (
                        <i
                            className="icon-search"
                            onClick={() => setSearchOpened(true)}
                        ></i>
                    )}
                </p>
                {userList
                    .filter((entry) => search(entry, state.search))
                    .map((entry, i) => (
                        <UserRow
                            selected={
                                entry.email === selectedChat?.entity?.email
                            }
                            user={entry}
                            me={user}
                            call={call}
                            key={i}
                            _key={i}
                            onClick={() => selectUser(entry)}
                        />
                    ))}
            </div>
            {(hasRequests || hasWaiting) && (
                <div className={classes.section}>
                    {hasRequests && (
                        <>
                            <p className={classes.sectionTitle}>
                                {i18n.t(
                                    "user.network.connectstate.newRequests"
                                )}
                            </p>
                            {connectionUsers[
                                User.ConnectionTypes.REQUESTED_ME
                            ].map((u) => (
                                <UserRow
                                    user={u}
                                    key={u.uuid}
                                    showNewMessages={false}
                                ></UserRow>
                            ))}
                        </>
                    )}
                    {process.env.REACT_APP_ENV === "dev" && hasWaiting && (
                        <>
                            <p className={classes.sectionTitle}>
                                {i18n.t("user.network.connectstate.requested")}
                            </p>
                            {connectionUsers[
                                User.ConnectionTypes.REQUESTED_I
                            ].map((u) => (
                                <UserRow
                                    user={u}
                                    key={u.uuid}
                                    showNewMessages={false}
                                    forceShow={true}
                                ></UserRow>
                            ))}
                        </>
                    )}
                </div>
            )}
        </div>
    );
}
