import { useRef } from "react";
import { createPortal } from "react-dom";

export const Portal = ({ selector, children, replaceContent = false }) => {
    const el = document.querySelector(selector);
    const target = useRef(el).current;
    const hasMounted = useRef(false);

    //console.log("Portal", selector, el, target, hasMounted.current);
    if (!target && !el) return null;
    if (replaceContent && !hasMounted.current) {
        //if (target) target.innerHTML = "";
        //hasMounted.current = true;
    }

    if (!children) return null;
    //console.log("Portal", selector, target, hasMounted.current, children);
    return createPortal(children, target || el);
};
