import { _ } from "Constants";
import classes from "./ContextMenu.module.css";
import { useEffect, useState } from "react";

export default function ContextMenu({ icon, children }) {
    const [opened, setOpened] = useState(false);

    const clickedOutside = (e) => {
        if (!e.target.closest(`.${classes.root}`)) {
            setOpened(false);
        }
    };

    useEffect(() => {
        if (opened) document.addEventListener("click", clickedOutside);

        return () => document.removeEventListener("click", clickedOutside);
    }, [opened]);

    useEffect(() => {
        setOpened(false);
    }, [children]);

    return (
        <div className={classes.root + (opened ? _ + classes.opened : _)}>
            <div
                className={classes.value}
                onClick={(e) => {
                    e.stopPropagation();
                    setOpened(!opened);
                }}
            >
                {icon}
            </div>
            {opened && (
                <div
                    className={classes.content}
                    onClick={(e) => {
                        e.stopPropagation();
                        setOpened(false);
                    }}
                >
                    {children}
                </div>
            )}
        </div>
    );
}
