import React, { Component } from "react";
import { CONST, _, _ia } from "Constants";
import GroupImage from "../GroupImage";
import classes from "./GroupRow.module.css";
import RemoteSharingStateView from "../UserRow/RemoteSharingStateView/RemoteSharingStateView";
import { useSelector } from "react-redux";
import { GetNewMessagesCount } from "_redux/reducers/message";

const GroupRow = ({uuid, name, onClick, selected = false, className = null, children, img=null}) => {
  const messages = useSelector(state =>GetNewMessagesCount(state)(uuid));

  return (
    <div
      className={classes.root + _ia + (selected ? _ +classes.active : CONST._empty)}
      onClick={onClick}
    >
      <GroupImage messages={messages} img={img}></GroupImage>
      <span className={classes.text}>{name}</span>
      <RemoteSharingStateView groupId={uuid}></RemoteSharingStateView>
      {children && <div className={classes.children}>{children}</div>}
    </div>
  );
}
export default GroupRow;
