// Action Type
export const LOADER = "LOADER";

// Action
export const setLoader = (loading) => {
  return {
    type: LOADER,
    payload: loading,
  };
};
