import React, { useEffect, useState } from "react";
import Icon from "../../../ui/assets/Icon";

import { Button, Space } from "../../../styles";
import CreateSurvey from "./CreateSurvey";
import {
  _surveyQuestionCreate as SurveyQuestionCreate,
  _surveyWorkspaceGetAll as SurveyWorkspaceGetAll,
  _surveyUpdate as SurveyUpdate,
  _surveyResultGet as SurveyResultGet,
  _surveyQuestionUpdate as SurveyQuestionUpdate,
} from "../../../_redux/api/survey";


import { getUser } from "../../../_redux/reducers/login";
import { useDispatch } from "react-redux";
import {
  AllWorkspaceSurveys,
  SurveyDataChanged,
  SendSurveyPing,
} from "../../../_redux/reducers/survey";
import { GetActual as SelectedWorkspace } from "../../../_redux/reducers/workspace";
import SurveyRow from "./SurveyRow";
import { CONST, SURVEY_STATE, _ } from "../../../Constants";
import EditQuestionCard from "./EditQuestionCard";
import QuestionCard from "./QuestionCard";
import i18n from "../../../i18n";
import { Ping, Events as SurveyEvents } from "../../../_ws/SurveyWebsocket";
import { addListener, removeListener } from "_ws/Websocket";
import { canEditWorkspace } from "../../../KlarHelper";
import { ReactSortable } from "react-sortablejs";
import { GetActual as GetActualMeeting } from "_redux/reducers/meeting";
import { GetCompanyTheme } from "_redux/reducers/company";
import { IsPortrait, convertCompanyTheme } from "Utils";
import { GetSelectedLanguage } from "../../../_redux/reducers/language";
import { useSelector } from "react-redux";
import classes from "./SurveyModule.module.css";
import { setSurveyAvailableQuestions } from "_redux/api/_app";
import { GetSurveyQuestions } from "_redux/reducers/app";
import { Portal } from "components/Portal";
import {
    MobileHeaderLeftActionPortal,
    MobileHeaderTitlePortal,
} from "ui/HeaderMobile/HeaderMobile";

export const bySortId = (a, b) => {
    if (a.sort_id < b.sort_id) return -1;
    if (a.sort_id > b.sort_id) return 1;
    return 0;
};

const AUTO_SAVE_DELAY_MS = 3000;

function SurveyModule(props) {
    const dispatch = useDispatch();

    const allWorkspaceSurveys = useSelector((state) => {
        return AllWorkspaceSurveys(state);
    });
    const surveyDataChanged = useSelector((state) => {
        return SurveyDataChanged(state);
    });
    const sendSurveyPing = useSelector((state) => {
        return SendSurveyPing(state);
    });
    const selectedWorkspace = useSelector((state) => {
        return SelectedWorkspace(state);
    });
    const actualMeeting = useSelector((state) => {
        return GetActualMeeting(state);
    });
    const user = useSelector((state) => {
        return getUser(state);
    });
    const _companyTheme = useSelector((state) => {
        return GetCompanyTheme(state);
    });
    const currentLanguage = useSelector((state) => {
        return GetSelectedLanguage(state);
    });
    const nonAdminSurveyQuestions = useSelector((state) => {
        return GetSurveyQuestions(state);
    });

    const [selectedSurvey, setSelectedSurvey] = useState(null);
    // const [surveyState, setSurveyState] = useState(null);
    const [createSurvey, setCreateSurvey] = useState(false);
    const [surveyToEdit, setSurveyToEdit] = useState(null);

    //const [nonAdminSurveyQuestions, setNONADMINSurveyQuestions] = useState([]);
    const [leftMenuIsLoading, setLeftMenuIsLoading] = useState(false);
    const [languageSelected, setLanguageSelected] = useState(currentLanguage);
    const [questionOrderingSaveContdown, setQuestionOrderingSaveContdown] =
        useState(null);
    const [adminQuestions, setAdminQuestions] = useState([]);

    const companyTheme = convertCompanyTheme(_companyTheme);

    useEffect(() => {
        if (selectedWorkspace && allWorkspaceSurveys) {
            addListener(SurveyEvents.pingSurveyGroup, wsPingReceived);
        }

        return () =>
            removeListener(SurveyEvents.pingSurveyGroup, wsPingReceived);
    }, [selectedWorkspace, allWorkspaceSurveys]);

    const wsPingReceived = (data) => {
        let uuid = data.uuid;
        if (uuid.indexOf("#") > -1) uuid = uuid.split("#")[1];
        //console.log(data, uuid)
        dispatch(
            SurveyWorkspaceGetAll({
                survey: {
                    workspace_id: uuid /*props.selectedWorkspace?.uuid*/,
                },
            })
        );
    };

    useEffect(() => {
        // If any data on any survey changed, ping others to fetch data
        //console.log(props.sendSurveyPing);
        if (sendSurveyPing && selectedWorkspace?.uuid) {
            let pingType = sendSurveyPing?.pingType;
            //console.log(pingType, selectedWorkspace?.uuid);
            Ping("ws#" + selectedWorkspace?.uuid, pingType);
        }
    }, [sendSurveyPing]);

    const toggleSurveySelection = (survey) => {
        if (survey && selectedSurvey !== survey) {
            setSelectedSurvey(survey);
            props.toggleLeftMenu("open");
        } else {
            setSelectedSurvey(null);
            props.toggleLeftMenu("close");
        }
    };

    const toggleCreateSurvey = (floodRequest = false) => {
        toggleSurveySelection();
        if (floodRequest === true) {
            setSurveyToEdit(null);
            setCreateSurvey(false);
            return;
        }
        setSurveyToEdit(null);
        if (props.active) {
            setCreateSurvey(!createSurvey);
            return;
        }
        setSurveyToEdit(null);
        setCreateSurvey(true);
    };

    const toggleEditSurvey = (survey) => {
        toggleSurveySelection();
        setSurveyToEdit(survey ? survey : null);
        setCreateSurvey(!createSurvey);
    };

    useEffect(() => {
        if (selectedSurvey) {
            setSelectedSurvey(null);
        }
    }, [props.active]);

    useEffect(() => {
        // If any data on any survey changed, fetch to get new data
        //console.log("Get surveys", props.surveyDataChanged, props.selectedWorkspace);
        if (selectedWorkspace?.uuid) {
            dispatch(
                SurveyWorkspaceGetAll(
                    {
                        survey: {
                            workspace_id: selectedWorkspace?.uuid,
                        },
                    },
                    languageSelected
                )
            );
        }
    }, [surveyDataChanged, selectedWorkspace, languageSelected]);

    useEffect(() => {
        // If survey are fetched, check if one is selected and
        // update the data accordingly
        if (selectedSurvey) {
            setSelectedSurvey(
                allWorkspaceSurveys.survey.find(
                    (survey) => survey.uuid === selectedSurvey.uuid
                )
            );
        }

        // Set the question data for the right non admin drawer area
        if (allWorkspaceSurveys) {
            let questionsArray = [];
            const surveys = allWorkspaceSurveys?.survey?.filter(
                (item) => item.type !== "q_and_a"
              );
            for (let survey of surveys) {
                if (
                    survey.user_id !== user.uuid &&
                    (!survey.meeting_id ||
                        survey.meeting_id === actualMeeting?.uuid)
                ) {
                    for (let question of survey.questions) {
                        if (
                            (question.state === SURVEY_STATE.ACTIVE.key &&
                                !question?.user_response?.length) ||
                            question.state === SURVEY_STATE.PUBLISHED.key
                        ) {
                            questionsArray.push(question);
                        }
                    }
                }
            }

            const qs = questionsArray.sort((a, b) => a.sort_id - b.sort_id);
            dispatch(setSurveyAvailableQuestions(qs));
            //setNONADMINSurveyQuestions(qs);
        }
    }, [allWorkspaceSurveys, actualMeeting]);

    useEffect(() => {
        // Responsible to set the survey state indicators of the edit window
        // setSurveyState(
        //   Object.values(SURVEY_STATE).filter(
        //     (state) => state.key === selectedSurvey?.state
        //   )[0]
        // );

        // Set the question data for the left admin drawer area
        if (selectedSurvey?.questions) {
            setAdminQuestions(
                selectedSurvey?.questions.sort((a, b) => a.sort_id - b.sort_id)
            );
        }
    }, [selectedSurvey]);

    const createSurveyQuestion = (survey_id) => {
        dispatch(
            SurveyQuestionCreate({
                question: {
                    survey_id: survey_id,
                    name: "",
                    type: "",
                    state: SURVEY_STATE.DRAFT.key,
                    anonymous: false,
                    sort_id: adminQuestions[adminQuestions.length - 1]
                        ? adminQuestions[adminQuestions.length - 1].sort_id + 1
                        : 0,
                    options: [],
                },
            })
        );
    };

    const changeSurveyState = (survey, state) => {
        dispatch(
            SurveyUpdate(
                {
                    survey: {
                        uuid: survey.uuid,
                        state: state.key,
                    },
                },
                languageSelected
            )
        );
    };

    const changeQuestionState = (question, state, notify_users) => {
        dispatch(
            SurveyQuestionUpdate({
                question: {
                    uuid: question.uuid,
                    state: state.key,
                    notify_users: notify_users ? "true" : "",
                },
                languageSelected,
            })
        );
    };

    const saveQuestionOrdering = () => {
        setLeftMenuIsLoading(false);
        for (let i in adminQuestions) {
            dispatch(
                SurveyQuestionUpdate(
                    {
                        question: {
                            uuid: adminQuestions[i].uuid,
                            sort_id: i,
                        },
                    },
                    languageSelected
                )
            );
        }
    };

    const changeQuestionOrdering = (event) => {
        setLeftMenuIsLoading(true);
        if (questionOrderingSaveContdown) {
            clearTimeout(questionOrderingSaveContdown);
        }
        setQuestionOrderingSaveContdown(
            setTimeout(saveQuestionOrdering, AUTO_SAVE_DELAY_MS)
        );
    };

    const onChangeQuestionOrderingStart = () => {
        if (questionOrderingSaveContdown) {
            clearTimeout(questionOrderingSaveContdown);
        }
    };

    const handleOpenDrawer = () => {
        if (!props.active) {
            props.toggleOpen(props.value);
        }
    };

    const getAdminQuestionList = () => {
        return adminQuestions?.map((question) => {
            return (
                <div key={question.uuid}>
                    {question.state === SURVEY_STATE.DRAFT.key ? (
                        <EditQuestionCard
                            languageSelected={languageSelected}
                            setLanguageSelected={setLanguageSelected}
                            question={question}
                            idOfSurveyCreator={selectedSurvey?.user_id}
                            changeQuestionState={changeQuestionState}
                        />
                    ) : (
                        <QuestionCard
                            languageSelected={languageSelected}
                            setLanguageSelected={setLanguageSelected}
                            question={question}
                            idOfSurveyCreator={selectedSurvey?.user_id}
                            changeQuestionState={changeQuestionState}
                        />
                    )}

                    <Space />
                </div>
            );
        });
    };
    return (
        <div
            className={classes.root + _ + (props.active ? classes.opened : _)}
            style={{ background: companyTheme.background }}
        >
            {props.active && IsPortrait() ? (
                <>
                    <Portal selector={MobileHeaderLeftActionPortal}>
                        <i
                            className={"icon-backBtn" + " icon-chevron ia"}
                            onClick={(e) => {
                                e.stopPropagation();
                                props.toggleOpen(props.value);
                            }}
                        ></i>
                    </Portal>
                    <Portal selector={MobileHeaderTitlePortal}>
                        <span>{i18n.t("survey")}</span>
                    </Portal>
                </>
            ) : (
                <div
                    className={classes.header}
                    onClick={handleOpenDrawer}
                    style={{ hasContent: nonAdminSurveyQuestions.length > 0 }}
                >
                    <div className={classes.icon}>
                        <i className="icon-survey"></i>
                    </div>
                    <span>{i18n.t("survey")}</span>
                    <Icon
                        className={classes.closeBtn + " ia"}
                        type={Icon.Types.close}
                        color={Icon.Colors.white}
                        onClick={(e) => {
                            e.stopPropagation();
                            props.toggleOpen(props.value);
                        }}
                    />
                </div>
            )}
            <div
                className={
                    classes.body + _ + (props.active ? classes.active : _)
                }
            >
                {createSurvey ? (
                    <CreateSurvey
                        toggleCreateSurvey={toggleCreateSurvey}
                        surveyToEdit={surveyToEdit}
                        languageSelected={languageSelected}
                        setLanguageSelected={setLanguageSelected}
                    />
                ) : (
                    <div className={classes.content}>
                        {canEditWorkspace(selectedWorkspace?.role?.value) ? (
                            <div className={classes.section}>
                                <p className={classes.title}>
                                    {i18n.t("survey.admin.section.title")}
                                </p>

                                <Button
                                    type="main"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        toggleCreateSurvey();
                                        handleOpenDrawer();
                                    }}
                                >
                                    <i className="icon-plus"></i>
                                    {i18n.t("survey.create.title")}
                                </Button>

                                {allWorkspaceSurveys?.survey
                                    ?.filter((item) => item.type !== "q_and_a")
                                    ?.map((survey, i) =>
                                        survey.user_id === user.uuid ? (
                                        <div className="row" key={i}>
                                            <div
                                                style={{
                                                    float: "left",
                                                    width: "75%",
                                                }}
                                            >
                                                <SurveyRow
                                                    key={i}
                                                    _key={i}
                                                    selected={
                                                        selectedSurvey?.uuid ===
                                                        survey.uuid
                                                    }
                                                    className={
                                                        selectedSurvey
                                                            ? CONST.classes
                                                                  .active
                                                            : CONST.classes
                                                                  ._empty
                                                    }
                                                    onClick={() =>
                                                        toggleSurveySelection(
                                                            survey
                                                        )
                                                    }
                                                    name={survey.name}
                                                />
                                            </div>
                                            {/* TODO Include permissions again */}
                                            {/*{entry.permission === "admin" ? (*/}
                                            <div
                                                style={{
                                                    float: "left",
                                                    width: "25%",
                                                    height: "56px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <Icon
                                                    className="ia"
                                                    type={Icon.Types.settings}
                                                    color={Icon.Colors.black}
                                                    onClick={() =>
                                                        toggleEditSurvey(survey)
                                                    }
                                                />
                                            </div>
                                            {/*) : null}*/}
                                        </div>
                                    ) : null
                                )}
                            </div>
                        ) : null}

                        <div className={classes.section}>
                            {nonAdminSurveyQuestions?.map((question, i) => (
                                <React.Fragment key={i}>
                                    <QuestionCard question={question} key={i} />
                                    <Space />
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                )}
            </div>

            <div
                className={
                    classes.left + _ + (props.leftMenuOpen ? classes.opened : _)
                }
            >
                <div className={classes.header}>
                    <Icon
                        className={classes.backBtn + " ia"}
                        type={Icon.Types.chevron_right}
                        color={Icon.Colors.dark}
                        onClick={() => toggleSurveySelection()}
                    />
                    {i18n.t("survey.admin.management.title")}
                    <div className={classes.actions}>
                        {selectedSurvey?.state === SURVEY_STATE.DRAFT.key ||
                        selectedSurvey?.state === SURVEY_STATE.ACTIVE.key ? (
                            <Button
                                type="main"
                                onClick={() =>
                                    createSurveyQuestion(selectedSurvey?.uuid)
                                }
                            >
                                <i className="icon-plus"></i>
                                {i18n.t("button.add_survey_question")}
                            </Button>
                        ) : (
                            <Button
                                type="info"
                                outlined={true}
                                onClick={() =>
                                    changeSurveyState(
                                        selectedSurvey,
                                        SURVEY_STATE.DRAFT
                                    )
                                }
                            >
                                {i18n.t("button.download")}
                            </Button>
                        )}
                    </div>
                </div>
                <div
                    className={classes.body}
                    indicator={SURVEY_STATE.DRAFT.key}
                    loading={leftMenuIsLoading || null}
                >
                    <div className={classes.scrollable}>
                        <ReactSortable
                            list={adminQuestions}
                            setList={setAdminQuestions}
                            onStart={onChangeQuestionOrderingStart}
                            onEnd={(e) => changeQuestionOrdering(e)}
                            animation={200}
                            handle=".drag-handle"
                        >
                            {getAdminQuestionList()}
                        </ReactSortable>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SurveyModule;
