import { CONST } from "./Constants";
import packageJson from '../package.json';
export const SoundTracks = {
	CALL_INCOMING:'CALL_INCOMING',
	CALL_OUTGOING:'CALL_OUTGOING',
	NOTIFICATION:'NOTIFICATION',
}

let audioElement = null;
let notif = null;
let lastKey = CONST._empty;
let lastStart = 0;
let documentClicked = false;

document.addEventListener("click", docClicked);

function docClicked(){
  documentClicked=true;
  document.removeEventListener("click", docClicked);
	Stop();

}
export function Stop()
{
	if(documentClicked && audioElement)
	{
		lastKey = CONST._empty;
		audioElement.pause();
	}
}
export function Play(soundKey)
{
	if((!documentClicked || lastKey === soundKey) && new Date().getTime()-lastStart<10000)
		return;
	if(!audioElement)
	{
		audioElement = document.createElement("audio");
		audioElement.loop=true;
		audioElement.volume = packageJson.settings.sfxVolume;
		audioElement.classList.add("AudioPlayer");
		document.body.append(audioElement);

		notif = document.createElement("audio");
		notif.volume = packageJson.settings.sfxVolume;
		notif.classList.add("AudioPlayer");
		document.body.append(notif);
		notif.src = '/sounds/notification.mp3';
	}

	audioElement.pause();
	if(soundKey === SoundTracks.CALL_INCOMING)
	{
		audioElement.src = '/sounds/call_icoming.mp3';
	}
	if(soundKey === SoundTracks.CALL_OUTGOING)
	{
		audioElement.src = '/sounds/call_outgoing.mp3';
	}
	if(soundKey === SoundTracks.NOTIFICATION)
	{
		notif.play();
	}
	audioElement.play();

	lastKey = soundKey;
	lastStart = new Date().getTime();
}