import React, { useEffect, useState } from "react";

import Icon from "ui/assets/Icon";

import { GetApiURL } from "_redux/api/_api";
import { CONST, _ } from "Constants";
import { User, _GetInitials, _GetName } from "_redux/_models/User";

import classes from "./UserImageAtomic.module.css";
import { GetUserStatus } from "_redux/reducers/users";
import { GetNewMessagesCount } from "_redux/reducers/message";
import { useSelector } from "react-redux";

const cache = new Map();

/**
 * Renders UserThumb with status and a badge with count of unread messages.
 * Requires only the user object with an email address.
 *
 *
 * @param {object} user user:{email, ...}
 * @param {string} user.email email
 * @param {string} [className=] optional
 * @param {string} [size=] optional (default=null, then uses a css variable --size ion the dom tree)
 * @param {string} [showBadge=] optional  (default=true)
*
 */
const UserImageAtomic = ({user = null, className = "", size='var(--margin-4)', showBadge=true, status=null, newMessages=null}) => {

  let messages = useSelector(state =>GetNewMessagesCount(state)(user?.email));

  if(newMessages)
    messages = newMessages;
  const userStatus = useSelector(state =>GetUserStatus(state)(user?.email));
  //console.log(user.email, userStatus)
  if (!user)
    return null;


  let _url = GetApiURL() + "/files/user/" + (user.email) + "/thumb";
  let _alt = _GetName(user);
  let connectionIcon = null;
  if(user.connectionType === User.ConnectionTypes.CONNECTED)
    connectionIcon = "connected";
  else if(user.connectionType === User.ConnectionTypes.REQUESTED_ME)
    connectionIcon = "attention";
  else if(user.connectionType === User.ConnectionTypes.REQUESTED_I)
    connectionIcon = "hourglass";


  const cacheKey = user?.email;
  const cacheKeyDetailed = user?.firstname + _ + user?.lastname + _ + className + _ + size + _ + showBadge + _ + messages + _ +userStatus+_+status+_+connectionIcon;

  //console.log(cacheKey);

  if(!cache.has(cacheKey) || cache.get(cacheKey).detailed != cacheKeyDetailed){
    cache.set(cacheKey,
     {detaild:cacheKeyDetailed,
      el:
     <div className={classes.root + _ + (className ?  className : _)} style={{'--size':size}}>
      <Icon
        className="usericon"
        type={Icon.Types.messenger_user}
        color={Icon.Colors.white}
      ></Icon>
      <img
        src={_url}
        alt={_alt}
        onLoad={(e)=>e.target.classList.remove(classes.imgNotFound)}
        onError={(e) => {
          setTimeout(()=>{
            e.target.classList.add(classes.imgNotFound);
            e.target.alt = _GetInitials(user);
          },500);

        }}
      ></img>
      {<div className={classes.status + _ + (status?status:userStatus)}>{connectionIcon && <i className={"icon-"+connectionIcon}></i>}</div>}
      {showBadge && messages > 0 ? <div className={classes.badge}>{messages}</div> : null}
    </div>
     }
    )
  }
  return cache.get(cacheKey).el;

}


export default UserImageAtomic;

