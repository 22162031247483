import {CALL_STARTED, CALL_ACCEPTED, CALL_REJECTED, CALL_HANGEDUP} from './../actions/call';

import {initialState}  from 'initialState.js';
import { Call, CreateId, States } from '../_models/Call';


export function callReducer(state = initialState, action) {
  switch(action.type) {
    case CALL_STARTED:{

     // console.log(CALL_STARTED, action.payload);

      const id = CreateId(action.payload.from, action.payload.to);
      const call = new Call();
      call.state = States.started;
      call.from = action.payload.from;
      call.to = action.payload.to;
      call.id = id;


      if(state.actualCall){
        state.oldCalls.push(state.actualCall);
        return {...state, actualCall:call, oldCalls:state.oldCalls};
      }
      else
        return {...state, actualCall:call};
    }
    case CALL_ACCEPTED:{
      //console.log(CALL_ACCEPTED, action.payload);
      const id = CreateId(action.payload.from, action.payload.to);

      if(state.actualCall && state.actualCall.id != id){
        //what should we do with an existing call?
        //check state and then?
      }
      const call = new Call();
      call.state = States.accepted;
      call.from = action.payload.from;
      call.to = action.payload.to;
      call.meeting = action.payload.meeting;
      call.id = id;


      return {...state, actualCall:call};
    }
    case CALL_REJECTED:{
      console.log(CALL_REJECTED, action.payload);
      const id = CreateId(action.payload.from, action.payload.to);
      if(state.actualCall && state.actualCall.id != id){
        //what should we do with an existing old call?
      }
      if(state.actualCall?.id === id){
        const call = new Call();
        call.state = States.rejected;
        call.from = action.payload.from;
        call.to = action.payload.to;
        call.id = id;


        state.oldCalls.push(call);
        return {...state, actualCall:null, oldCalls:state.oldCalls};
      }
      return state;
      //return{...state}
    }
    case CALL_HANGEDUP:{
      if (!state.actualCall) {
        return state
      }

      //console.log(CALL_HANGEDUP, action.payload);
      // const id = CreateId(action.payload.from, action.payload.to);

      const oldCall = {
        ...state.actualCall,
        state: States.hangedUp
      }

      const oldCalls = [
        ...state.oldCalls,
        oldCall
      ]

      return {...state, actualCall: null, oldCalls };
    }
		default:
			return state;
	}
}

export const GetActualCall = state => state.call.actualCall;
//export const GetOldCalls = state => state.call.oldCalls;
export const GetOldCalls = (state) => (from, to)=>{
  if(from == null && to == null)
    return state.call.oldCalls;
  const id = CreateId(from, to);
  let calls = [];
  state.call.oldCalls.map(call=>{if(call.id === id)return call;})
  return calls;
}

/*
export const GetSharer = (state) => (group_id)=> state.call.groups[group_id]?.sharer;
*/