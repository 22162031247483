export const CALL_STARTED = 'CALL_STARTED'
export const CALL_ACCEPTED = 'CALL_ACCEPTED'
export const CALL_REJECTED = 'CALL_REJECTED'
export const CALL_HANGEDUP = 'CALL_HANGEDUP'
export const CLOSE_BROADCAST = 'CLOSE_BROADCAST'

export function callState(state, payload) {  
  return {type: state,payload: payload};	
}
export function _hangup(payload) {  
  return {type: CALL_HANGEDUP,payload: payload};	
}
export function _start(payload) {  
  return {type: CALL_STARTED,payload: payload};	
}
export function _reject(payload) {  
  return {type: CALL_REJECTED,payload: payload};	
}
export function _accept(payload) {  
  return {type: CALL_ACCEPTED,payload: payload};	
}
export function _close() {
  return {type: CLOSE_BROADCAST};
}