
export const WEBSOCKET_REMOTE_RECEIVED = "WEBSOCKET_REMOTE_RECEIVED";
export const WEBSOCKET_SET_LISTENING_GROUP = "WEBSOCKET_SET_LISTENING_GROUP";
export const WEBSOCKET_REMOTE_SET_SHARER = "WEBSOCKET_REMOTE_SET_SHARER";
export const WEBSOCKET_SET_ACTIVE_GROUP = "WEBSOCKET_SET_ACTIVE_GROUP";
export const WEBSOCKET_SET_GROUP = "WEBSOCKET_SET_GROUP";
export const WEBSOCKET_NEW_JOINER = "WEBSOCKET_NEW_JOINER";

export function _setActualGroup({ group_id }) {
  return { type: WEBSOCKET_SET_ACTIVE_GROUP, group_id: group_id };
}

export function _setGroup({ group_id, my_uuid, stats }) {
  return {
    type: WEBSOCKET_SET_GROUP,
    group_id: group_id,
    my_uuid: my_uuid,
    stats: stats,
  };
}

export function _setHasNew({ hasNew }) {
  return { type: WEBSOCKET_NEW_JOINER, hasNew };
}

