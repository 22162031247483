import { CONST } from "Constants";
import { ParseDateTime } from "KlarHelper";
export function ParseMeetings(raw) {
  let meetings = [];
  if (Array.isArray(raw)) {
    for (let i in raw) {
      let meeting = ParseMeeting(raw[i]);
      if (meeting) meetings.push(meeting);
    }
  } else {
    let meeting = ParseMeeting(raw);
    if (meeting) meetings.push(meeting);
  }

  return meetings;
}
export function ParseMeeting(raw) {
  try {
    let meeting = new Meeting();

    meeting.name = raw.name;
    meeting.is_public = raw.is_public === true || raw.is_public === 1;
    meeting.ant_room_id = raw.ant_room_id;
    meeting.public_hash = raw.public_hash;
    meeting.stream_id = raw.stream_id;
    meeting.stream_url = raw.stream_url;
    meeting.stream_key = raw.stream_key;
    meeting.password = raw.password;
    meeting.preview_image = raw.preview_image;
    meeting.show_group_chat = raw.show_group_chat;

    meeting.start_time = ParseDateTime(raw.start_time);
    meeting.duration = raw.duration;
    meeting.lobby = raw.lobby;
    if (raw.details) meeting.details = JSON.parse(raw.details);
    if (!meeting.details.name) meeting.details.name = CONST._empty;

    if (!meeting.details.title) meeting.details.title = CONST._empty;

    if (!meeting.details.img) meeting.details.img = CONST._empty;
    if (!meeting.details.text) meeting.details.text = CONST._empty;
    if (!meeting.details.title_2) meeting.details.title_2 = CONST._empty;
    if (!meeting.details.short_title)
      meeting.details.short_title = CONST._empty;
    if (!meeting.details.short_title_2)
      meeting.details.short_title_2 = CONST._empty;
    if (!meeting.details.short_description)
      meeting.details.short_description = CONST._empty;
    if (!meeting.details.show_participant)
      meeting.details.show_participant = CONST._empty;

    meeting.text = raw.text;
    meeting.short_description = raw.short_description;
    meeting.description = raw.description;
    meeting.subtitle = raw.subtitle;

    meeting.type = raw.type;
    meeting.chat_room_id = 0;
    meeting.workspace_id = raw.workspace_id;
    meeting.sort_id = raw.sort_id;
    meeting.uuid = raw.uuid;
    meeting.stage_uuid = raw.stage_uuid;

    return meeting;
  } catch (e) {
    console.error("Error parsing meeting", raw);
  }
  return null;
}

export class Meeting {
  constructor() {
    this.name = CONST._empty;
    this.is_public = false;
    this.ant_room_id = CONST._empty;
    this.public_hash = CONST._empty;
    this.stream_id = CONST._empty;
    this.password = CONST._empty;
    this.preview_image = CONST._empty;

    this.duration = 0;
    this.start_time = null;
    this.text = CONST._empty;
    this.short_description = CONST._empty;
    this.description = CONST._empty;
    this.subtitle = CONST._empty;

    this.details = {
      name: CONST._empty,
      title: CONST._empty,
      img: CONST._empty,
      text: CONST._empty,
      title_2: CONST._empty,
      short_title: CONST._empty,
      short_title_2: CONST._empty,
      short_description: CONST._empty,
      show_participant: CONST._empty,
    };
    this.type = CONST._empty;
    this.chat_room_id = 0;
    this.workspace_id = CONST._empty;
    this.sort_id = 0;
    this.uuid = CONST._empty;
    this.stage_uuid = CONST._empty;
  }
}

Meeting.Types = {
  conference: "conference",
  live: "broadcast",
  call: "call",
};

Meeting.Roles = {
  1: "admin",
  10: "owner",
  20: "attendee",
};
