import { CONST } from "Constants";
import { ParseDocument } from "./Document";
import { ParseMeeting } from "./Meeting";
export function ParsePage(raw) {
  if (!raw) return null;

  try {
    let page = new Page();

    page.uuid = raw.uuid;
    page.entity_id = raw.entity_id;
    page.name = raw.name;
    page.description = raw.description;
    page.short_description = raw.short_description;
    page.subtitle = raw.subtitle;
    page.type = raw.type;
    page.entity_id = raw.entity_id;
    page.details = raw.details;
    page.items = ParsePageItems(raw.items);
    page.tile_data = raw.tile_data;
    page.payload = raw.payload;

    return page;
  } catch (e) {
    console.log("Error parsing page", raw);
  }
  return null;
}

export function ParsePageItems(raw) {
  let items = [];
  if (!raw || !Array.isArray(raw)) return [];
  for (let i in raw) {
    let pageItem = ParsePageItem(raw[i]);
    if (pageItem) items.push(pageItem);
  }
  return items;
}

export function ParsePageItem(raw) {
  try {
    let pageItem = new PageItem();
    pageItem.uuid = raw.uuid;
    pageItem.page_id = raw.page_id;
    pageItem.content = raw.content;
    pageItem.entity_id = raw.entity_id;
    pageItem.description = raw.description;
    pageItem.short_description = raw.short_description;
    pageItem.subtitle = raw.subtitle;

    pageItem.type = PageItem.Types[raw.type];
    if (!pageItem.type) return;
    pageItem.name = raw.name;
    if (raw.img) pageItem.img = raw.img;
    let details = null;
    try {
      details = JSON.parse(raw.details);
    } catch (e) {
      details = { subtitle: CONST._empty, link: CONST._empty };
    }
    pageItem.details = details;
    pageItem.sort_id = raw.sort_id;
    if (raw.main_url) pageItem.main_url = raw.main_url;

    pageItem.payload = raw.payload;
    if (raw.type === PageItem.Types.document)
      pageItem.payload = ParseDocument(raw.payload);
    if (raw.type === PageItem.Types.meeting && raw.payload?.details) {
      /* TODO dirty hotfix - meetgin signup button art the subpages
            //not sure if there are any side effects
            // the props.model should not be changed here - better place is the reducer
            // we should speak about the model structure and refactor if possible
            //16.09.2023 Sergej Hein
            let payloadDetails = JSON.parse(raw.payload?.details);
            console.log({ ...pageItem });
            if (!pageItem.uuid) {
                pageItem.uuid = raw.payload.uuid;
                console.log({ ...pageItem.details });
                pageItem.details = payloadDetails;
            }
            //pageItem.payload = ParseDocument(raw.payload);
            //pageItem.payload = ParseMeeting(raw.payload);
            //console.log("PageItem", pageItem, raw.payload);*/
    }

    return pageItem;
  } catch (e) {
    console.log("Error parsing pageItem", raw, e);
  }
  return null;
}

export class PageItem {
  constructor() {
    this.uuid = CONST._empty;
    this.page_id = CONST._empty;
    this.entity_id = CONST._empty;
    this.type = CONST._empty;
    this.content = CONST._empty;
    this.name = CONST._empty;
    this.img = null;
    this.main_url = null;
    this.details = { subtitle: CONST._empty, link: CONST._empty };
    this.sort_id = 0;
    this.subtitle = CONST._empty;
    this.description = CONST._empty;
    this.short_description = CONST._empty;
  }
}

PageItem.Types = {
  company: "company",
  workspace: "workspace",
  meeting: "meeting",
  page: "page",
  stage: "stage",
  document: "document",
  external: "external",
  html: "html",
  forum: "forum",
};

export class Page {
  constructor() {
    this.uuid = CONST._empty;
    this.type = CONST._empty;
    this.entity_id = CONST._empty;
    this.name = CONST._empty;
    this.entity_id = CONST._empty;
    this.subtitle = CONST._empty;
    this.description = CONST._empty;
    this.short_description = CONST._empty;
    this.details = {};
    this.payload = {};
    this.items = [];
    this.tile_data = {};
  }
}

Page.Types = {
  company: "company",
  workspace: "workspace",
  meeting: "meeting",
  page: "page",
};
