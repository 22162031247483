import styled from "styled-components/macro";
import { Paragraph } from "../../../styles";

export const TileViewContainer = styled.div`
  background-color: var(--black-semi);
  border: 2px solid var(--main-semi);
  background-size: cover;
  width: 450px;
  min-height: 250px;
  transform: scale(1);
  transition: transform 0.3s;
  cursor: pointer;
  &:hover {
    transform: scale(1.04);
    transition: transform 0.3s;
  }
`;

export const TileItem = styled.div`
  position: absolute;
  margin: 10px;
  ${(props) => (props.top ? "top: 0;" : null)}
  ${(props) => (props.bottom ? "bottom: 0;" : null)}
  ${(props) => (props.right ? "right: 0;" : null)}
  ${(props) => (props.left ? "left: 0;" : null)}
`;

export const NewTileContainer = styled(TileViewContainer)`
  background-color: transparent;
  border: 2px solid var(--gray-dark);
`;

export const TileTitle = styled(Paragraph)`
  position: absolute;
  color: var(--main);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const LabelContainer = styled.div`
  display: flex;
`;
