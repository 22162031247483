import React from "react";
import { _GetName } from "_redux/_models/User";
import UserImageAtomic from "../../MessengerModule/UserImageAtomic";
import classes from "./ContactIcon.module.css";

const ContactIcon = ({style, _key, user, onClick, opened}) => {
  return (
      <div
          className={"ia" + classes.root}
          key={_key}
          onClick={onClick}
          style={style}
      >
          <UserImageAtomic
              user={user}
              showBadge={opened}
              size={opened ? "var(--margin-5)" : "var(--margin-3)"}
          />
          {opened && <span className={classes.name}>{_GetName(user)}</span>}
      </div>
  );
};

export default ContactIcon;
