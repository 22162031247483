import { SendSocketIoRawMessage, notifyListeners } from "./Websocket";
//import { start as startCall, accept as acceptCall, reject as rejectCall, hangup as hangUpCall, accept } from "../_redux/api/call";

export function Init(socket) {
    socket.on(Events.createGroup, (data) => {
        notifyListeners(Events.createGroup, data);
    });
    socket.on(Events.renameGroup, (data) => {
        notifyListeners(Events.renameGroup, data);
    });
    socket.on(Events.deleteGroup, (data) => {
        notifyListeners(Events.deleteGroup, data);
    });
    socket.on(Events.addUsersToGroup, (data) => {
        notifyListeners(Events.addUsersToGroup, data);
    });
    socket.on(Events.removeUsersFromGroup, (data) => {
        notifyListeners(Events.removeUsersFromGroup, data);
    });
    socket.on(Events.sendMessageToGroup, (data) => {
        notifyListeners(Events.sendMessageToGroup, data);
    });
}
export function CreateGroup(group = { name: "", users: [] }) {
    //store.dispatch(startCall({from:from, to:to}));
    SendSocketIoRawMessage(Events.createGroup, group);
}
export function RenameGroup(group_id, name) {
    //store.dispatch(startCall({from:from, to:to}));
    SendSocketIoRawMessage(Events.renameGroup, { uuid: group_id, name: name });
}

export function DeleteGroup(group_id) {
    //store.dispatch(startCall({from:from, to:to}));
    SendSocketIoRawMessage(Events.deleteGroup, { uuid: group_id });
}

export function AddUsers(group_id, users = []) {
    //users = [{uuid:string}]
    SendSocketIoRawMessage(Events.addUsersToGroup, {
        uuid: group_id,
        users: users,
    });
}
export function RemoveUsers(group_id, users = []) {
    //users = [{uuid:string}]
    SendSocketIoRawMessage(Events.removeUsersFromGroup, {
        uuid: group_id,
        users: users,
    });
}

export function JoinCallGroup(group_id) {
    SendSocketIoRawMessage(Events.joinGroup, { uuid: group_id });
}

export function SendGroupMessage(groupid, msg, type) {
    SendSocketIoRawMessage(Events.sendMessageToGroup, {
        uuid: groupid,
        type: type,
        message: msg,
    });
}

export const Events = {
    createGroup: "createGroup",
    renameGroup: "renameGroup",
    deleteGroup: "deleteGroup",
    addUsersToGroup: "addUserToGroup",
    removeUsersFromGroup: "removeUserToGroup",
    joinGroup: "joinGroup",
    leaveGroup: "leaveGroup",
    sendMessageToGroup: "sendMessageToGroup",
};
