export const SURVEY_GET = {
  PENDING: "SURVEY_GET_PENDING",
  SUCCESS: "SURVEY_GET_SUCCESS",
  ERROR: "SURVEY_GET_ERROR",
};

export const SURVEY_RESULT_GET = {
  PENDING: "SURVEY_RESULT_GET_PENDING",
  SUCCESS: "SURVEY_RESULT_GET_SUCCESS",
  ERROR: "SURVEY_RESULT_GET_ERROR",
};

export const SURVEY_QUESTION_VOTE = {
  PENDING: "SURVEY_QUESTION_VOTE_PENDING",
  SUCCESS: "SURVEY_QUESTION_VOTE_SUCCESS",
  ERROR: "SURVEY_QUESTION_VOTE_ERROR",
};

export const SURVEY_USER_GET_ALL = {
  PENDING: "SURVEY_USER_GET_ALL_PENDING",
  SUCCESS: "SURVEY_USER_GET_ALL_SUCCESS",
  ERROR: "SURVEY_USER_GET_ALL_ERROR",
};

export const SURVEY_USER_ANSWER = {
  PENDING: "SURVEY_USER_ANSWER_PENDING",
  SUCCESS: "SURVEY_USER_ANSWER_SUCCESS",
  ERROR: "SURVEY_USER_ANSWER_ERROR",
};

export const SURVEY_WORKSPACE_GET_ALL = {
  PENDING: "SURVEY_WORKSPACE_GET_ALL_PENDING",
  SUCCESS: "SURVEY_WORKSPACE_GET_ALL_SUCCESS",
  ERROR: "SURVEY_WORKSPACE_GET_ALL_ERROR",
};

export const SURVEY_UPDATE = {
  PENDING: "SURVEY_UPDATE_PENDING",
  SUCCESS: "SURVEY_UPDATE_SUCCESS",
  ERROR: "SURVEY_UPDATE_ERROR",
};

export const SURVEY_QUESTION_UPDATE = {
  PENDING: "SURVEY_QUESTION_UPDATE_PENDING",
  SUCCESS: "SURVEY_QUESTION_UPDATE_SUCCESS",
  ERROR: "SURVEY_QUESTION_UPDATE_ERROR",
};

export const SURVEY_QUESTION_OPTION_UPDATE = {
  PENDING: "SURVEY_QUESTION_OPTION_UPDATE_PENDING",
  SUCCESS: "SURVEY_QUESTION_OPTION_UPDATE_SUCCESS",
  ERROR: "SURVEY_QUESTION_OPTION_UPDATE_ERROR",
};

export const SURVEY_DELETE = {
  PENDING: "SURVEY_DELETE_PENDING",
  SUCCESS: "SURVEY_DELETE_SUCCESS",
  ERROR: "SURVEY_DELETE_ERROR",
};

export const SURVEY_QUESTION_DELETE = {
  PENDING: "SURVEY_QUESTION_DELETE_PENDING",
  SUCCESS: "SURVEY_QUESTION_DELETE_SUCCESS",
  ERROR: "SURVEY_QUESTION_DELETE_ERROR",
};

export const SURVEY_QUESTION_OPTION_DELETE = {
  PENDING: "SURVEY_QUESTION_OPTION_DELETE_PENDING",
  SUCCESS: "SURVEY_QUESTION_OPTION_DELETE_SUCCESS",
  ERROR: "SURVEY_QUESTION_OPTION_DELETE_ERROR",
};

export const SURVEY_QUESTION_CREATE = {
  PENDING: "SURVEY_QUESTION_CREATE_PENDING",
  SUCCESS: "SURVEY_QUESTION_CREATE_SUCCESS",
  ERROR: "SURVEY_QUESTION_CREATE_ERROR",
};

export const SURVEY_QUESTION_OPTION_CREATE = {
  PENDING: "SURVEY_QUESTION_OPTION_CREATE_PENDING",
  SUCCESS: "SURVEY_QUESTION_OPTION_CREATE_SUCCESS",
  ERROR: "SURVEY_QUESTION_OPTION_CREATE_ERROR",
};

export const SURVEY_CREATE = {
  PENDING: "SURVEY_CREATE_PENDING",
  SUCCESS: "SURVEY_CREATE_SUCCESS",
  ERROR: "SURVEY_CREATE_ERROR",
};

export function _surveyGet(type, info, payload) {
  return { type: SURVEY_GET[type], info: info, payload: payload };
}

export function _surveyResultGet(type, info, payload) {
  return { type: SURVEY_RESULT_GET[type], info: info, payload: payload };
}

export function _surveyQuestionVote(type, info, payload) {
  return { type: SURVEY_QUESTION_VOTE[type], info: info, payload: payload };
}

export function _surveyUserGetAll(type, info, payload) {
  return { type: SURVEY_USER_GET_ALL[type], info: info, payload: payload };
}

export function _surveyUserAnswer(type, info, payload) {
  return { type: SURVEY_USER_ANSWER[type], info: info, payload: payload };
}

export function _surveyWorkspaceGetAll(type, info, payload) {
  return { type: SURVEY_WORKSPACE_GET_ALL[type], info: info, payload: payload };
}

export function _surveyUpdate(type, info, payload) {
  return { type: SURVEY_UPDATE[type], info: info, payload: payload };
}

export function _surveyQuestionUpdate(type, info, payload) {
  return { type: SURVEY_QUESTION_UPDATE[type], info: info, payload: payload };
}

export function _surveyQuestionOptionUpdate(type, info, payload) {
  return {
    type: SURVEY_QUESTION_OPTION_UPDATE[type],
    info: info,
    payload: payload,
  };
}

export function _surveyDelete(type, info, payload) {
  return { type: SURVEY_DELETE[type], info: info, payload: payload };
}

export function _surveyQuestionDelete(type, info, payload) {
  return { type: SURVEY_QUESTION_DELETE[type], info: info, payload: payload };
}

export function _surveyQuestionOptionDelete(type, info, payload) {
  return {
    type: SURVEY_QUESTION_OPTION_DELETE[type],
    info: info,
    payload: payload,
  };
}

export function _surveyQuestionCreate(type, info, payload) {
  return { type: SURVEY_QUESTION_CREATE[type], info: info, payload: payload };
}

export function _surveyQuestionOptionCreate(type, info, payload) {
  return {
    type: SURVEY_QUESTION_OPTION_CREATE[type],
    info: info,
    payload: payload,
  };
}

export function _surveyCreate(type, info, payload) {
  return { type: SURVEY_CREATE[type], info: info, payload: payload };
}
