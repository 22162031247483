import React, { Component } from "react";
import { CONST } from "Constants";
// import GroupImage from "../GroupImage";
import "./SurveyRow.css";
import Icon from "../../../../ui/assets/Icon";

class SurveyRow extends Component {
  render() {
    return (
      <div
        className={`UserRow ia ${
          this.props.selected ? CONST.classes._active : ""
        } ${this.props.published ? "published" : ""}`}
        onClick={this.props.onClick}
      >
        <div className="UserImage">
          <Icon type={Icon.Types.groups} color={Icon.Colors.black}></Icon>
        </div>
        <span>{this.props.name}</span>
      </div>
    );
  }
}
export default SurveyRow;
