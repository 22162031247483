import {STAGE_GET,STAGE_UPDATE,STAGE_CREATE,STAGE_GET_AVAILABLE,STAGE_DELETE} from './../actions/stage';
import {APP_LOGOUT_USER}  from './../actions/app';
import {initialState}  from 'initialState.js';
import {ParseStages, ParseStage} from '_redux/_models/Stage';
import { CONST } from '../../Constants';

export function stageReducer(state = initialState, action) {	
	switch(action.type) {
		case APP_LOGOUT_USER:
			return{...state,availableStages:[],actualStage:null}		
  /*#region STAGE_DELETE*/		
  case STAGE_DELETE.SUCCESS:
  {
    let workspaceStages = state.availableStages;
    if(action.payload.stage)
    {
      for(let i in workspaceStages)
      {
        if(workspaceStages[i].uuid === action.payload.stage.uuid)
        {
          workspaceStages.splice(i, 1);
          break;
        }
      }
    }
    return {
      ...state,
      workspaceStages:[...workspaceStages]
    }    
  }
  case STAGE_DELETE.PENDING: 
    return {...state,error: null,pending: true}
  case STAGE_DELETE.ERROR:					
    return {...state,pending: false,error: action.info}
  /*#endregion STAGE_DELETE*/

  /*#region STAGE_UPDATE*/		
  case STAGE_UPDATE.SUCCESS:
  {
    let workspaceStages = state.availableStages;

    if(action.payload.stage)
    {
      for(let i in workspaceStages)
      {
        if(workspaceStages[i].uuid === action.payload.stage.uuid)
        {
          workspaceStages[i] = ParseStage(action.payload.stage);
          break;
        }
      }
    }
    return {
      ...state,
      workspaceStages:[...workspaceStages]
    }
  }
  case STAGE_UPDATE.PENDING: 
    return {...state,error: null,pending: true};
  case STAGE_UPDATE.ERROR:					
    return {...state,pending: false,error: action.info};
  /*#endregion STAGE_UPDATE*/

  /*#region STAGE_CREATE*/
		
	case STAGE_CREATE.SUCCESS:
  {
    let workspaceStages = state.availableStages;

    if(action.payload.stage && action.payload.stage.workspace_id)
    {
      workspaceStages.push(ParseStage(action.payload.stage));
    }
    
    return {
      ...state,
      workspaceStages:[...workspaceStages]
    }    
  }
  case STAGE_CREATE.PENDING: 
    return {...state,error: null,pending: true}
  case STAGE_CREATE.ERROR:					
    return {...state,pending: false,error: action.info}
  /*#endregion STAGE_CREATE*/

	/*#region STAGE_GET*/
		
		case STAGE_GET.SUCCESS:	
		//	console.log(action.payload.stage);
			let actualStage =  action.payload.stage;
			return {
				...state,				
				pending: false,
				actualStage: actualStage
			}
		case STAGE_GET.PENDING: 
			return {...state,error: null,pending: true}
		case STAGE_GET.ERROR:					
			return {...state,pending: false,error: action.info}
		/*#endregion STAGE_GET*/

	/*#region STAGE_GET_AVAILABLE*/
		
    case STAGE_GET_AVAILABLE.SUCCESS:		
      let workspaceStages = state.workspaceStages;
    
      if(action.payload.workspace_stages)
      {
        workspaceStages = ParseStages(action.payload.workspace_stages);
      }
      let stages = state.availableStages;
      if(action.payload.stages)
        stages = ParseStages(action.payload.stages);
      
      return {
				...state,
				pending: false,
        availableStages: stages,
        workspaceStages:workspaceStages
			}
		case STAGE_GET_AVAILABLE.PENDING: 
			return {...state,error: null,pending: true,}
		case STAGE_GET_AVAILABLE.ERROR:			
      return {...state,pending: false,error: action.info}
  /*#endregion STAGE_GET_AVAILABLE*/
		default: 
			return state;
	}
}
export const IsError = state => state.stage.error;
export const IsPending = state => state.stage.pending;
export const GetState = state => state.stage.pending?CONST.reducerStates.pending:state.stage.error?CONST.reducerStates.error:CONST.reducerStates.success;
export const GetAvailable = state => state.stage.availableStages;

export const GetActual = state => state.stage.actualStage;

