import React, { useEffect, useRef, useState } from "react";
import {
  GetAllLanguages,
  GetSelectedLanguage,
} from "../_redux/reducers/language";
import { useDispatch, useSelector } from "react-redux";
import { _update as LanguageUpdate } from "_redux/actions/language";
import classes from './LanguageSelector.module.css';
import { _ } from "Constants";

export const LanguageSelector = ({ languageSelected, setLanguageSelected, isDark, collapsible=false, _ref, className, showLabel=false }) => {


  const [opened, SetOpened] = useState(collapsible?false:true);
  const languages = useSelector(GetAllLanguages);
  const selected = useSelector(GetSelectedLanguage);
  const language = languageSelected || selected;
  const elementsRef = useRef();
  const dispatch = useDispatch();

  useEffect(()=>{
    if(collapsible && opened)
      document.addEventListener("click", clicked);
    return ()=>{
      document.removeEventListener("click", clicked);
    }
  },[opened]);

  const clicked = (e)=>{
    if(e.target != elementsRef.current){
      SetOpened(false);
    }

  }

  const renderElements = () =>{
    return languages.map((item) => (
      <div
        key={item}
        className={classes.element + _ + (language === item?classes.active:isDark?classes.dark:'')}
        style={{'--bg':`url(/images/flags/${item}.png)`}}
        onClick={() => {
          if (setLanguageSelected) {
            setLanguageSelected(item);
            SetOpened(false);
            return;
          }
          dispatch(LanguageUpdate(item));
          SetOpened(false);
        }}
      >
        {collapsible?<><div className={classes.bg}></div><span>{item}</span></>:item}
      </div>
    ))

  }

  return (
    <div className={classes.root+(className?_+className:_)} ref={_ref}>
      {collapsible &&
        <>
          <div
            className={classes.btn}
            onClick={(e)=>{
              e.preventDefault();
              e.stopPropagation();
              SetOpened(true)
            }}
          >
            <i className="icon-globe"></i>
            {showLabel && <span className={classes.label}>{language}</span>}
          </div>

          {opened &&
            <div className={classes.elements} ref={elementsRef}>
              {renderElements()}
            </div>
          }
        </>
      }
      {!collapsible && renderElements()}
    </div>
  );
};
