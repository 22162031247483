import styled, { css } from "styled-components/macro";
import { Button, rotate360, RoundButton, Spinner } from "../../../../styles";
import Modal from "styled-react-modal";

export const AddQuestionButton = styled(Button)`
  text-align: center;
  height: 40px;
  padding-top: 0px;
`;

export const DeleteQuestionButton = styled(RoundButton)`
  position: absolute;
  top: -12px;
  right: -13px;
  padding: 6px;
  width: 32px;
  height: 32px;
`;

export const DeleteQuestionOptionButton = styled(RoundButton)`
  position: absolute;
  top: -8px;
  right: -7px;
  width: 18px;
  height: 18px;
`;

export const SaveCompleteIndicator = styled.div`
  border-radius: 50%;
  width: 18px;
  height: 18px;
  border: 3px solid var(--state-green);
`;

export const SurveyCardSpinner = styled(Spinner)`
  border-top: 3px solid var(--gray-dark);
  border-right: 3px solid var(--gray-dark);
  border-bottom: 3px solid var(--gray-dark);
  border-left: 4px solid var(--main);
`;

export const SavingIndicator = styled.div`
  background: transparent;
  border-radius: 50%;
  width: 38px;
  height: 38px;
  position: absolute;
  right: -16px;
  top: -15px;

  border: 3px solid var(--state-green);

  ${({ isSaving }) =>
    isSaving &&
    css`
      animation: ${rotate360} 1s linear infinite;
      transform: translateZ(0);
      border-top: 3px solid var(--gray-dark);
      border-right: 3px solid var(--gray-dark);
      border-bottom: 3px solid var(--gray-dark);
      border-left: 4px solid var(--main);
    `}
`;

export const StyledCheckboxWrapper = styled.div`
  margin-top: 20px;
  & .customCheckbox {
    display: flex;
  }
`;

export const StyledModal = Modal.styled`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: var(--gray-light);
  border-radius: 2px;
  position: relative;
  width: 600px;
  height: 200px;
  min-height: 200px;
  max-height: 90vh;
  overflow: auto;
  padding: var(--margin-5)
`;

export const ModalActionWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

export const ActionButton = styled(Button)`
  color: var(--black);
  border: 1px solid var(--black);
`;
