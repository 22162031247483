import React, { useEffect, useRef, useState } from "react";

import { CONST } from "../../../../Constants";

import classes from "./MessengerChatMessage.module.css";

import { CustomIcon } from "../../../../styles";
import { IsFileUrl, IsUrl, RenderDateTime } from "../../../../KlarHelper";
import useOnScreen from "../../../../hooks/useOnScreen";
import { _GetName } from "_redux/_models/User";
import UserImageAtomic from "../UserImageAtomic";

const MessengerChatMessage = (props) => {
  const ref = useRef();
  const isVisible = useOnScreen(ref);

  const newMessage = props.msg.is_new && props.msg.from != props.me.email
  const [wasShown, SetWasShown] = useState(!newMessage)

  useEffect(() => {
    let to=null;

    if (isVisible && !wasShown) {
      props.msg.is_new = false;
      props.setAsShownHandler && props.setAsShownHandler(props.msg.id);
      to = setTimeout(()=>{SetWasShown(true)}, 1000)
    }

    return ()=>clearTimeout(to);
  },[isVisible, newMessage]);

  const getFileIcon = (path) =>{
    if(msg.msg.indexOf(".mp4") > -1)
      return "icon-video";
    if(msg.msg.indexOf(".pdf") > -1)
      return "icon-document";

    return "icon-document";
  }
  const msg = props.msg;

  if (!props.user) return null;
  if (msg.is_deleted) return null;

  const user = msg.from === props.me.email ? props.me : props.user;
  const is_left = user === props.user;
  let classname = "msgRow ";

  classname += is_left ? CONST._empty : CONST.classes.right;
  classname += !wasShown ? CONST.classes._new : CONST._empty;

  const isInternal =  msg.msg.indexOf(process.env.REACT_APP_API_URL) > -1
  const isImage =  msg.msg.indexOf(process.env.REACT_APP_API_URL) > -1 && (msg.msg.indexOf(".gif") > -1 || msg.msg.indexOf(".jpeg") > -1 || msg.msg.indexOf(".png") > -1)
  const isFile = IsFileUrl(msg.msg);
  let visibleUrl = msg.msg;
  if(isInternal && isFile){
    let parts = msg.msg.split("_");
    if(parts.length>1)
    {
      parts.splice(parts.length-1,1);
    }
    visibleUrl = parts.join("_");

    parts = visibleUrl.split("/");
    if(parts.length>1){
      visibleUrl = parts[parts.length-1]
    }
  }
  return (
    <div className={classes.root} ref={ref}>
      <div className={classname}>
        <UserImageAtomic user={user} size='var(--margin-4)' showBadge={false}></UserImageAtomic>
        <div className="content">
          <div className="name">
            {_GetName(user)} {RenderDateTime(msg.timestamp).time}
          </div>
          <div className="msg">
            {IsUrl(msg.msg) && isFile ? (
              <a
                target="_blank"
                rel="noopener noreferrer"
                key={msg.msg}
                href={msg.msg}

                className={((isImage && isInternal)?classes.image:classes.file)}
              >
                {(isImage && isInternal)?
                  <img className={classes.msgImg} src={msg.msg} alt="" />
                 : (
                  <>
                  <CustomIcon
                    icon={getFileIcon(msg.msg)}
                    size={27}
                    color="var(--main)"
                    style={{
                      marginRight: "8px",
                      paddingLeft: 20,
                      paddingRight: 20,
                    }}
                  />
                  <br/>
                  <span className={classes.urlLink}>{visibleUrl}</span>
                  </>
                )}
              </a>
            ) : (
              msg.msg.split(CONST._space).map((part) =>
                IsUrl(part) &&
                part.split("script").length === 1 &&
                !IsFileUrl(part) ? (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    key={part}
                    href={part}
                    className="urlLink"
                  >
                    {part + CONST._space}
                  </a>
                ) : (
                  part + CONST._space
                )
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessengerChatMessage;
