import React, { useEffect, useRef, useState } from "react";
import {
  OpenQuestionsIndicator,
  SurveyActionDrawer,
  SurveyIconContainer,
} from "./styles";
import Icon from "../../../ui/assets/Icon";
import { Composition } from "atomic-layout";
import {
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerSection,
  LeftMenu,
  LeftMenuActions,
  LeftMenuBody,
  LeftMenuHeader,
  ScrollableContainer,
} from "../styles";
import { Button, Paragraph, Space } from "../../../styles";
import CreateSurvey from "./CreateSurvey";
import {
  _surveyQuestionCreate as SurveyQuestionCreate,
  _surveyWorkspaceGetAll as SurveyWorkspaceGetAll,
  _surveyUpdate as SurveyUpdate,
  _surveyResultGet as SurveyResultGet,
  _surveyQuestionUpdate as SurveyQuestionUpdate,
} from "../../../_redux/api/survey";
import { getUser } from "../../../_redux/reducers/login";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  AllWorkspaceSurveys,
  SurveyDataChanged,
  SendSurveyPing,
} from "../../../_redux/reducers/survey";
import { GetActual as SelectedWorkspace } from "../../../_redux/reducers/workspace";
import SurveyRow from "./SurveyRow";
import { CONST, SURVEY_STATE } from "../../../Constants";
import EditQuestionCard from "./EditQuestionCard";
import QuestionCard from "./QuestionCard";
import i18n from "../../../i18n";
import { Ping, Events as SurveyEvents } from "../../../_ws/SurveyWebsocket";
import { addListener, removeListener } from "_ws/Websocket";
import { canEditWorkspace } from "../../../KlarHelper";
import { ReactSortable } from "react-sortablejs";
import { GetActual as GetActualMeeting } from "_redux/reducers/meeting";
import { GetCompanyTheme } from "_redux/reducers/company";
import checkPageStatus, { convertCompanyTheme } from "Utils";
import { GetSelectedLanguage } from "../../../_redux/reducers/language";

export const bySortId = (a, b) => {
  if (a.sort_id < b.sort_id) return -1;
  if (a.sort_id > b.sort_id) return 1;
  return 0;
};

export const byId = (a, b) => {
  if (a.id < b.id) return -1;
  if (a.id > b.id) return 1;
  return 0;
};

const AUTO_SAVE_DELAY_MS = 3000;

function SurveyModule(props) {
  const [initialLoad, setInitialLoad] = useState(true);
  const [selectedSurvey, setSelectedSurvey] = useState(null);

  const [createSurvey, setCreateSurvey] = useState(false);
  const [surveyToEdit, setSurveyToEdit] = useState(null);

  const [nonAdminSurveyQuestions, setNONADMINSurveyQuestions] = useState([]);
  const [leftMenuIsLoading, setLeftMenuIsLoading] = useState(false);
  const [languageSelected, setLanguageSelected] = useState(
    props.currentLanguage
  );
  const companyTheme = convertCompanyTheme(props.companyTheme);
  const activeSurvey = useRef(null);
  useEffect(() => {
    if (props.selectedWorkspace && props.allWorkspaceSurveys) {
      addListener(SurveyEvents.pingSurveyGroup, wsPingReceived);
      return () => removeListener(SurveyEvents.pingSurveyGroup, wsPingReceived);
    }
  }, [props.selectedWorkspace, props.allWorkspaceSurveys]);

  const wsPingReceived = (data) => {
    let uuid = data.uuid;
    if (uuid.indexOf("#") > -1) uuid = uuid.split("#")[1];
    props.SurveyWorkspaceGetAll({
      survey: {
        workspace_id: uuid /*props.selectedWorkspace?.uuid*/,
      },
    });
  };

  useEffect(() => {
    // If any data on any survey changed, ping others to fetch data
    //console.log(props.sendSurveyPing);
    if (props.sendSurveyPing && props.selectedWorkspace?.uuid) {
      let pingType = props.sendSurveyPing?.pingType;
      //console.log(pingType, props.selectedWorkspace?.uuid);
      Ping("ws#" + props.selectedWorkspace?.uuid, pingType);
    }
  }, [props.sendSurveyPing]);

  const toggleSurveySelection = (survey) => {
    if (survey && selectedSurvey !== survey) {
      setSelectedSurvey(survey);
      props.toggleLeftMenu("open");
    } else {
      setSelectedSurvey(null);
      props.toggleLeftMenu("close");
    }
  };

  const toggleCreateSurvey = (floodRequest = false) => {
    toggleSurveySelection();
    if (floodRequest === true) {
      setSurveyToEdit(null);
      setCreateSurvey(false);
      return;
    }
    setSurveyToEdit(null);
    if (props.active) {
      setCreateSurvey(!createSurvey);
      return;
    }
    setSurveyToEdit(null);
    setCreateSurvey(true);
  };

  const toggleEditSurvey = (survey) => {
    toggleSurveySelection();
    setSurveyToEdit(survey ? survey : null);
    setCreateSurvey(!createSurvey);
  };

  useEffect(() => {
    if (selectedSurvey) {
      setSelectedSurvey(null);
    }
  }, [props.active]);

  useEffect(() => {
    // If any data on any survey changed, fetch to get new data
    //console.log("Get surveys", props.surveyDataChanged, props.selectedWorkspace);
    if (props.selectedWorkspace?.uuid) {
      props.SurveyWorkspaceGetAll(
        {
          survey: {
            workspace_id: props.selectedWorkspace?.uuid,
          },
        },
        languageSelected
      );
    }
  }, [props.surveyDataChanged, props.selectedWorkspace, languageSelected]);

  useEffect(() => {
    // If survey are fetched, check if one is selected and
    // update the data accordingly
    if (selectedSurvey) {
      setSelectedSurvey(
        props.allWorkspaceSurveys.survey.find(
          (survey) => survey.uuid === selectedSurvey.uuid
        )
      );
    }

    // Set the question data for the right non admin drawer area
    if (props.allWorkspaceSurveys) {
      let questionsArray = [];
      const surveys = props.allWorkspaceSurveys?.survey?.filter(
        (item) => item.type !== "q_and_a"
      );
      for (let survey of surveys) {
        if (
          survey.user_id !== props.user.uuid &&
          (!survey.meeting_id ||
            survey.meeting_id === props.actualMeeting?.uuid)
        ) {
          for (let question of survey.questions) {
            if (
              (question.state === SURVEY_STATE.ACTIVE.key &&
                !question?.user_response?.length) ||
              question.state === SURVEY_STATE.PUBLISHED.key
            ) {
              questionsArray.push(question);
            }
          }
        }
      }
      const sortQuestion = questionsArray.sort((a, b) => a.sort_id - b.sort_id);

      setNONADMINSurveyQuestions(sortQuestion);
      if (
        sortQuestion.length > nonAdminSurveyQuestions.length &&
        !initialLoad
      ) {
        const newQuestion = sortQuestion.find(
          (item) =>
            !nonAdminSurveyQuestions.map((i) => i.uuid).includes(item.uuid)
        );
        if (newQuestion && newQuestion.state === "active") {
          checkPageStatus("New survey", newQuestion.name, "");
        }
      }
      setInitialLoad(false);
    }
  }, [props.allWorkspaceSurveys, props.actualMeeting]);

  const [adminQuestions, setAdminQuestions] = useState([]);
  useEffect(() => {
    // Responsible to set the survey state indicators of the edit window
    // setSurveyState(
    //   Object.values(SURVEY_STATE).filter(
    //     (state) => state.key === selectedSurvey?.state
    //   )[0]
    // );

    // Set the question data for the left admin drawer area
    if (selectedSurvey?.questions) {
      setAdminQuestions(
        selectedSurvey?.questions.sort((a, b) => a.sort_id - b.sort_id)
      );
    }
  }, [selectedSurvey]);

  useEffect(() => {
    if (
      activeSurvey?.current &&
      props.openSurvey &&
      nonAdminSurveyQuestions.length
    ) {
      activeSurvey.current.scrollIntoView();
    }
  }, [props.openSurvey, activeSurvey, nonAdminSurveyQuestions]);

  const createSurveyQuestion = (survey_id) => {
    props.SurveyQuestionCreate({
      question: {
        survey_id: survey_id,
        name: "",
        type: "",
        state: SURVEY_STATE.DRAFT.key,
        anonymous: false,
        sort_id: adminQuestions[adminQuestions.length - 1]
          ? adminQuestions[adminQuestions.length - 1].sort_id + 1
          : 0,
        options: [],
      },
    });
  };

  const changeSurveyState = (survey, state) => {
    props.SurveyUpdate(
      {
        survey: {
          uuid: survey.uuid,
          state: state.key,
        },
      },
      languageSelected
    );
  };

  const changeQuestionState = (question, state, notify_users) => {
    props.SurveyQuestionUpdate({
      question: {
        uuid: question.uuid,
        state: state.key,
        notify_users: notify_users ? "true" : "",
      },
      languageSelected,
    });
  };

  const [questionOrderingSaveContdown, setQuestionOrderingSaveContdown] =
    useState(null);

  const saveQuestionOrdering = () => {
    setLeftMenuIsLoading(false);
    for (let i in adminQuestions) {
      props.SurveyQuestionUpdate(
        {
          question: {
            uuid: adminQuestions[i].uuid,
            sort_id: i,
          },
        },
        languageSelected
      );
    }
  };

  const changeQuestionOrdering = (event) => {
    setLeftMenuIsLoading(true);
    if (questionOrderingSaveContdown) {
      clearTimeout(questionOrderingSaveContdown);
    }
    setQuestionOrderingSaveContdown(
      setTimeout(saveQuestionOrdering, AUTO_SAVE_DELAY_MS)
    );
  };

  const onChangeQuestionOrderingStart = () => {
    if (questionOrderingSaveContdown) {
      clearTimeout(questionOrderingSaveContdown);
    }
  };

  const handleOpenDrawer = () => {
    if (!props.active) {
      props.toggleOpen(props.value);
    }
  };

  if (
    !canEditWorkspace(props.selectedWorkspace?.role?.value) &&
    nonAdminSurveyQuestions.length == 0
  )
    return "";
  return (
    <>
      <SurveyActionDrawer background={companyTheme.background}>
        <DrawerHeader hasContent={nonAdminSurveyQuestions.length > 0}>
          <Composition
            areas="left right"
            justifyContent="space-between"
            paddingLeft={10}
            paddingRight={10}
            onClick={handleOpenDrawer}
          >
            {(Areas) => (
              <>
                <Areas.Left>
                  <Composition areas="item item" alignItems="center" gap={10}>
                    <SurveyIconContainer>
                      <Icon
                        className="arrow"
                        type={Icon.Types.raisehand}
                        color={Icon.Colors.black}
                      />
                      {nonAdminSurveyQuestions.length > 0 && (
                        <OpenQuestionsIndicator>
                          {nonAdminSurveyQuestions.length}
                        </OpenQuestionsIndicator>
                      )}
                    </SurveyIconContainer>
                    <span>{i18n.t("survey")}</span>
                  </Composition>
                </Areas.Left>
                <Areas.Right>
                  <Composition areas="item item">
                    {canEditWorkspace(props.selectedWorkspace?.role?.value) ? (
                      <Icon
                        className={"new_msg ia"}
                        type={Icon.Types.write}
                        color={Icon.Colors.white}
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleCreateSurvey();
                          handleOpenDrawer();
                        }}
                      />
                    ) : null}

                    <Icon
                      className="arrow ia"
                      style={{
                        transform: `rotate(${
                          props.active ? "180deg" : "0deg"
                        })`,
                      }}
                      type={Icon.Types.chevron_up}
                      color={Icon.Colors.white}
                      onClick={(e) => {
                        e.stopPropagation();
                        props.toggleOpen(props.value);
                      }}
                    />
                  </Composition>
                </Areas.Right>
              </>
            )}
          </Composition>
        </DrawerHeader>
        <DrawerBody active={props.active}>
          {createSurvey ? (
            <CreateSurvey
              toggleCreateSurvey={toggleCreateSurvey}
              surveyToEdit={surveyToEdit}
              languageSelected={languageSelected}
              setLanguageSelected={setLanguageSelected}
            />
          ) : (
            <DrawerContent>
              {canEditWorkspace(props.selectedWorkspace?.role?.value) ? (
                <DrawerSection maxHeight="300px" minHeight="200px">
                  <Paragraph
                    color="var(--bg-dark)"
                    size="16px"
                    style={{ paddingLeft: 7 }}
                  >
                    {i18n.t("survey.admin.section.title")}
                  </Paragraph>
                  {props.allWorkspaceSurveys?.survey
                    ?.filter((item) => item.type !== "q_and_a")
                    .map((survey, i) =>
                      survey.user_id === props.user.uuid ? (
                        <div className="row" key={i}>
                          <div
                            style={{
                              float: "left",
                              width: "75%",
                            }}
                          >
                            <SurveyRow
                              key={i}
                              _key={i}
                              selected={selectedSurvey?.uuid === survey.uuid}
                              className={
                                selectedSurvey
                                  ? CONST.classes.active
                                  : CONST.classes._empty
                              }
                              onClick={() => toggleSurveySelection(survey)}
                              name={survey.name}
                            />
                          </div>
                          {/* TODO Include permissions again */}
                          {/*{entry.permission === "admin" ? (*/}
                          <div
                            style={{
                              float: "left",
                              width: "25%",
                              height: "56px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Icon
                              className="ia"
                              type={Icon.Types.settings}
                              color={Icon.Colors.black}
                              onClick={() => toggleEditSurvey(survey)}
                            />
                          </div>
                          {/*) : null}*/}
                        </div>
                      ) : null
                    )}
                </DrawerSection>
              ) : null}

              <DrawerSection>
                {nonAdminSurveyQuestions?.map((question, i) => (
                  <div
                    key={i}
                    ref={
                      question.uuid === props.openSurvey ? activeSurvey : null
                    }
                  >
                    <QuestionCard question={question} key={i} />
                    <Space />
                  </div>
                ))}
                {/*<Card>*/}
                {/*  <Chip color="open">Open</Chip>*/}
                {/*  <Paragraph color="var(--bg-dark)" bold>*/}
                {/*    Which color of counter top do you prefer?*/}
                {/*  </Paragraph>*/}
                {/*  <Composition gap={10}>*/}
                {/*    <TextArea*/}
                {/*      value=""*/}
                {/*      width="100%"*/}
                {/*      color="var(--bg-dark)"*/}
                {/*      bgColor="var(--gray-light)"*/}
                {/*    />*/}
                {/*    <Button color="var(--bg-dark)">Submit</Button>*/}
                {/*  </Composition>*/}
                {/*</Card>*/}
              </DrawerSection>
            </DrawerContent>
          )}
        </DrawerBody>
      </SurveyActionDrawer>
      <LeftMenu active={props.leftMenuOpen} parentActive={true}>
        <LeftMenuHeader>
          <Icon
            className="arrow ia"
            type={Icon.Types.chevron_right}
            color={Icon.Colors.black}
            onClick={() => toggleSurveySelection()}
            styling={{ position: "absolute", left: "10px" }}
          />
          <Paragraph color="var(--bg-dark)" paddingTop="5px">
            {i18n.t("survey.admin.management.title")}
          </Paragraph>
        </LeftMenuHeader>
        <LeftMenuBody
          indicator={SURVEY_STATE.DRAFT.key}
          loading={leftMenuIsLoading || null}
        >
          <ScrollableContainer padding="30px" marginTop="3px">
            <ReactSortable
              list={adminQuestions}
              setList={setAdminQuestions}
              onStart={onChangeQuestionOrderingStart}
              onEnd={(e) => changeQuestionOrdering(e)}
              animation={200}
            >
              {adminQuestions?.map((question) => {
                return (
                  <div key={question.uuid}>
                    {question.state === SURVEY_STATE.DRAFT.key ? (
                      <EditQuestionCard
                        languageSelected={languageSelected}
                        setLanguageSelected={setLanguageSelected}
                        question={question}
                        idOfSurveyCreator={selectedSurvey?.user_id}
                        changeQuestionState={changeQuestionState}
                      />
                    ) : (
                      <QuestionCard
                        languageSelected={languageSelected}
                        setLanguageSelected={setLanguageSelected}
                        question={question}
                        idOfSurveyCreator={selectedSurvey?.user_id}
                        changeQuestionState={changeQuestionState}
                      />
                    )}

                    <Space />
                  </div>
                );
              })}
            </ReactSortable>

            {/*<Card>*/}
            {/*  {buttonText === "Share Result" ? (*/}
            {/*    <Chip color="completed">Completed</Chip>*/}
            {/*  ) : (*/}
            {/*    <Chip color="open">Open</Chip>*/}
            {/*  )}*/}
            {/*  <Paragraph color="var(--bg-dark)" bold>*/}
            {/*    Which color of counter top do you prefer?*/}
            {/*  </Paragraph>*/}
            {/*  <CardSection>*/}
            {/*    <CardSectionElement>*/}
            {/*      This is an answer to the question.*/}
            {/*    </CardSectionElement>*/}
            {/*    <CardSectionElement>*/}
            {/*      This is an answer to the question.*/}
            {/*    </CardSectionElement>*/}
            {/*  </CardSection>*/}
            {/*  <Space />*/}
            {/*  <Composition gap={10}>*/}
            {/*    <Button*/}
            {/*      color="var(--bg-dark)"*/}
            {/*      onClick={() => handleSetButtonText("Share Result")}*/}
            {/*    >*/}
            {/*      {buttonText}*/}
            {/*    </Button>*/}
            {/*  </Composition>*/}
            {/*</Card>*/}
          </ScrollableContainer>
        </LeftMenuBody>
        <LeftMenuActions>
          {selectedSurvey?.state === SURVEY_STATE.DRAFT.key ||
          selectedSurvey?.state === SURVEY_STATE.ACTIVE.key ? (
            <Button
              color="var(--bg-dark)"
              padding="0px"
              onClick={() => createSurveyQuestion(selectedSurvey?.uuid)}
              width="50%"
            >
              {i18n.t("button.add_survey_question")}
            </Button>
          ) : (
            <Button
              padding="0px"
              width="50%"
              color="var(--bg-dark)"
              onClick={() =>
                changeSurveyState(selectedSurvey, SURVEY_STATE.DRAFT)
              }
            >
              {i18n.t("button.download")}
            </Button>
          )}
        </LeftMenuActions>
      </LeftMenu>
    </>
  );
}

const mapStateToProps = (state) => ({
  allWorkspaceSurveys: AllWorkspaceSurveys(state),
  surveyDataChanged: SurveyDataChanged(state),
  sendSurveyPing: SendSurveyPing(state),
  selectedWorkspace: SelectedWorkspace(state),
  actualMeeting: GetActualMeeting(state),
  user: getUser(state),
  companyTheme: GetCompanyTheme(state),
  currentLanguage: GetSelectedLanguage(state),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      SurveyWorkspaceGetAll,
      SurveyQuestionCreate,
      SurveyUpdate,
      SurveyResultGet,
      SurveyQuestionUpdate,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SurveyModule);
