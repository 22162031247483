import React from "react";
import * as S from "../styles";

export default function LabeledTextInput(props) {
  return (
    <div className={props.className}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {props.label && (
          <S.InputLabel htmlFor={props.label}>{props.label}: </S.InputLabel>
        )}

        {!!props.rightComponent && <div>{props.rightComponent}</div>}
      </div>

      <span style={props.label ? { marginTop: 4 } : null} />
      <S.TextInput id={props.label} {...props} />
    </div>
  );
}
