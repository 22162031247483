import { useEffect, useRef, useState } from "react";
import SurveyModule from "./SurveyModule/SurveyModule";

import {
    INSTRUCTIONS_INDICATOR_FORM,
    INSTRUCTIONS_PAGE,
    INSTRUCTIONS_STEP,
} from "../../Constants";
import MobileInAppMessenger from "./MessengerModule/MobileInAppMessenger";

import {
    setChatOpened,
    setDrawerModule,
    setSocialUserboardOpened,
} from "_redux/api/_app";
import { useSelector } from "react-redux";
import {
    GetSelectedChat,
    GetSelectedDrawerModule,
    GetSurveyQuestions,
} from "_redux/reducers/app";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import classes from "./MobileOverlayActions.module.css";
import { GetActual } from "_redux/reducers/company";
import { GetActual as SelectedWorkspace } from "_redux/reducers/workspace";
import { canEditWorkspace } from "KlarHelper";
import SurveyFloatingButton from "./SurveyModule/SurveyFloatingButton";

export const Modules = {
    survey: "survey",
    poll: "poll",
    messenger: "messenger",
    contacts: "contacts",
};

export default function MobileOverlayActions(props) {
    const location = useLocation();
    const dispatch = useDispatch();
    const openedModule = useSelector((state) => {
        return GetSelectedDrawerModule(state);
    });
    const openedChat = useSelector((state) => {
        return GetSelectedChat(state);
    });

    const actualCompany = useSelector((state) => {
        return GetActual(state);
    });

    const surveyQuestions = useSelector((state) => GetSurveyQuestions(state));
    const selectedWorkspace = useSelector((state) => {
        return SelectedWorkspace(state);
    });

    const [leftSurveyMenuOpen, setLeftSurveyMenuOpen] = useState(null);
    const [leftMessengerMenuOpen, setLeftMessengerMenuOpen] = useState(null);
    const [leftContactMenuOpen, setLeftContactMenuOpen] = useState(null);
    const overlayActionsRef = useRef();

    const toggleLeftSurveyMenu = (action) => {
        if (action === "close") {
            setLeftSurveyMenuOpen(null);
        } else {
            setLeftSurveyMenuOpen(true);
        }
    };

    const toggleLeftMessengerMenu = (action) => {
        if (action === "close") {
            setLeftMessengerMenuOpen(null);
        } else {
            setLeftMessengerMenuOpen(true);
        }
    };

    const toggleLeftContactMenu = (action) => {
        if (action === "close") {
            setLeftContactMenuOpen(null);
        } else {
            setLeftContactMenuOpen(true);
        }
    };

    const toggleOpen = (module) => {
        if (module != null && openedModule !== module) {
            dispatch(setDrawerModule(module));
        } else {
            dispatch(setDrawerModule(null));
            setLeftContactMenuOpen(null);
        }
    };

    useEffect(() => {
        if (openedModule) toggleOpen(null);
        dispatch(setSocialUserboardOpened(false));
    }, [location]);

    useEffect(() => {
        props.setComponentStats({
            [INSTRUCTIONS_PAGE.workspace]: {
                [INSTRUCTIONS_STEP.messenger]: {
                    coordinates:
                        overlayActionsRef.current.getBoundingClientRect(),
                    form: INSTRUCTIONS_INDICATOR_FORM.square,
                    bottom: true,
                    right: true,
                },
            },
        });
    }, [overlayActionsRef.current?.getBoundingClientRect()]);

    useEffect(() => {}, [leftMessengerMenuOpen]);

    useEffect(() => {
        //if(!openedModule){

        setLeftSurveyMenuOpen(null);
        setLeftMessengerMenuOpen(null);
        setLeftContactMenuOpen(null);

        //}
    }, [openedModule]);

    /*  const isAdmin = actualCompany?.role_value === 10;
  const isOwner = actualCompany?.role_value === 1;*/
    const isWorkspaceUser = actualCompany?.role_value;

    // const isWorkspaceEditor = actualCompany?.role_value<30;
    const isWorkspaceEditor = canEditWorkspace(selectedWorkspace?.role?.value);

    return (
        <div className={classes.root} ref={overlayActionsRef}>
            <MobileInAppMessenger
                user={props.user}
                active={openedModule === Modules.messenger}
                value={Modules.messenger}
                toggleOpen={toggleOpen}
            />

            {(isWorkspaceUser ||
                (props?.AllWorkspaceSurveys?.survey ?? []).length > 0) && (
                <>
                    <SurveyModule
                        active={openedModule === Modules.survey}
                        value={Modules.survey}
                        toggleOpen={toggleOpen}
                        toggleLeftMenu={toggleLeftSurveyMenu}
                        leftMenuOpen={leftSurveyMenuOpen}
                    />
                    <SurveyFloatingButton></SurveyFloatingButton>
                </>
            )}
        </div>
    );
}
