import React, { useEffect, useRef } from "react";

import { getUser as GetMyUser } from "_redux/reducers/login";

import { CONST } from "Constants";
import { RenderDateTime } from "KlarHelper";
import Icon from "ui/assets/Icon";

import "./GroupChat.css";
import MessageTextInput from "../MessageTextInput/MessageTextInput";

import { JoinCallGroup } from "../../../../_ws/GroupWebsocket";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setChatOpened } from "_redux/api/_app";
import MessengerChatMessage from "../MessengerChatMessage";
import { GetLastReadMessageTimeInGroup, SetLastReadMessageTimeInGroup } from "_redux/_models/MessagesDB";
import { IsMobile } from "Utils";
import { Portal } from "components/Portal";
import {
    MobileHeaderActionsPortal,
    MobileHeaderLeftActionPortal,
    MobileHeaderTitlePortal,
} from "ui/HeaderMobile/HeaderMobile";

export const getGroupId = (meeting) => {
    return meeting?.message_group_id
        ? meeting?.message_group_id
        : meeting?.uuid;
};

export default function MobileGroupChat({ meeting, messages }) {
    const listEndRef = useRef();
    const dispatch = useDispatch();
    const me = useSelector((state) => {
        return GetMyUser(state);
    });

    useEffect(() => {
        if (meeting) {
            const group_id = getGroupId(meeting);
            //The group should be already joined in MessangerModule/index.js - just to be sure :)
            JoinCallGroup(group_id, []);
        }
    }, []);

    useEffect(() => {
        if (listEndRef.current != null) {
            listEndRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [messages, messages.length]);

    const setAsShown = (ids) => {
        const group_id = getGroupId(meeting);
        SetLastReadMessageTimeInGroup(group_id, new Date().getTime());
    };

    const group_id = getGroupId(meeting);
    let lastMessageInGroupReadTime = GetLastReadMessageTimeInGroup(group_id);
    let lastDate = "";

    return (
        <div className="MessengerChat">
            {IsMobile && (
                <>
                    <Portal selector={MobileHeaderLeftActionPortal}>
                        <i
                            className={"icon-backBtn icon-chevron ia"}
                            onClick={() =>
                                dispatch(
                                    setChatOpened({
                                        opened: false,
                                        scope: "user",
                                        entity: null,
                                    })
                                )
                            }
                        ></i>
                    </Portal>
                    <Portal selector={MobileHeaderTitlePortal}>
                        <span>
                            {meeting?.name
                                ? meeting.name
                                : meeting?.groups?.name}
                        </span>
                    </Portal>
                    <Portal selector={MobileHeaderActionsPortal}>
                        <div></div>
                    </Portal>
                </>
            )}
            {!IsMobile() && (
                <div className="title">
                    <div className="titleLine">
                        {meeting?.name ? meeting.name : meeting?.groups?.name}
                    </div>

                    <Icon
                        className="arRight ia"
                        type={Icon.Types.chevron_right}
                        onClick={() =>
                            dispatch(
                                setChatOpened({
                                    opened: false,
                                    scope: "user",
                                    entity: null,
                                })
                            )
                        }
                    ></Icon>
                </div>
            )}
            <div className="list">
                {messages.map((msg, index) => {
                    if (msg.is_deleted) return null;
                    const isNew =
                        lastMessageInGroupReadTime < msg.timestamp.getTime() &&
                        msg.is_new &&
                        msg.from !== me.uuid;

                    let is_left = msg.from !== me.email;

                    if (isNew) {
                    } else {
                        msg.is_new = false;
                    }

                    let date = new Date(msg.timestamp);
                    let renderDate = RenderDateTime(date);
                    let dateString =
                        lastDate !== renderDate.date ? renderDate.date : null;
                    lastDate = renderDate.date;
                    if (dateString)
                        dateString = <div className="date">{dateString}</div>;

                    return (
                        <div key={index}>
                            {dateString ? dateString : null}
                            <MessengerChatMessage
                                msg={msg}
                                me={me}
                                user={
                                    is_left
                                        ? {
                                              email: msg.from,
                                              firstname: msg.from_name,
                                              lastname: "",
                                          }
                                        : me.email
                                }
                                setAsShownHandler={setAsShown}
                                group={meeting}
                            ></MessengerChatMessage>
                        </div>
                    );
                })}
                <div className="listEndAnchor" ref={listEndRef}></div>
            </div>
            <MessageTextInput
                chat={CONST.chat.group_chat}
                meeting={meeting}
                currentUser={me}
            />
        </div>
    );
}
