import styled, { css } from "styled-components/macro";
import { Button } from "styles";
import { OverlayActionsDrawer } from "../styles";
import Modal from "styled-react-modal";

export const SurveyActionDrawer = styled(OverlayActionsDrawer)`
  pointer-events: all;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: unset;
  border-bottom: 1px solid var(--black);
  background: ${({ background }) =>
    background ? background : "var(--bg-dark)"};
`;

export const QuestionCardActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  ${({ borderTop }) =>
    borderTop
      ? css`
          border-top: 1px solid var(--black);
          padding-top: 10px;
        `
      : null}
`;

export const QuestionsContainer = styled.div`
  display: grid;
  grid-gap: 10px;
  border-top: 1px solid;
  padding-top: 10px;
`;

export const QuestionIndicatorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 12px;
`;

export const SurveyIconContainer = styled.div`
  background-color: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  position: relative;
`;

export const OpenQuestionsIndicator = styled.div`
  position: absolute;
  border-radius: 50%;
  width: var(--margin-2);
  height: var(--margin-2);
  background-color: var(--main);
  color: var(--white);
  text-align: center;
  font-size: var(--fsize_3);
  line-height: calc(2.2 * var(--margin-1));
  right: -3px;
  top: -2px;
`;

export const StyledCheckboxWrapper = styled.div`
  margin-top: 20px;
  & .customCheckbox {
    display: flex;
  }
`;

export const StyledModal = Modal.styled`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: var(--gray-light);
  border-radius: 2px;
  position: relative;
  width: 600px;
  height: 200px;
  min-height: 200px;
  max-height: 90vh;
  overflow: auto;
  padding: var(--margin-5)
`;

export const ModalActionWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

export const ActionButton = styled(Button)`
  color: var(--black);
  border: 1px solid var(--black);
`;
