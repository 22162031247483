import React, { Component } from "react";

class DropZoneWrapper extends Component {
  constructor(props) {
    super(props);
    this.fileDropped = this.fileDropped.bind(this);
    this.done = this.done.bind(this);
    this.drop_obj_ref = React.createRef();
    this.ref = React.createRef();
    this.inputFile =React.createRef();
    this.dragOver = this.dragOver.bind(this);
    this.dragLeave = this.dragLeave.bind(this);
    this.dragEnter = this.dragEnter.bind(this);
    this.clicked = this.clicked.bind(this);
    this.onChangeFile = this.onChangeFile.bind(this);
    
  }

  fileDropped(e) {
    this.drop_obj_ref.current.classList.remove(this.props.dragOverClassName);
    this.pending_file_type = null;
    e.stopPropagation();
    e.preventDefault();
    let files = [];
    if (e.dataTransfer.items) {
      for (var i = 0; i < e.dataTransfer.items.length; i++) {
        if (e.dataTransfer.items[i].kind === "file") {
          var _file = e.dataTransfer.items[i].getAsFile();
          files.push(_file);
        }
      }
    } else {
      if (e.dataTransfer.files.length > 0) files = e.dataTransfer.files;
    }
    this.filesSelected(files);
  }

  filesSelected(files) {
    // document.getElementById("map_file").removeAttribute("pending_file_type");
    if (files == null || files.length === 0) {
      //	this.props.addAppMessage(i18n.t("fileuploader_errorInselectedFile_title"),  "null", APP_MESSAGE_TYPE_ERROR);
      return;
    }
    
    if (this.props.onFilesSelected){
      this.props.onFilesSelected(files);
      return;
    }


    this.files = [];
    this.fileReadQueue = [];

    if (files.length === 1) {
      let reader = new FileReader();
      reader._name = files[0].name;
      reader.readAsDataURL(files[0]);
      reader.onload = function (e) {
        this.done(reader.result, files[0]);
      }.bind(this);
      reader.onerror = function (error) {
        //	this.props.addAppMessage(i18n.t("fileuploader_errorInselectedFile_title"),  "null", APP_MESSAGE_TYPE_ERROR);
      };
    }

    
  }

  done(base64, file) {
    if (this.props.onDone) this.props.onDone(base64, file);
  }

  dragOver(e) {
    e.preventDefault();
  }

  dragEnter(e) {
    e.preventDefault();
    this.drop_obj_ref.current.classList.add(this.props.dragOverClassName);
  }

  dragLeave(e) {
    e.preventDefault();
    this.drop_obj_ref.current.classList.remove(this.props.dragOverClassName);
  }
  clicked(e){
    this.inputFile.current.click();
  }
  onChangeFile(e){
    
    this.filesSelected(e.target.files)
  }
  render() {
    if(this.props.disabled)
      return(<div>
        {this.props.children}
      </div>);

    return (
      <div
        ref={this.drop_obj_ref}
        onDrop={this.fileDropped}
        onDragOver={this.dragOver}
        onDragEnter={this.dragEnter}
        onDragLeave={this.dragLeave}
        onClick={this.props.onClickSelectFileEnabled?this.clicked:null}
        className={this.props.className?this.props.className:''}
      >
        {this.props.children}
        {this.props.onClickSelectFileEnabled && <input type='file' id='file' multiple={this.props.multiple} ref={this.inputFile} style={{display: 'none'}} onChange={this.onChangeFile.bind(this)}/>}
      </div>
    );
  }
}
export default DropZoneWrapper;
