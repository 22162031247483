import { store } from "..";
import { SendSocketIoRawMessage } from "./Websocket";
import {
    start as startCall,
    reject as rejectCall,
    hangup as hangUpCall,
} from "../_redux/api/call";

export function StartCall(from, to) {
    store.dispatch(startCall({ from: from, to: to }));
    SendSocketIoRawMessage("calling", { to: to });
}

export function AcceptCall(from, to) {
    //reducer will be updated in Websocket.on("acceptCall") with meeting object
    SendSocketIoRawMessage("acceptCall", { to: to });
}

export function RejectCall(from, to) {
    store.dispatch(rejectCall({ from: from, to: to }));
    SendSocketIoRawMessage("rejectCall", { to: to });
}

export function HangUpCall(from, to) {
    store.dispatch(hangUpCall({ from: from, to: to }));
    SendSocketIoRawMessage("hangUpCall", { to: to });
}
