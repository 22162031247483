import { CONST } from "../../Constants";

export function ParseFolder(raw) {
  try {
    let folder = new Folder();
    folder.uuid = raw.uuid;
    folder.name = raw.name;
    folder.icon = raw.icon;
    folder.parent_id = raw.parent_id;
    folder.workspace_id = raw.workspace_id;
  } catch (e) {
    console.error("Error parsing folder", raw);
  }
  return null;
}

export class Folder {
  constructor() {
    this.name = CONST._empty;
    this.icon = CONST._empty;
    this.parent_id = CONST._empty;
    this.workspace_id = CONST._empty;
    this.uuid = CONST._empty;
  }
}
