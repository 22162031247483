import React, { PureComponent } from "react";
import { CONST } from "../../../Constants";

import "./Icon.css";
import classes from "./Icon.module.css";
class Icon extends PureComponent {
  render() {
    let className = CONST._empty;
    if (this.props.className != null) className += CONST._space + this.props.className;
    if (this.props.type != null) className += CONST._space + this.props.type;
    if (this.props.color != null) className += CONST._space + this.props.color;
    if (this.props.nested != null) className += CONST._space + "fillparent";
    if (this.props.hidden) className += CONST.classes._hidden;
    if (this.props.active) className += CONST.classes._active;
    if (this.props.disabled) className += CONST.classes._disabled;
    let customStyles = { maskRepeat: "no-repeat" };
    if (this.props.icon) customStyles["--path"] = "url(" + this.props.icon + ")";

    let title = this.props.type;
    if (this.props.title) title = this.props.title;

    if (this.props.onClick)
      return (
        <div
          className={"Icon" + className}
          onClick={this.props.onClick}
          style={customStyles === {} ? customStyles : this.props.styling}
          title={this.props.notShowTitle ? "" : title}
          style={this.props.style}
        >
          <div className="bgmask" style={{ maskRepeat: "no-repeat", ...this.props.customStyleIcon }}></div>
          {this.props.badge && this.props.badge != "" && <div className={classes.badge}>{this.props.badge}</div>}
        </div>
      );
    return (
      <div className={"Icon" + className} style={this.props.styling}>
        <div
          className="bgmask"
          style={{ ...customStyles, ...this.props.customStyleIcon }}
          title={this.props.notShowTitle ? "" : title}
        ></div>
        {this.props.badge && this.props.badge != "" && <div className={classes.badge}>{this.props.badge}</div>}
      </div>
    );
  }
}
export default Icon;

Icon.Types = {
  _3d: "_3d",
  _360: "_360",
  back: "back",
  close_2: "close_2",

  hand: "hand",
  hotspot: "hotspot",
  info: "info",
  logout: "logout",
  mail: "mail",

  next: "next-page",
  palette: "palette",
  play: "play-button",
  refresh: "refresh-button",
  right: "right",
  settings: "settings",
  custom: "custom",

  threejs_playOnClick: "threejs_playOnClick",
  threejs_uiHide: "threejs_uiHide",
  threejs_uiShow: "threejs_uiShow",
  threejs_3ddetail: "threejs_3ddetail",
  threejs_3ddetail_no_space: "threejs_3ddetail_no_space",
  threejs_animation: "threejs_animation",
  threejs_animation_no_space: "threejs_animation_no_space",
  threejs_close: "threejs_close",
  threejs_close_no_space: "threejs_close_no_space",
  threejs_comments: "threejs_comments",
  threejs_comments_no_space: "threejs_comments_no_space",
  threejs_configuration: "threejs_configuration",
  threejs_configuration_no_space: "threejs_configuration_no_space",
  threejs_info: "threejs_info",
  threejs_info_no_space: "threejs_info_no_space",
  threejs_links: "threejs_links",
  threejs_links_no_space: "threejs_links_no_space",
  threejs_material: "threejs_material",
  threejs_material_no_space: "threejs_material_no_space",
  threejs_media: "threejs_media",
  threejs_media_no_space: "threejs_media_no_space",
  threejs_tint: "threejs_media",
  threejs_tint_no_space: "threejs_tint_no_space",

  threejs_perspectives: "threejs_perspectives",
  threejs_object_camera: "threejs_object_camera",
  threejs_object_floor: "threejs_object_floor",
  threejs_object_light: "threejs_object_light",
  threejs_object_mesh: "threejs_object_mesh",
  threejs_object_meshcollider: "threejs_object_meshcollider",
  threejs_object_meshgroup: "threejs_object_meshgroup",
  threejs_object_navmesh: "threejs_object_navmesh",
  threejs_object_spot: "threejs_object_spot",
  threejs_object_sprite: "threejs_object_sprite",

  video_audio_off: "video_audio_off",
  video_audio_on: "video_audio_on",
  video_call_end: "video_call_end",
  video_call_start: "video_call_start",
  video_video_off: "video_video_off",
  video_video_on: "video_video_on",

  notifications_off: "notifications_off",
  notifications_on: "notifications_on",
  groups: "groups",

  activity: "activity",
  agenda: "agenda",
  audio_on: "audio-on",
  audio_off: "audio-off",
  archive: "archive",
  calendar_add: "calendar-add",
  calendar: "calendar",
  call: "call",
  call_accept: "call_accept",
  call_end: "call_end",
  call_waiting: "call_waiting",
  cam: "cam",

  sharescreen: "sharescreen",
  chat: "chat",
  chevron_right: "chevron_right",
  chevron_up: "chevron_up",
  chevron_down: "chevron_down",
  clock: "clock",
  close: "close",
  company: "company",
  contact_request: "contact_request",
  contact_suggestions: "contact-suggestions",
  contactinfo: "contactinfo",
  contacts_saved: "contacts-saved",
  contacts: "contacts",
  document: "document",
  down: "down",
  download: "download",
  download_ics: "download_ics",
  enter: "enter",
  filetype_: "file-type-unknown",
  filetype_image: "file-type-image",
  filetype_video: "file-type-video",
  filetype_text: "file-type-text",
  filetype_pdf: "file-type-pdf",
  filetype_object_view: "file-type-object_view",

  html: "html",
  iframe: "iframe",
  image: "image",
  left: "left",
  like: "like",
  link: "link",
  messenger_user: "messenger_user",
  menu: "menu",
  mic: "mic",
  network: "network",
  overview: "overview",
  plus: "plus",
  preview: "preview",

  remote_sharing: "remote_sharing",
  remote_listening: "remote_listening",

  profile: "profile",
  raisehand: "raisehand",
  save: "save",
  schedule: "schedule",
  search: "search",
  skills: "skills",
  social_feed: "_3d",
  sort_list: "sort_list",
  sort_tiles: "sort_tiles",
  stage: "stage",
  time: "time",
  trash: "trash",
  user: "user",
  video: "video",
  video_on: "video-on",
  video_off: "video-off",
  videocall: "icon-videocall",
  workshop: "workshop",
  write: "write",
  zoom_in: "zoom-in",
  zoom_out: "zoom-out",

  meeting_type_call: "meeting-type-call",
  meeting_type_broadcast: "meeting-type-broadcast",
  meeting_type_conference: "meeting-type-conference",
  edite: "edite",

  home: "home",
  ar: "ar",
  broadcast_listen: "broadcast_listen",
  broadcast_close: "broadcast_close",
  broadcast_maximize: "broadcast_maximize",
  broadcast_minimize: "broadcast_minimize",
  broadcast_sharing: "broadcast_sharing",
  question_mark: "question_mark",
  tick: "tick"
};

Icon.Colors = {
  white: "white",
  black: "black",
  dark: "dark",
  blue: "blue",
  main: "main",
  red: "red",
  green: "green"
};
