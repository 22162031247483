import {
  APP_LANGUAGE_CHANGE
} from "../actions/language";
import { AVAILABLE_TRANSLATIONS } from "../../Constants";

const initialState = {
  languages: AVAILABLE_TRANSLATIONS,
  selected: "de",
};

export const languageReducer = (state = initialState, action) => {
  switch (action.type) {
    case APP_LANGUAGE_CHANGE:
      return {
        ...state,
        selected: action.payload,
      };

    default:
      return state;
  }
};
export const GetAllLanguages = (state) => state.language.languages;
export const GetSelectedLanguage = (state) => state.language.selected;
