import React from "react";
import * as S from "../styles";

export default function LabeledSelect(props) {
  return (
    <div>
      <S.InputLabel htmlFor={props.label} color={props.labelColor}>
        {props.label}:{" "}
      </S.InputLabel>
      <span style={props.label ? { marginTop: 3 } : null} />
      <S.Select id={props.label} {...props}>
        {props.options.map((option) => {
          return (
            <option
              key={typeof option === "string" ? option : option.value}
              value={typeof option === "string" ? option : option.value}
            >
              {typeof option === "string" ? option : option.label}
            </option>
          );
        })}
        {props.children}
      </S.Select>
    </div>
  );
}
