import {RESPONSE_TYPE} from './../api/_api.js';

export const API_REQUEST_ADD = 'API_REQUEST_ADD';
export const API_REQUEST_DONE = 'API_REQUEST_DONE';

export function apiRequestStart() {
	return {
	 	type: API_REQUEST_ADD
	};
}

export function apiRequestDone() {
	return {
	 	type: API_REQUEST_DONE
	};
}

export function GenericAction(type, info, payload, _PENDING, _SUCCESS, _ERROR)
{
	if(type === RESPONSE_TYPE.PENDING){return {type: _PENDING, payload:info};}
	else if(type === RESPONSE_TYPE.SUCCESS){return {type: _SUCCESS,info: info,payload: payload};}
	else if(type === RESPONSE_TYPE.ERROR){return {type: _ERROR,info: info,payload: payload};
	}
}
