import styled, { css, keyframes } from "styled-components/macro";
import Icon from "../ui/assets/Icon";
import UploadDropZone from "@rpldy/upload-drop-zone";
import UploadButton from "@rpldy/upload-button";
import DatePicker from "react-datepicker";
import { SURVEY_STATE } from "../Constants";
import { IsMobile } from "Utils";

export const StyledIcon = styled(Icon)`
  color: ${(props) => props.color};
  cursor: pointer;
  &:hover {
    color: var(--main);
  }
`;

export const TextInput = styled.input`
  background-color: ${(props) =>
    props.bgColor ? props.bgColor : "var(--gray-dark)"};
  border-color: var(--gray-dark);
  font-size: var(--fsize_4);
  color: ${(props) => (props.color ? props.color : "var(--gray-light)")};
  width: ${(props) => (props.width ? props.width : "")};
  height: ${(props) => (props.height ? props.height : "")};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "")};
  padding: 8px;
  padding-top: 10px;
  border-radius: 4px;
  &:hover {
    outline: 2px solid var(--main-semi);
  }
  &:focus {
    outline: 2px solid var(--main);
  }
  &:disabled {
    background-color: var(--black-middle);
    color: var(--gray-middle);
    outline: none;
  }
`;

export const TextArea = styled.textarea`
  background-color: ${(props) =>
    props.bgColor ? props.bgColor : "var(--gray-dark)"};
  border-color: var(--gray-dark);
  font-size: var(--fsize_4);
  color: ${(props) => (props.color ? props.color : "var(--gray-light)")};
  width: ${(props) => (props.width ? props.width : "")};
  height: ${(props) => (props.height ? props.height : "")};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "")};
  padding: 8px;
  padding-top: 10px;
  border-radius: 4px;
  &:hover {
    outline: 2px solid var(--main-semi);
  }
  &:focus {
    outline: 2px solid var(--main);
  }
`;

export const InputLabel = styled.label`
  display: block;
  color: ${(props) => (props.color ? props.color : "var(--gray-light)")};
`;

export const Button = styled.button`
  --bg:${(props) =>{
    if(props.type==="main")
      return "var(--main)";
    else if(props.type==="danger")
      return "var(--red)";
    else if(props.type==="success")
      return "var(--green)";
    else if(props.type==="info")
      return "var(--bg-5)";
    return 'inherit';
    }
  };
  vertical-align:middle;
  background-color: ${(props) =>props.outline?'inherit':"var(--bg)"};
  border-color: var(--gray-dark);
  font-size: var(--fsize_4);
  color: ${(props) => (props.color ? props.color : "var(--gray-light)")};
  width: ${(props) => (props.width ? props.width : "")};
  padding: ${(props) => (props.padding ? props.padding :IsMobile() || props.small?"var(--margin-1)":"var(--margin-2)")};
  padding-left: ${(props) =>
    props.padding
      ? props.padding
      : props.paddingSides
      ? props.paddingSides
      : IsMobile() || props.small?"var(--margin-1)":"var(--margin-3)"};
  padding-right: ${(props) =>
    props.padding
      ? props.padding
      : props.paddingSides
      ? props.paddingSides
      : IsMobile() || props.small?"var(--margin-1)":"var(--margin-3)"};
  border-radius: 4px;
  border:1px solid var(--bg);
  cursor: pointer;
  &:hover {
    outline: 2px solid var(--main-semi);
  }
  line-height:1;
`;

export const Select = styled.select`
  background-color: ${(props) =>
    props.bgColor ? props.bgColor : "var(--gray-dark)"};
  border-color: var(--gray-dark);
  font-size: var(--fsize_4);
  color: ${(props) => (props.color ? props.color : "var(--gray-light)")};
  width: ${(props) => (props.width ? props.width : "")};
  padding: 8px;
  padding-top: 10px;
  border-radius: 4px;
  &:hover {
    outline: 2px solid var(--main-semi);
  }
  &:focus {
    outline: 2px solid var(--main);
  }
`;

export const Option = styled.option``;

export const Space = styled.span`
  margin: ${(props) => (props.size ? props.size : "5px")};
  ${(props) => (props.left ? "margin-left:" + props.size : null)}
  ${(props) => (props.right ? "margin-right:" + props.size : null)}
  ${(props) => (props.top ? "margin-top:" + props.size : null)}
  ${(props) => (props.bottom ? "margin-bottom:" + props.size : null)}
  display: inline-block;
`;

export const Paragraph = styled.p`
  color: ${(props) => (props.color ? props.color : "var(--gray-light)")};
  ${(props) => (props.size ? "font-size: " + props.size : null)}!important;
  ${(props) => (props.max ? "max-width: " + props.max : null)}
  ${(props) => (props.bold ? "font-weight: 700!important;" : null)}
  ${(props) => (props.align ? "text-align: " + props.align + ";" : null)}
  ${(props) => (props.margin ? "margin: " + props.margin + ";" : null)}
  ${(props) => (props.inline ? "display: inline;" : null)}
  ${(props) => (props.flex ? "display: flex;" : null)}
  ${(props) =>
    props.paddingTop ? "padding-top: " + props.paddingTop + ";" : null}
`;

export const CustomIcon = styled.div`
  width: ${(props) => (props.size ? props.size + "px" : "100%")};
  height: ${(props) => (props.size ? props.size + "px" : "100%")};
  mask-image: ${(props) =>
    props.icon
      ? "url(/images/" + props.icon + ".svg)"
      : "url(/images/icon-plus.svg)"};
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: ${(props) => (props.size ? props.size - 5 + "px" : "50px")};
  background-color: ${(props) => (props.color ? props.color : "gray")};
  padding-left: 5px;
  padding-right: 5px;
  display: inline-block;
  ${(props) =>
    props.hoverable &&
    css`
      &:hover {
        background-color: var(--main);
        cursor: pointer;
      }
    `}
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 2px;
`;

export const TableHeader = styled.thead`
  background-color: var(--table-header);
`;

export const TableDataContainer = styled.tbody`
  overflow: auto;
`;

export const TableDataRow = styled.tr`
  background-color: var(--table-row);
`;

export const TableCell = styled.td`
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
`;

export const Dropzone = styled(UploadDropZone)`
  background-color: var(--gray-dark);
  border: 1px solid var(--gray-dark);
  border-radius: 4px;
  width: ${(props) => (props.width ? props.width : "100%")};
  height: ${(props) => (props.height ? props.height : "100%")};
  &:hover {
    outline: 2px solid var(--main-semi);
  }
  padding: 10px;
`;

export const CustomUploadButton = styled(UploadButton)`
  background-color: var(--gray-light);
  font-size: var(--fsize_4);
  color: var(--black-middle);
  padding: 10px;
  padding-top: 12px;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 6px;
  height: 45px;
  cursor: pointer;
  &:hover {
    outline: 2px solid var(--main-semi);
  }
`;

export const Card = styled.div`
  position: relative;
  width: 100%;
  padding: 10px;
  ${({ indicator }) =>
    indicator
      ? "border: 2px solid " + handleColorType(indicator)
      : "border: 1px solid var(--gray-dark-semi)"}}
  border-radius: 8px;
  box-shadow: 0px 0px 0.6px rgba(0, 0, 0, 0.105),
    0px 0px 5px rgba(0, 0, 0, 0.21);
`;

export const CardSection = styled.div`
  overflow: auto;
  max-height: 150px;
  width: 100%;
  padding: 2px;
`;

export const CardSectionElement = styled.div`
  border: 1px solid var(--gray-dark-semi);
  border-radius: 8px;
  padding: 8px;
  margin-bottom: 5px;
  min-height: 35px;
`;

export const handleColorType = (color) => {
  switch (color) {
    case SURVEY_STATE.ACTIVE.key:
      return "var(--state-green)";
    case SURVEY_STATE.DRAFT.key:
      return "var(--gray-dark)";
    case SURVEY_STATE.CLOSED.key:
      return "var(--gray-dark)";
    case "result":
      return "var(--main)";
    case SURVEY_STATE.PUBLISHED.key:
      return "var(--main)";
    case "saving":
      return "var(--main)";
    case "saved":
      return "var(--state-green)";
    case "ended":
      return "var(--gray-dark)";
    default:
      return "#fff";
  }
};

export const Chip = styled.div`
  background-color: ${({ color }) => handleColorType(color)};
  padding: 4px;
  padding-left: 7px;
  padding-right: 7px;
  display: inline-block;
  color: white;
  border-radius: 14px;
  box-shadow: 0px 0px 0.3px rgba(0, 0, 0, 0.18), 0px 0px 2px rgba(0, 0, 0, 0.36);
  text-align: center;
  vertical-align: middle;
  height: 23px;
  line-height: 19px;
`;

export const CustomDatePicker = styled(DatePicker)`
  background-color: ${(props) =>
    props.bgColor ? props.bgColor : "var(--gray-dark)"};
  border-color: var(--gray-dark);
  font-size: var(--fsize_4);
  color: ${(props) => (props.color ? props.color : "var(--gray-light)")};
  width: ${(props) => (props.width ? props.width : "")};
  height: ${(props) => (props.height ? props.height : "")};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "")};
  padding: 8px;
  padding-top: 10px;
  border-radius: 4px;
  &:hover {
    outline: 2px solid var(--main-semi);
  }
  &:focus {
    outline: 2px solid var(--main);
  }
  &:disabled {
    background-color: var(--black-middle);
    color: var(--gray-middle);
    outline: none;
  }
`;

export const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div`
  --lineWidth:${(props) => (props.lineWidth ? props.lineWidth : "4px")};
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);
  border-top: var(--lineWidth) solid var(--gray-dark);
  border-right: var(--lineWidth) solid var(--gray-dark);
  border-bottom: var(--lineWidth) solid var(--gray-dark);
  border-left: var(--lineWidth) solid var(--main);
  background: transparent;
  width: ${(props) => (props.size ? props.size : "60px")};
  height: ${(props) => (props.size ? props.size : "60px")};
  border-radius: 50%;
`;

export const RoundButton = styled.div`
  border: 1px solid var(--bg-dark);
  border-radius: 34px;
  text-align: center;
  background-color: ${(props) =>
    props.bgColor ? props.bgColor : "var(--gray-middle)"};
  &:hover {
    cursor: pointer;
    outline: 3px solid var(--main);
  }
`;
