import { store } from "..";
import { User } from "_redux/_models/User";
import { SendSocketIoRawMessage, notifyListeners } from "./Websocket";
import { UpdateOrAddUser } from "_redux/api/users";

import { FindUserByEmail } from "_redux/reducers/users";
export function Init(socket) {
    /*socket.on(Events.answerConnexionRequest, (data) => {
        notifyListeners(Events.answerConnexionRequest, data);
    });

    socket.on(Events.sendConnexionRequest, (data) => {
        notifyListeners(Events.sendConnexionRequest, data);
    });
    */
    socket.on(Events.answeredConnexionRequest, (data) => {
        if (data.action === "accept") {
            if (data.from) {
                data.from.connectionType = User.ConnectionTypes.CONNECTED;
                store.dispatch(UpdateOrAddUser(data.from));
            }
        } else if (data.action === "deny") {
            if (data.from) {
                data.from.connectionType = "UNKNOWN";
                store.dispatch(UpdateOrAddUser(data.from));
            }
        }
        notifyListeners(Events.answeredConnexionRequest, data);
    });

    socket.on(Events.receiveConnexionRequest, (data) => {
        if (data.action === "request") {
            if (data.from) {
                data.from.connectionType = User.ConnectionTypes.REQUESTED_ME;
                store.dispatch(UpdateOrAddUser(data.from));
            }
        }
        notifyListeners(Events.receiveConnexionRequest, data);
    });
}

export const SendConnexionRequest = (email) => {
    const user = FindUserByEmail(email);
    if (user) {
        user.connectionType = User.ConnectionTypes.REQUESTED_I;
        store.dispatch(UpdateOrAddUser(user));
    }
    SendSocketIoRawMessage(Events.sendConnexionRequest, {
        action: "request",
        data: { email },
    });
};

export const SendConnexionAnswer = (email, accepted) => {
    const user = FindUserByEmail(email);

    if (user && accepted) {
        user.connectionType = User.ConnectionTypes.CONNECTED;
        store.dispatch(UpdateOrAddUser(user));
    } else if (user && !accepted) {
        user.connectionType = null;
        store.dispatch(UpdateOrAddUser(user));
    }

    SendSocketIoRawMessage(Events.answerConnexionRequest, {
        action: accepted ? "accept" : "deny",
        data: { email },
    });
};

export const Events = {
    answerConnexionRequest: "answerConnexionRequest",
    sendConnexionRequest: "sendConnexionRequest",
    receiveConnexionRequest: "receiveConnexionRequest",
    answeredConnexionRequest: "answeredConnexionRequest",
};
