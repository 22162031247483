export const FOLDERS_GET = {
  PENDING: "FOLDERS_GET_PENDING",
  SUCCESS: "FOLDERS_GET_SUCCESS",
  ERROR: "FOLDERS_GET_ERROR",
};
export const FOLDERS_CREATE = {
  PENDING: "FOLDERS_CREATE_PENDING",
  SUCCESS: "FOLDERS_CREATE_SUCCESS",
  ERROR: "FOLDERS_CREATE_ERROR",
};
export const FOLDERS_UPDATE = {
  PENDING: "FOLDERS_UPDATE_PENDING",
  SUCCESS: "FOLDERS_UPDATE_SUCCESS",
  ERROR: "FOLDERS_UPDATE_ERROR",
};

export const FOLDERS_DELETE = {
  PENDING: "FOLDERS_DELETE_PENDING",
  SUCCESS: "FOLDERS_DELETE_SUCCESS",
  ERROR: "FOLDERS_DELETE_ERROR",
};

export const FOLDERS_GET_INWORKSPACE = {
  PENDING: "FOLDERS_GET_INWORKSPACE_PENDING",
  SUCCESS: "FOLDERS_GET_INWORKSPACE_SUCCESS",
  ERROR: "FOLDERS_GET_INWORKSPACE_ERROR",
};

export function _getInWorkspace(type, info, payload) {
  return {
    type: FOLDERS_GET_INWORKSPACE[type],
    info: info,
    payload: payload,
  };
}

export function _get(type, info, payload) {
  return { type: FOLDERS_GET[type], info: info, payload: payload };
}
export function _delete(type, info, payload) {
  return { type: FOLDERS_DELETE[type], info: info, payload: payload };
}
export function _update(type, info, payload) {
  return { type: FOLDERS_UPDATE[type], info: info, payload: payload };
}
export function _create(type, info, payload) {
  return { type: FOLDERS_CREATE[type], info: info, payload: payload };
}
