import { EMPTY } from "../../Constants";

export class Call {
  constructor() {
    this.from = EMPTY;
    this.to = EMPTY;
    this.meeting = null;
    this.state = States._default

    this.id = EMPTY;
  }

  hasUser(uuid){
    if(this.isFromUser(uuid))
      return true;
    if(this.isToUser(uuid))
      return true;
  }

  isToUser(uuid){
    if(this.to === uuid)
      return true;
  }

  isFromUser(uuid){
    if(this.from === uuid)
      return true;
  }
}

export function CreateId(from, to)
{
  let id = from+"#"+to;
  if(from == null)
  {
    console.error("from not set",from, to);
    return id;
  }
  if(from.localeCompare(to)>0)
    id = to + "#" + from;
  return id;
}

export const States = {
  _default: "_default",
  started: "started",
  accepted: "accepted",
  rejected: "rejected",
  hangedUp: "hangedUp"
};
