import {
  _get as getMeeting,
  _getAvailable as getAvailableMeetings,
  _getPast as getPastMeetings,
  _update as updateMeeting,
  _create as createMeeting,
  _delete as deleteMeeting,
  _getUserByStreamId as getUserByStreamId,
  _getUsers as getMeetingUsers,
  _setUsers as setMeetingUsers,
  _getUsers1 as getMeetingUsers1,
  _setResponse as setMeetingResponse,
  _resetCurrentMeeting,
} from "./../actions/meeting";
import { _doRequest, Endpoints } from "./_api.js";
export function _get(data, callBackFn) {
  //{meeting_id:'meetinguuid'}
  return _doRequest(Endpoints.meeting_get, getMeeting, data, callBackFn);
}

export function _getAvailable(data) {
  return _doRequest(Endpoints.meeting_getAll, getAvailableMeetings, data);
}

export function _getPast(data) {
  return _doRequest(Endpoints.meeting_getPast, getPastMeetings, data);
}

export function _create(data) {
  return _doRequest(Endpoints.meeting_create, createMeeting, data);
}

export function _update(data) {
  return _doRequest(Endpoints.meeting_update, updateMeeting, data);
}

export function _delete(data) {
  return _doRequest(Endpoints.meeting_delete, deleteMeeting, data);
}

export function _getUserByStreamId(data) {
  return _doRequest(
    Endpoints.meeting_getUserByStreamIid,
    getUserByStreamId,
    data
  );
}
export function _getUsers(data) {
  return _doRequest(Endpoints.meeting_getUsers, getMeetingUsers, data);
}

export function _setUsers(data) {
  return _doRequest(Endpoints.meeting_setUsers, setMeetingUsers, data);
}
export function _getUsers1(data) {
  return _doRequest(Endpoints.meeting_getUsers1, getMeetingUsers1, data);
}

export function _setResponse(data) {
  return _doRequest(Endpoints.meeting_setResponse, setMeetingResponse, data);
}

export function _signUpMeeting(data) {
  return _doRequest(Endpoints.meeting_setUp, _getAvailable, data);
}
/*
export function _getUsers(data) {
	return _doRequest(Endpoints.meeting_getUser, getMeetingUsers, data);	
}
export function _setUsers(data) {
	return _doRequest(Endpoints.meeting_getUser, getMeetingUsers, data);	
}
export function deleteUsers(data) {
	return _doRequest(Endpoints.meeting_getUser, getMeetingUsers, data);	
}*/

export function resetCurrentMeeting(data) {
  return (dispatch) => {
    dispatch(_resetCurrentMeeting(data));
  };
}
