import {
  SURVEY_GET,
  SURVEY_RESULT_GET,
  SURVEY_USER_ANSWER,
  SURVEY_WORKSPACE_GET_ALL,
  SURVEY_UPDATE,
  SURVEY_QUESTION_UPDATE,
  SURVEY_QUESTION_OPTION_UPDATE,
  SURVEY_DELETE,
  SURVEY_QUESTION_DELETE,
  SURVEY_QUESTION_OPTION_DELETE,
  SURVEY_QUESTION_CREATE,
  SURVEY_QUESTION_OPTION_CREATE,
  SURVEY_CREATE,
} from "../actions/survey";

import { initialState } from "initialState.js";
import { SURVEY_STATE } from "../../Constants";
import { PingTypes } from "../../_ws/SurveyWebsocket";

export function surveyReducer(state = initialState, action) {
  switch (action.type) {
    case SURVEY_GET.SUCCESS:
      return {
        ...state,
        survey: action.payload,
        pending: false,
      };
    case SURVEY_GET.PENDING:
      return {
        ...state,
        pending: true,
      };
    case SURVEY_GET.ERROR:
      return {
        ...state,
        error: action.info,
        pending: false,
      };
    case SURVEY_CREATE.SUCCESS:
      return {
        ...state,
        survey_data_changed: action.payload,
        pending: false,
      };
    case SURVEY_CREATE.PENDING:
      return {
        ...state,
        pending: true,
      };
    case SURVEY_CREATE.ERROR:
      return {
        ...state,
        error: action.info,
        pending: false,
      };
    case SURVEY_UPDATE.SUCCESS:
      return {
        ...state,
        survey_data_changed: action.payload,
        pending: false,
      };
    case SURVEY_UPDATE.PENDING:
      return {
        ...state,
        pending: true,
      };
    case SURVEY_UPDATE.ERROR:
      return {
        ...state,
        error: action.info,
        pending: false,
      };
    case SURVEY_WORKSPACE_GET_ALL.SUCCESS:
      
      return {
        ...state,
        all_surveys: action.payload,
        pending: false,
      };
    case SURVEY_WORKSPACE_GET_ALL.PENDING:
      return {
        ...state,
        pending: true,
      };
    case SURVEY_WORKSPACE_GET_ALL.ERROR:
      return {
        ...state,
        error: action.info,
        pending: false,
      };
    case SURVEY_QUESTION_CREATE.SUCCESS:
      return {
        ...state,
        survey_data_changed: action.payload,
        pending: false,
      };
    case SURVEY_QUESTION_CREATE.PENDING:
      return {
        ...state,
        pending: true,
      };
    case SURVEY_QUESTION_CREATE.ERROR:
      return {
        ...state,
        error: action.info,
        pending: false,
      };
    case SURVEY_QUESTION_DELETE.SUCCESS:
      return {
        ...state,
        survey_data_changed: action.payload,
        sendPing: {
          payload: action.payload,
          pingType: PingTypes.surveyQuestionDeleted,
        },
        pending: false,
      };
    case SURVEY_QUESTION_DELETE.PENDING:
      return {
        ...state,
        pending: true,
      };
    case SURVEY_QUESTION_DELETE.ERROR:
      return {
        ...state,
        error: action.info,
        pending: false,
      };
    case SURVEY_QUESTION_OPTION_CREATE.SUCCESS:
      return {
        ...state,
        survey_data_changed: action.payload,
        pending: false,
      };
    case SURVEY_QUESTION_OPTION_CREATE.PENDING:
      return {
        ...state,
        pending: true,
      };
    case SURVEY_QUESTION_OPTION_CREATE.ERROR:
      return {
        ...state,
        error: action.info,
        pending: false,
      };
    case SURVEY_DELETE.SUCCESS:
      return {
        ...state,
        survey_data_changed: action.payload,
        sendPing: {
          payload: action.payload,
          pingType: PingTypes.surveyDeleted,
        },
        pending: false,
      };
    case SURVEY_DELETE.PENDING:
      return {
        ...state,
        pending: true,
      };
    case SURVEY_DELETE.ERROR:
      return {
        ...state,
        error: action.info,
        pending: false,
      };
    case SURVEY_QUESTION_OPTION_DELETE.SUCCESS:
      return {
        ...state,
        survey_data_changed: action.payload,
        pending: false,
      };
    case SURVEY_QUESTION_OPTION_DELETE.PENDING:
      return {
        ...state,
        pending: true,
      };
    case SURVEY_QUESTION_OPTION_DELETE.ERROR:
      return {
        ...state,
        error: action.info,
        pending: false,
      };
    case SURVEY_QUESTION_UPDATE.SUCCESS:
      let respObj = {
        ...state,
        survey_data_changed: action.payload,
        pending: false,
      };

      let questionBefore = state.all_surveys.survey
        .filter((obj) => obj.uuid === action.payload.question.survey_id)[0]
        .questions.filter(
          (obj) => obj.uuid === action.payload.question.uuid
        )[0];
      let questionAfter = action.payload.question;

      if (questionBefore.state !== questionAfter.state) {
        // Check if the question state has changed in order to decide whether to send a ping
        respObj["sendPing"] = {
          payload: action.payload,
          pingType: PingTypes.questionStateChanged,
        };
      }

      if (
        questionAfter.state !== SURVEY_STATE.DRAFT.key &&
        questionAfter.state !== SURVEY_STATE.CLOSED.key &&
        questionBefore.sort_id !== questionAfter.sort_id
      ) {
        // Check if the question sort_id has changed and is correct state,
        // in order to decide whether to send a ping
        respObj["sendPing"] = {
          payload: action.payload,
          pingType: PingTypes.questionStateChanged,
        };
      }

      return respObj;
    case SURVEY_QUESTION_OPTION_UPDATE.SUCCESS:
      return {
        ...state,
        // survey_data_changed: action.payload,
        pending: false,
      };
    case SURVEY_USER_ANSWER.SUCCESS:
      return {
        ...state,
        survey_data_changed: action.payload,
        sendPing: {
          payload: action.payload,
          pingType: PingTypes.questionAnswered,
        },
        pending: false,
      };
    case SURVEY_RESULT_GET.SUCCESS:
      return {
        ...state,
        survey_result: action.payload,
        pending: false,
      };
    default:
      return state;
  }
}

export const AllWorkspaceSurveys = (state) => state.survey.all_surveys;
export const GetSurveyInformation = (state) => state.survey.survey;
export const SurveyDataChanged = (state) => state.survey.survey_data_changed;
export const SendSurveyPing = (state) => state.survey.sendPing;
export const SurveyResult = (state) => state.survey.survey_result;
