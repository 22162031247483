
import {
  WEBSOCKET_REMOTE_RECEIVED,
  WEBSOCKET_SET_LISTENING_GROUP,
  WEBSOCKET_REMOTE_SET_SHARER,
  WEBSOCKET_SET_ACTIVE_GROUP,
  WEBSOCKET_SET_GROUP,
  WEBSOCKET_NEW_JOINER
} from './../actions/websocket';
  
import {initialState}  from 'initialState.js';

export function websocketReducer(state = initialState, action) {
  switch(action.type) {
    case WEBSOCKET_REMOTE_RECEIVED:{  
      console.error("WEBSOCKET_REMOTE_RECEIVED")    
      return{...state, lastMessage:action.val}
    }
    case WEBSOCKET_REMOTE_SET_SHARER:{
      console.error("WEBSOCKET_REMOTE_SET_SHARER")
      let group_id = null;
      group_id = action.val.group_id;
      let groups = {...state.groups};
      if(groups[group_id] == null){
        groups[group_id] = {sharer:null, lastMessage:null};
      }
      groups[group_id].sharer = action.val.state?action.val.user_id:null;
      //groups[group_id].sharerName = action.stats.sharerName?action.stats.sharerName:"";
      groups[group_id].lastMessage = {action:"setSharer"};
      return{...state, groups:groups}
    }
    case WEBSOCKET_SET_ACTIVE_GROUP:{
      return{
        ...state, 
        actualGroup:action.group_id, 
        isListening:action.group_id!=null?state.isListening:false, 
        isSharing:action.group_id!=null?state.isSharing:false}
    }
    case WEBSOCKET_SET_GROUP:{
      
      let groups = {...state.groups};
      if(groups[action.group_id] == null){        
        groups[action.group_id] = {sharer:null, lastMessage:null, listeners:[]};      
      }

      
      groups[action.group_id].sharer = action.stats.sharer;
      groups[action.group_id].sharerName = action.stats.sharerName?action.stats.sharerName:"";
      groups[action.group_id].listeners = action.stats.clients.filter(client=>{        
        if(client!=action.stats.sharer)
          return client
      });
      groups[action.group_id].lastMessage = action.message;

      let isSharing = false;
      let isListening = false;
      for(let i in groups){
        if(groups[i].sharer === action.my_uuid)
          isSharing = true;
        if(groups[i].listeners.includes(action.my_uuid))
          isListening = true;
      }
      //console.log(groups, Object.keys(groups))
      return{...state, groups:groups, isListening:isListening, isSharing:isSharing}  
    }
    case WEBSOCKET_SET_LISTENING_GROUP:{
      console.error("WEBSOCKET_SET_LISTENING_GROUP")
      return{...state, actualGroup:action.group_id, listening:action.group_id}      
    }
    case WEBSOCKET_NEW_JOINER: {
      return { ...state, hasNew: action.hasNew };
    }
		default: 
			return state;
	}
}

export const GetSharerInGroup = (state) => (group_id)=> state.websocket.groups[group_id]?.sharer;
export const GetSharerNameInGroup = (state) => (group_id)=> state.websocket.groups[group_id]?.sharerName;

export const GetListenersInGroup = (state) => (group_id)=> state.websocket.groups[group_id]?.listeners?state.websocket.groups[group_id]?.listeners:[];

export const IsListeningGroup = (state) => (group_id, user_id=null)=>{
 // console.log(user_id, state.websocket.groups[group_id]?.listeners, state.websocket.groups[group_id]?.listeners?.includes(user_id?user_id:state.login?.user?.uuid))
  return state.websocket.groups[group_id]?.listeners?.includes(user_id?user_id:state.login?.user?.uuid);
}
export const IsSharingGroup = (state) => (group_id, user_id=null)=>{
  return  state.websocket.groups[group_id]?.sharer === (user_id?user_id:state.login?.user?.uuid)
};
export const GetActualGroup = (state) => state.websocket.actualGroup;

export const IsListening = (state) => ()=> state.websocket.isListening;
export const IsSharing = (state) => ()=> state.websocket.isSharing;

export const GetRemote = state => state.websocket.lastMessage;

export const GetGroups = state => state.websocket.groups;
//export const GetShareGroup = state => state.websocket.actualGroup;
export const IsShareListening = state => state.websocket.role === 'listening';