import {
  DOCUMENTS_GET,
  DOCUMENTS_UPDATE,
  DOCUMENTS_CREATE,
  DOCUMENTS_GET_INWORKSPACE,
  DOCUMENTS_GET_RECORDING,
  DOCUMENTS_DELETE,
} from "./../actions/document";

import { initialState } from "initialState.js";
import { ParseDocument } from "_redux/_models/Document";

export function documentsReducer(state = initialState, action) {
  switch (action.type) {
    /*#region DOCUMENTS_GET*/
    case DOCUMENTS_GET.SUCCESS: {
      console.error("not implemented", action);
      return {
        ...state,
        pending: false,
      };
    }
    case DOCUMENTS_GET.PENDING:
      return { ...state, error: null, pending: true };
    case DOCUMENTS_GET.ERROR:
      return { ...state, pending: false, error: action.info };
    /*#endregion DOCUMENTS_GET*/
    case DOCUMENTS_UPDATE.ERROR: {
      // console.log(action)
      return state;
    }
    case DOCUMENTS_UPDATE.PENDING: {
      // console.log(action)
      return state;
    }
    case DOCUMENTS_UPDATE.SUCCESS: {
      // console.log(action.payload)
      let doc = ParseDocument(action.payload.document);

      if (doc) {
        doc.pending = false;
        for (let i in state.documents) {
          if (state.documents[i].uuid === doc.uuid) state.documents[i] = doc;
        }
      }

      return {
        ...state,
        pending: false,
        documents: [...state.documents],
      };
    }

    case DOCUMENTS_CREATE.SUCCESS: {
      let doc = ParseDocument(action.payload.document);
      if (doc) state.documents.push(doc);
      return {
        ...state,
        pending: false,
        documents: [...state.documents],
      };
    }

    case DOCUMENTS_DELETE.SUCCESS: {
      let toRemove = [];
      for (let i2 in action.payload.list) {
        for (let i in state.documents) {
          if (action.payload.list[i2] === state.documents[i].uuid)
            toRemove.push(i);
        }
      }
      while (toRemove.length > 0) {
        state.documents.splice(toRemove[0], 1);
        toRemove.splice(0, 1);
      }

      return {
        ...state,
        pending: false,
        documents: [...state.documents],
      };
    }

    /*#region DOCUMENTS_GET_INWORKSPACE*/
    case DOCUMENTS_GET_INWORKSPACE.SUCCESS: {
      let docs = [];
      for (let i in action.payload.docs) {
        let doc = ParseDocument(action.payload.docs[i]);
        if (doc) docs.push(doc);
      }

      return {
        ...state,
        pending: false,
        documents: docs,
      };
    }

    // #recording mix with workspace docs
    case DOCUMENTS_GET_RECORDING.SUCCESS: {
      let docs = [];
      for (let i in action.payload.meeting_recordings) {
        const rec = {
          uuid: action.payload.meeting_recordings[i].uuid,
          name: action.payload.meeting_recordings[i].meeting.start_time
            ? action.payload.meeting_recordings[i].meeting.name +
              action.payload.meeting_recordings[i].meeting.start_time
            : action.payload.meeting_recordings[i].meeting.name,
          description: action.payload.meeting_recordings[i].details,
          is_public: 0,
          type: "video",
          path: action.payload.meeting_recordings[i].recording_url,
          used: 0,
          workspace_id:
            action.payload.meeting_recordings[i].meeting.workspace_id,
          accessibility: "",
          show_daily_notification: 0,
          url: "",
        };

        let doc = ParseDocument(rec);
        if (doc) docs.push(doc);
      }

      return {
        ...state,
        pending: false,
        recording: docs,
      };
    }

    case DOCUMENTS_GET_INWORKSPACE.PENDING:
      return { ...state, error: null, pending: true };
    case DOCUMENTS_GET_INWORKSPACE.ERROR:
      return { ...state, pending: false, error: action.info };
    /*#endregion DOCUMENTS_GET_INWORKSPACE*/
    default:
      return state;
  }
}
export const IsError = (state) => state.documents.error;
export const IsPending = (state) => state.documents.pending;
export const GetInWorkspace = (state) => state.documents.documents;
export const GetWorkspaceRecording = (state) => state.documents.recording;
export const Get = (state) => state.documents.actualDoc;
