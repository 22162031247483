import React from "react";
import {_} from 'Constants';

import { GetApiURL } from "_redux/api/_api";
import classes from "./GroupImage.module.css";

export default function GroupImage({messages, user, img}){
  return (
    <div className={classes.root}>
      {!user ? (
        img?
        <img
          src={img}
          alt={_}
        ></img>
        :

        <i className={classes.fontIcon + " icon-group"}></i>
      ) : (
        <i className={classes.fontIcon + " icon-user"}></i>

      )}
      {user && <img
            src={GetApiURL() + "/files/user/" + user + "/thumb"}
            alt={user}
          ></img>
      }
      {messages > 0 ? (
        <div className={classes.badge}>{messages}</div>
      ) : null}
    </div>
  );
}