import React from "react";


import { CONST } from "Constants";
import i18n from "i18n";
import Icon from "ui/assets/Icon";

import classes from "./MobileInAppMessenger.module.css";

import {
  DrawerBody,
  DrawerContent,
  OverlayActionsDrawer,
  DrawerHeader,
  LeftMenu,
  DrawerSection,
} from "../styles";
import { Composition } from "atomic-layout";
import GroupRow from "./GroupRow";
import CreateGroupChat from "./CreateGroupChat";
import { Button, Paragraph } from "../../../styles";

import MobileMessengerChat from "./MessengerChat/MobileMessengerChat";
import MobileGroupChat from "./GroupChat/MobileGroupChat";
import UserRow from "./UserRow";
import UserImageAtomic from "./UserImageAtomic";
import MessengerLists from './MessengerLists';


function DesktopInAppMessengerView({active, user, state, setState, toggleDrawer, toggleCreateGroup, toggleEditGroup, actualMeeting, groupInformation, selectGroupChat, groups, selectUser, call, userList, selectedChat, soundState, allNewMessages, connectionUsers}){

  return (

      <OverlayActionsDrawer>
        <DrawerHeader>
          <Composition
            areas="item item item"
            justifyContent="space-between"
            alignItems="center"
            paddingLeft={10}
            paddingRight={10}

            onClick={toggleDrawer}
          >
            <UserImageAtomic
              user={user}
              status={CONST.userStates.available}
              newMessages={allNewMessages}
            />
            <span>{i18n.t("messenger.title")}</span>
            <Composition areas="item item item">
              <Icon
                className="notif ia"
                type={
                  soundState.soundOn
                    ? Icon.Types.notifications_on
                    : Icon.Types.notifications_off
                }
                color={Icon.Colors.white}
                onClick={(e) => {
                  e.stopPropagation();
                  soundState.SetSoundOn(!soundState.soundOn);
                }}
              />

              <Icon
                className={
                  "arrow ia " +
                  (active ? classes.rotated : CONST._empty)
                }
                type={Icon.Types.chevron_up}
                color={Icon.Colors.white}
                onClick={toggleDrawer}
              />
            </Composition>
          </Composition>
        </DrawerHeader>
        <DrawerBody active={active}>
          {state.createGroup ? (
            <CreateGroupChat
              userList={userList}
              toggleCreateGroup={toggleCreateGroup}
              groupToEdit={state.groupToEdit}
            />
          ) :
            <MessengerLists user={user} state={state} setState={setState} toggleCreateGroup={toggleCreateGroup} toggleEditGroup={toggleEditGroup} actualMeeting={actualMeeting} groupInformation={groupInformation} selectGroupChat={selectGroupChat} groups={groups} selectUser={selectUser} call={call} userList={userList} selectedChat={selectedChat} connectionUsers={connectionUsers}></MessengerLists>
          }
        </DrawerBody>
        {selectedChat?.opened &&
          <LeftMenu active={selectedChat!=null}>
            {selectedChat?.scope==="group" &&
              <MobileGroupChat
                meeting={selectedChat?.entity}
                onClose={() => selectGroupChat(selectedChat?.entity)}
                messages={state.messages}
              />
            }
            {selectedChat?.scope==="user"  &&
              <MobileMessengerChat
                user={selectedChat?.entity}
                call={call}
                messages={state.messages}
              />
            }
          </LeftMenu>
        }
      </OverlayActionsDrawer>

    );
}

export default DesktopInAppMessengerView;
