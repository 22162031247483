import {
  _get as getMessage,
  _getAvailable as getAvailableMessages,
  _create as createMessage,
  _addMessageFromWs as addMessageFromWs,
  _addMessageNew as addMessageNew,
  _getNew as getNewMessage,
  _delete as deleteMessage,
  _setAsShown as setAsShown,
  _sendFile as sendFile,
  _getGroup as getGroup,
  _sendGroupMessage as sendGroupMessage,
  _getGroupMessages as getGroupMessages,
  _createGroupMessage as createGroupMessage,
  _getUserGroups as getUserGroups,
  _groupAddUsers as groupAddUsers,
  _groupRemoveUsers as groupRemoveUsers,
  _deleteGroup as deleteGroup,
  _groupRemoveMyself as groupRemoveMyself,
  _updateGroupTitle as updateGroupTitle,
  _setMessagesCount,
} from "./../actions/message";
import { _doRequest, Endpoints, _doRequest_async, RESPONSE_TYPE } from "./_api.js";


export function _get(data) {

  return _doRequest(Endpoints.message_get, getMessage, data);
}

export function _getAvailable(data) {
  return _doRequest(Endpoints.message_getAvailable, getAvailableMessages, data);
}

export function _create_depr(data) {
  return _doRequest(Endpoints.message_create, createMessage, data);
}

export function _addMessageFromWs(data, isGroup=false) {
  return dispatch => {
		dispatch(addMessageFromWs(data, isGroup));
	};
}
export function _addMessageNew(data) {
  return dispatch => {
		dispatch(addMessageNew(data));
	};
}
export function setMessagesCount(data) {
  return dispatch => {
		dispatch(_setMessagesCount(data));
	};
}


export function _getNew(data) {
  return _doRequest(Endpoints.message_getNew, getNewMessage, data);
}

export function _delete(data) {
  return _doRequest(Endpoints.message_delete, deleteMessage, data);
}
export function _setShown(data) {
  return _doRequest(Endpoints.message_setShown, setAsShown, data);
}

export function _sendFile(data) {
  console.log(data)
  return _doRequest(Endpoints.message_sendFile, sendFile, data);
}

export  function _getGroup(data) {

  return _doRequest(Endpoints.group_get, getGroup, data);
}
export async function _getGroup_async(data, reducerDispatch = null) {

  const res = await _doRequest_async(Endpoints.group_get, null, data);
  reducerDispatch && reducerDispatch(getGroup(RESPONSE_TYPE.SUCCESS, res.info, res.data))
  return res?.data?.group;
}

export function _updateGroupTitle(data) {
  return _doRequest(Endpoints.group_update, updateGroupTitle, data);
}

export function _createGroup(data) {
  return _doRequest(Endpoints.group_create, createGroupMessage, data);
}

export function _sendGroupMessage(data) {
  return _doRequest(Endpoints.group_sendMessage, sendGroupMessage, data);
}

export function _getGroupMessages(data) {
  return _doRequest(Endpoints.group_getAll, getGroupMessages, data);
}

export function _getUserGroups(data) {
  return _doRequest(Endpoints.group_getUserGroups, getUserGroups, data);
}

export function _groupAddUsers(data) {
  return _doRequest(Endpoints.group_addUsers, groupAddUsers, data);
}

export function _groupRemoveUsers(data) {
  return _doRequest(Endpoints.group_removeUsers, groupRemoveUsers, data);
}

export function _deleteGroup(data) {
  return _doRequest(Endpoints.group_delete, deleteGroup, data);
}

export function _groupRemoveMyself(data) {
  return _doRequest(Endpoints.group_removeMyself, groupRemoveMyself, data);
}
