export const PAGE_GET = {
  PENDING: "PAGE_GET_PENDING",
  SUCCESS: "PAGE_GET_SUCCESS",
  ERROR: "PAGE_GET_ERROR",
};
export const PAGE_SET = {
  PENDING: "PAGE_SET_PENDING",
  SUCCESS: "PAGE_SET_SUCCESS",
  ERROR: "PAGE_SET_ERROR",
};
export const PAGE_CREATE = {
  PENDING: "PAGE_CREATE_PENDING",
  SUCCESS: "PAGE_CREATE_SUCCESS",
  ERROR: "PAGE_CREATE_ERROR",
};

export function _get(type, info, payload) {
  return { type: PAGE_GET[type], info: info, payload: payload };
}

export function _update(type, info, payload) {
  return { type: PAGE_SET[type], info: info, payload: payload };
}

export function _create(type, info, payload) {
  return { type: PAGE_CREATE[type], info: info, payload: payload };
}
