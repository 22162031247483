import styled, { css } from "styled-components/macro";
import { OverlayActionsDrawer } from "../styles";

export const SurveyActionDrawer = styled(OverlayActionsDrawer)`
  border-bottom: 1px solid var(--black);
  background: ${({ background }) =>
    background ? background : "var(--bg-dark)"};
`;

export const QuestionCardActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  ${({ borderTop }) =>
    borderTop
      ? css`
          border-top: 1px solid var(--black);
          padding-top: 10px;
        `
      : null}
`;

export const QuestionsContainer = styled.div`
  display: grid;
  grid-gap: 10px;
  border-top: 1px solid;
  padding-top: 10px;
`;

export const QuestionIndicatorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 12px;
`;

export const SurveyIconContainer = styled.div`
  background-color: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  position: relative;
`;

export const OpenQuestionsIndicator = styled.div`
  position: absolute;
  border-radius: 50%;
  width: var(--margin-2);
  height: var(--margin-2);
  background-color: var(--main);
  color: var(--white);
  text-align: center;
  font-size: var(--fsize_3);
  line-height: calc(2.2 * var(--margin-1));
  right: -3px;
  top: -2px;
`;

export const AnswerInput = styled.textarea`
  overflow-y: auto;
  resize: none;
  pointer-events: all;
  width: 100%;
  height: 100%;
`;

export const InputResponsesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-height: 300px;
  overflow: auto;
`;
