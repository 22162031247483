import { CONST } from "../../Constants";

export class Company{
  constructor()
  {
    this.name = CONST._empty;
    this.public_url = CONST._empty;
    this.email = CONST._empty;
    this.is_public = CONST._empty;
    this.logo = CONST._empty;
    
    this.mobile = CONST._empty;
    this.phone = CONST._empty;
    
    this.street = CONST._empty;
    this.zip = CONST._empty;
    this.city = CONST._empty;
    this.state = CONST._empty;
    this.country = CONST._empty;
    
    this.uuid = CONST._empty;
    

    this.__type = "company";
  }
}

export function ParseCompany(raw){
  let c = new Company();
  for(let i in raw){
    c[i] = raw[i];
  }
  return c;
}