export const COMPANY_CREATE={PENDING: 'COMPANY_CREATE_PENDING',SUCCESS: 'COMPANY_CREATE_SUCCESS',ERROR: 'COMPANY_CREATE_ERROR'}
export const COMPANY_GET={PENDING: 'COMPANY_GET_PENDING',SUCCESS: 'COMPANY_GET_SUCCESS',ERROR: 'COMPANY_GET_ERROR'}
export const COMPANY_UPDATE={PENDING: 'COMPANY_UPDATE_PENDING',SUCCESS: 'COMPANY_UPDATE_SUCCESS',ERROR: 'COMPANY_UPDATE_ERROR'}
export const COMPANY_GET_AVAILABLE={PENDING: 'COMPANY_GET_AVAILABLE_PENDING',SUCCESS: 'COMPANY_GET_AVAILABLE_SUCCESS',ERROR:'COMPANY_GET_AVAILABLE_ERROR'}
export const COMPANY_DELETE={PENDING: 'COMPANY_DELETE_PENDING',SUCCESS: 'COMPANY_DELETE_SUCCESS',ERROR: 'COMPANY_DELETE_ERROR'}
export const EMAIL_TEMPLATE = {
  PENDING: 'EMAIL_TEMPLATE_PENDING',
  SUCCESS: 'EMAIL_TEMPLATE_SUCCESS',
  ERROR: 'EMAIL_TEMPLATE_ERROR'
};

export const EMAIL_TEMPLATE_UPDATE = {
  PENDING: 'EMAIL_TEMPLATE_UPDATE_PENDING',
  SUCCESS: 'EMAIL_TEMPLATE_UPDATE_SUCCESS',
  ERROR: 'EMAIL_TEMPLATE_UPDATE_ERROR'
};

export function _create(type, info, payload) {  
  return {type: COMPANY_CREATE[type],info: info,payload: payload};	
}
export function _getAvailable(type, info, payload) {  
  return {type: COMPANY_GET_AVAILABLE[type],info: info,payload: payload};	
}
export function _get(type, info, payload) {  
  return {type: COMPANY_GET[type],info: info,payload: payload};	
}
export function _delete(type, info, payload) {  
  return {type: COMPANY_DELETE[type],info: info,payload: payload};	
}
export function _update(type, info, payload) {  
  return {type: COMPANY_UPDATE[type],info: info,payload: payload};	
}

export function _getEmailTemplate(type, info, payload) {
  return {type: EMAIL_TEMPLATE[type], info, payload: payload};
}

export function _updateEmailTemplate(type, info, payload) {
  return {type: EMAIL_TEMPLATE_UPDATE[type], info, payload: payload};
}