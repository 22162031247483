import {
  COMPANY_GET,
  /*COMPANY_UPDATE*/ COMPANY_GET_AVAILABLE,
  COMPANY_UPDATE,
  EMAIL_TEMPLATE,
} from "./../actions/company";
import { APP_LOGOUT_USER } from "./../actions/app";
import { initialState } from "initialState.js";
import { ParsePage } from "_redux/_models/Page";
import { PAGE_GET } from "../actions/page";
import { ParseCompany } from "../_models/Company";

export function companyReducer(state = initialState, action) {
  switch (action.type) {
    case COMPANY_UPDATE.SUCCESS: {
      console.log(action.payload);
      return {
        ...state,
        pending: false,
        actualCompany: ParseCompany(action.payload.company),
      };
    }
    case APP_LOGOUT_USER:
      return {
        ...state,
        availableCompanies: [],
      };
    /*#region COMPANY_GET*/
    case COMPANY_GET.PENDING:
      return { ...state, error: null, pending: true };
    case COMPANY_GET.SUCCESS:
      let actualCompany = ParseCompany(action.payload.company);
      actualCompany.page = ParsePage(action.payload.company.page);
      return {
        ...state,
        pending: false,
        actualCompany: actualCompany,
      };

    case PAGE_GET.SUCCESS: {
      //TODO hot fix - to update language changed edit page views
      let page = ParsePage(action.payload.page);
      if (page.uuid === state.actualCompany?.page?.uuid) {
        state.actualCompany.page = page;

        return {
          ...state,
          pending: false,
          actualCompany: { ...state.actualCompany },
        };
      }
    }
    case COMPANY_GET.ERROR:
      return { ...state, pending: false, error: action.info };
    /*#endregion COMPANY_GET*/

    /*#region COMPANY_GET_AVAILABLE*/
    case COMPANY_GET_AVAILABLE.PENDING:
      return { ...state, error: null, pending: true };
    case COMPANY_GET_AVAILABLE.SUCCESS:
      return {
        ...state,
        pending: false,
        availableCompanies: action.payload.companies,
      };
    case COMPANY_GET_AVAILABLE.ERROR:
      console.log(action);
      return { ...state, pending: false, error: action.info };
    case EMAIL_TEMPLATE.SUCCESS:
      return { ...state, email_template: action.payload };
    case EMAIL_TEMPLATE.ERROR: {
      return { ...state, email_template: null };
    }
    default:
      return state;
  }
}
export const IsError = (state) => state.company.error;
export const IsPending = (state) => state.company.pending;
export const GetAvailableCompanies = (state) =>
  state.company.availableCompanies;
export const GetActual = (state) => state.company.actualCompany;

export const GetCompanyTheme = (state) =>
  state?.company?.actualCompany?.style_customisation;
