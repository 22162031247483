import React from "react";
import * as S from "../styles";

export default function LabeledTextArea(props) {
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <S.InputLabel htmlFor={props.label}>{props.label}: </S.InputLabel>

        {!!props.rightComponent && <div>{props.rightComponent}</div>}
      </div>

      <span style={props.label ? { marginTop: 4 } : null} />
      <S.TextArea id={props.label} {...props} />
    </div>
  );
}
