import React, { useEffect, useState } from "react";
import { _getAvailable as LoadMyUsers } from "_redux/api/users";
import {
    AddUser,
    GetAllUsers,
    GetUsers as GetMyUsers,
} from "_redux/reducers/users";
import {
    GetActual as GetActualMeeting,
    GetState as GetStateMeeting,
    GetMeetingUsers,
} from "_redux/reducers/meeting";

import {
    _get as LoadMeeting,
    _getUsers as LoadMeetingUsers,
} from "_redux/api/meeting";

import {
    _get as LoadUserMessages,
    _getNew as LoadNewMessages,
    _getGroup as GetGroup,
    _getGroupMessages as LoadGroupMessages,
    _getUserGroups as GetUserGroups,
    _getGroup_async,
    _getGroup,
} from "_redux/api/message";
import {
    GetMessagesHash,
    GetLastLoadedMessageId,
    GetGroupInformation,
    GroupMessages,
    AllUserGroups,
    GetLastLoadedMessagesUser,
    GetMessagesById,
    GetGroupMessages,
    GetNewMessagesCount,
} from "_redux/reducers/message";

import { GetActualCall } from "_redux/reducers/call";
import { Play, SoundTracks, Stop as StopSound } from "SoundPlayer";
import { CONST, _ } from "Constants";
import classes from "./MobileInAppMessenger.module.css";
import { DynamicSort } from "KlarHelper";
import { FindUserByUuid } from "../../../_redux/reducers/users";
import { States } from "../../../_redux/_models/Call";

import CallState from "../../../ui/Call/CallState/CallState";
import { Events, JoinCallGroup } from "../../../_ws/GroupWebsocket";
import { AddUsers as WSGroupAddUsers } from "_ws/GroupWebsocket";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setChatOpened } from "_redux/api/_app";
import { GetSelectedChat, GetSelectedDrawerModule } from "_redux/reducers/app";
import { Modules } from "../MobileOverlayActions";
import MobileInAppMessengerView from "./MobileInAppMessengerView";
import DesktopInAppMessengerView from "./DesktopInAppMessengerView";

import checkPageStatus, { IsMobile } from "Utils";
import { addListener, removeListener } from "_ws/Websocket";
import { getGroupId } from "./GroupChat/MobileGroupChat";
import { getUsersByConnectionType } from "_redux/_models/UserDB";
import { User } from "_redux/_models/User";

function MobileInAppMessenger({ user, active, toggleOpen }) {
    //this.socket = GetSocket();

    const dispatch = useDispatch();

    const allNewMessages = useSelector((state) =>
        GetNewMessagesCount(state)("all")
    );

    const actualMeetingUsers = useSelector((state) => {
        return GetMeetingUsers(state);
    });
    const messagesHash = useSelector((state) => {
        return GetMessagesHash(state);
    });
    //stateHash
    const myUsers = useSelector((state) => {
        return GetMyUsers(state);
    });
    const lastLoadedMessageId = useSelector((state) => {
        return GetLastLoadedMessageId(state);
    });
    const call = useSelector((state) => {
        return GetActualCall(state);
    });
    const actualMeeting = useSelector((state) => {
        return GetActualMeeting(state);
    });
    const lastLoadedMessages = useSelector((state) => {
        return GetLastLoadedMessagesUser(state);
    });

    const actualMeetingReducerState = useSelector((state) => {
        return GetStateMeeting(state);
    });
    //const loggedIn = useSelector(state =>{ return IsUserLoggedIn(state)});
    const groupInformation = useSelector((state) => {
        return GetGroupInformation(state);
    });
    const lastLoadedGroupMessages = useSelector((state) => {
        return GroupMessages(state);
    });
    const allUserGroups = useSelector((state) => {
        return AllUserGroups(state);
    });
    const selectedChat = useSelector((state) => {
        return GetSelectedChat(state);
    });
    const openedModule = useSelector((state) => {
        return GetSelectedDrawerModule(state);
    });

    //console.log(allUserGroups);
    const [soundOn, SetSoundOn] = useState(
        JSON.parse(localStorage.getItem("preferences") || "{}").notifyAudio ===
            true
    );

    const [state, setState] = useState({
        selectedUser: null,
        selectedGroupChat: null,
        actualUsers: [],
        myUsers: [],
        messages: [],
        /*newMessages: [],*/
        connectedUsers: [],
        load: false,

        search: "",
        createGroup: false,
        groupToEdit: null,
    });

    useEffect(() => {
        addListener(Events.createGroup, reloadUserGroups);
        addListener(Events.deleteGroup, reloadUserGroups);
        addListener(Events.renameGroup, reloadUserGroups);
        addListener(Events.addUsersToGroup, reloadUserGroups);
        addListener(Events.removeUsersFromGroup, reloadUserGroups);

        return () => {
            removeListener(Events.createGroup, reloadUserGroups);
            removeListener(Events.deleteGroup, reloadUserGroups);
            removeListener(Events.renameGroup, reloadUserGroups);
            removeListener(Events.addUsersToGroup, reloadUserGroups);
            removeListener(Events.removeUsersFromGroup, reloadUserGroups);
        };
    }, []);

    const reloadUserGroups = (data) => {
        dispatch(GetUserGroups());
        toggleCreateGroup(true);
    };

    useEffect(() => {
        localStorage.setItem(
            "preferences",
            JSON.stringify({ notifyAudio: soundOn })
        );
    }, [soundOn]);

    const selectUser = (user) => {
        dispatch(setChatOpened({ opened: true, scope: "user", entity: user }));
    };

    const selectGroupChat = (groupChat) => {
        dispatch(
            setChatOpened({ opened: true, scope: "group", entity: groupChat })
        );
    };

    useEffect(() => {
        if (selectedChat?.opened) {
            setState({ ...state, messages: [] });

            //console.log(selectedChat, selectedChat?.entity?.uuid)
            if (selectedChat?.scope === "group" && selectedChat?.entity?.uuid) {
                dispatch(
                    LoadGroupMessages({ uuid: selectedChat?.entity?.uuid })
                );
                dispatch(GetGroup({ uuid: getGroupId(selectedChat?.entity) }));
            } else if (
                selectedChat?.scope === "user" &&
                selectedChat?.entity?.uuid
            ) {
                dispatch(
                    LoadUserMessages({ email: selectedChat?.entity?.email })
                );
            }
        }
    }, [selectedChat]);

    useEffect(() => {
        if (selectedChat?.scope === "group") {
            //meeting group
            let messages = GetMessagesById(selectedChat?.entity?.uuid);

            if (messages?.list?.length > 0)
                setState({ ...state, messages: messages.list });
        }
        if (
            selectedChat?.entity?.email &&
            lastLoadedMessages.email === selectedChat?.entity?.email
        ) {
            //u2u
            let messages = GetMessagesById(selectedChat?.entity?.email).list;
            if (messages.length > 0) {
                setState({ ...state, messages: messages });
            }
        }
    }, [lastLoadedMessages, messagesHash]);

    useEffect(() => {
        let list = [];
        if (selectedChat?.entity?.message_group_id)
            //normal group
            list = GetMessagesById(selectedChat?.entity?.message_group_id).list;
        else if (selectedChat?.entity?.uuid && selectedChat?.scope === "group")
            //meeting group
            list = GetMessagesById(selectedChat?.entity?.uuid).list;
        setState({ ...state, messages: list || [] });
    }, [lastLoadedGroupMessages]);

    useEffect(() => {
        if (state.selectedGroupChat) {
            let messages = GetGroupMessages(state.selectedGroupChat).list;
            //if (messages.length > 0 && state.lastCheckedMessages !== messages) {
            setState({ ...state, messages: messages });
            //}
        }
    }, [state.selectedGroupChat]);

    useEffect(() => {
        //call state

        if (call?.state === States.accepted) {
            if (
                /*actualMeetingReducerState !== CONST.reducerStates.pending &&*/
                actualMeeting == null ||
                actualMeeting.uuid !== call?.meeting.uuid
            ) {
                dispatch(LoadMeeting({ meeting_id: call.meeting.uuid }));
            }
        }

        if (call?.state === States.started) Play(SoundTracks.CALL_INCOMING);
        else StopSound();
    }, [call]);

    useEffect(() => {
        //load meeting users
        if (actualMeeting?.uuid) {
            dispatch(
                LoadMeetingUsers({
                    meeting_id: actualMeeting.uuid,
                })
            );
        }
    }, [actualMeeting?.uuid]);

    useEffect(() => {
        if (actualMeeting?.show_group_chat) {
            dispatch(GetGroup({ uuid: actualMeeting.message_group_id })).then(
                (res) => {
                    try {
                        //TODO we need a delay - otherwise the ws msg doesnt arrive
                        setTimeout(() => {
                            JoinCallGroup(actualMeeting?.message_group_id, []);
                        }, 2000);
                    } catch (error) {
                        console.log(error);
                    }
                }
            );
        }
    }, [actualMeeting?.uuid, actualMeeting?.show_group_chat]);

    useEffect(() => {
        //add the other user to ws the message group
        if (
            call &&
            groupInformation &&
            actualMeeting?.message_group_id === groupInformation?.uuid
        ) {
            if (groupInformation.users?.length === 1) {
                WSGroupAddUsers(groupInformation.uuid, [{ uuid: call.to }]);
            }
        }
    }, [actualMeeting?.uuid, groupInformation, call]);

    useEffect(() => {
        //initial load
        if (user?.email) {
            dispatch(LoadMyUsers());
            dispatch(GetUserGroups());
            dispatch(
                LoadNewMessages({
                    lastMessageId: lastLoadedMessageId,
                    userStatus: CONST.userStates.available,
                })
            );
        }
    }, [user?.email]);

    useEffect(() => {
        //JOIN the workspace group
        if (allUserGroups) {
            for (let id in allUserGroups) {
                if (allUserGroups[id].groups?.type === CONST.chat.workspace) {
                    JoinCallGroup(allUserGroups[id].groups.uuid, []);
                }
            }
        }
    }, [allUserGroups]);

    useEffect(() => {
        if (groupInformation?.users) {
            groupInformation?.users.forEach((user) => {
                if (user.users) AddUser(user.users);
            });
            //console.log(groupInformation?.users[].users);
        }
    }, [groupInformation]);

    const toggleCreateGroup = (floodRequest = false) => {
        if (floodRequest === true) {
            setState({
                ...state,
                createGroup: false,
                groupToEdit: null,
            });
        } else {
            setState({
                ...state,
                createGroup: !state.createGroup,
                groupToEdit: null,
            });
        }
    };

    const toggleEditGroup = (group) => {
        setState({
            ...state,
            createGroup: !state.createGroup,
            groupToEdit: group ? group : null,
        });
    };

    const toggleDrawer = () => {
        setState({
            ...state,
            selectedUser: null,
            selectedGroupChat: null,
            messages: [],
        });

        if (openedModule === Modules.messenger) {
            if (selectedChat?.opened)
                dispatch(
                    setChatOpened({
                        opened: false,
                        scope: "user",
                        entity: null,
                    })
                );
        }

        toggleOpen(Modules.messenger);
    };

    if (!user?.email || !user.email_confirmed) return null;

    let groups = [];
    if (allUserGroups) {
        groups = Object.keys(allUserGroups).map((key) => allUserGroups[key]);
    }

    let userList = [];
    userList = myUsers.filter(
        (u) => u.connectionType === User.ConnectionTypes.CONNECTED
    );

    let connectionUsers = {
        [User.ConnectionTypes.REQUESTED_ME]: getUsersByConnectionType(
            User.ConnectionTypes.REQUESTED_ME
        ),
        [User.ConnectionTypes.REQUESTED_I]: getUsersByConnectionType(
            User.ConnectionTypes.REQUESTED_I
        ),
    };

    userList.sort(DynamicSort("status"));

    let floatingCallState = {
        show: false,
        fromUser: null,
    };
    if (!state.opened && call?.state === States.started) {
        if (call.from != user.uuid) {
            floatingCallState.fromUser = FindUserByUuid(call.from);
            checkPageStatus(
                "New Call",
                floatingCallState.fromUser.name,
                "",
                true
            );
        } else floatingCallState.fromUser = FindUserByUuid(call.to); //user;
        if (floatingCallState.fromUser) floatingCallState.show = true;
    }

    return (
        <>
            {floatingCallState.show && (
                <>
                    <div className={classes.floatingCallStateContainer}>
                        <div className={classes.floatingCallState}>
                            <span>{floatingCallState.fromUser.GetName()}</span>
                            <CallState
                                call={call}
                                user={floatingCallState.fromUser}
                            ></CallState>
                        </div>
                    </div>
                </>
            )}

            {IsMobile() ? (
                <MobileInAppMessengerView
                    active={active}
                    /*allNewMessages={allNewMessages}*/
                    user={user}
                    state={state}
                    setState={setState}
                    toggleDrawer={toggleDrawer}
                    toggleCreateGroup={toggleCreateGroup}
                    toggleEditGroup={toggleEditGroup}
                    actualMeeting={actualMeeting}
                    groupInformation={groupInformation}
                    selectGroupChat={selectGroupChat}
                    groups={groups}
                    selectUser={selectUser}
                    call={call}
                    soundState={{ soundOn, SetSoundOn }}
                    userList={userList}
                    selectedChat={selectedChat}
                    connectionUsers={connectionUsers}
                ></MobileInAppMessengerView>
            ) : (
                <DesktopInAppMessengerView
                    active={active}
                    /*allNewMessages={allNewMessages}*/
                    user={user}
                    state={state}
                    setState={setState}
                    toggleDrawer={toggleDrawer}
                    toggleCreateGroup={toggleCreateGroup}
                    toggleEditGroup={toggleEditGroup}
                    actualMeeting={actualMeeting}
                    groupInformation={groupInformation}
                    selectGroupChat={selectGroupChat}
                    groups={groups}
                    selectUser={selectUser}
                    call={call}
                    userList={userList}
                    soundState={{ soundOn, SetSoundOn }}
                    allNewMessages={allNewMessages}
                    selectedChat={selectedChat}
                    connectionUsers={connectionUsers}
                ></DesktopInAppMessengerView>
            )}
        </>
    );
}

export default MobileInAppMessenger;
