import {
  _get as getUser,
  _getAvailable as getAvailableUsers,
  _add as addUser,
  _deny as denyUser,
  searchFast,
  searchDetailed,
  createInvitation,
  getStatus,
  addSupportUser,
  SetUserState as setUserState,
  updateOrAddUser
} from "./../actions/users";
import { _doRequest, Endpoints, _doRequest_async } from "./_api.js";

export function _get(data) {
  return _doRequest(Endpoints.users_get, getUser, data);
}

export function _getAvailable(data) {
  return _doRequest(Endpoints.users_getAvailable, getAvailableUsers, data);
}

export function _add(data) {
  return _doRequest(Endpoints.users_add, addUser, data);
}

export function _getStatus(data) {
	return _doRequest(Endpoints.users_getStatus, getStatus, data);
}


export function _deny(data) {
	return _doRequest(Endpoints.users_deny, denyUser, data);
}


export function _searchFast(data) {
  return _doRequest(Endpoints.users_searchFast, searchFast, data);
}

export function _createInvitation(data) {
  return _doRequest(Endpoints.users_createInvitation, createInvitation, data);
}

export function _searchDetailed(data) {
  return _doRequest(Endpoints.users_searchDetailed, searchDetailed, data);
}

export async function _searchDetailedAsync(data) {
    const res = await _doRequest_async(
        Endpoints.users_searchDetailed,
        null,
        data
    );
    console.log(res);
    return res;
}

export async function _getUserDetailed(data, dispatch=null) {
  const res = await _doRequest_async(Endpoints.users_getDetailed, dispatch, data);
  return res;
}

export const _addSupportUser = (data) => {
  return _doRequest(Endpoints.add_support_user, addSupportUser, data);
};

export const SetUserState = (uuid, status) => {
  return (dispatch) => {
    dispatch(setUserState(uuid, status));
  };
};


export function UpdateOrAddUser(user) {
  return (dispatch) => {
    dispatch(updateOrAddUser(user));
  };
}
