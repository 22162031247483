//import {store} from 'index';

import { store } from "../..";
import {
    loadLocalDataFunc,
    logOutUser,
    basket_delete,
    basket_add,
    set_meeting,
    getPublicData,
    appMediaHide as media_hide,
    appMediaShow as media_show,
    appInstructionsHide as instructions_hide,
    appInstructionsShow as instructions_show,
    setAdminMode,
    showEntityPicker as _showEntityPicker,
    hideEntityPicker as _hideEntityPicker,
    _loadAnalytics,
    _pushAnalytics,
    _setSocialUserboardOpened,
    _getLandingPageTemplate as getLandingPageTemplate,
    _updateBlockTemplate as updateBlockTemplate,
    _createBlockTemplate as createBlockTemplate,
    _getAnalyticsAll as getAnalyticsAll,
    _setMessengerOpened,
    _setDrawerModule,
    _setChatOpened,
    _setSurveyAvailableQuestions,
    _setSurveyOpened,
    _openCalendarEntryModal,
} from "./../actions/app";
import { _get as getStage } from "./../actions/stage";

import { _doRequest, Endpoints } from "./_api.js";

export function _setAdminMode(val) {
    return (dispatch) => {
        dispatch(setAdminMode(val));
    };
}

export function _getPublicData(data) {
    return _doRequest(Endpoints.public_data, getPublicData, data);
}

export function _doAppRequest(actionFunc, data) {
    return (dispatch) => {
        dispatch(actionFunc(data));
    };
}

export function loadLocalData(data) {
    return (dispatch) => {
        dispatch(loadLocalDataFunc(data));
    };
}

export function logout() {
    return (dispatch) => {
        dispatch(logOutUser());
    };
}

export function addToBasket(val) {
    return (dispatch) => {
        dispatch(basket_add(val));
    };
}

export function removeFromBasket(val) {
    return (dispatch) => {
        dispatch(basket_delete(val));
    };
}

export function showMedia(path, options = null, settings = null) {
    return (dispatch) => {
        dispatch(media_show(path, options, settings));
    };
}

export function hideMedia() {
    return (dispatch) => {
        dispatch(media_hide());
    };
}

export function showInstructions(val) {
    return (dispatch) => {
        dispatch(instructions_show(val));
    };
}

export function hideInstructions(val) {
    return (dispatch) => {
        dispatch(instructions_hide(val));
    };
}

export function showEntityPicker(val) {
    return (dispatch) => {
        dispatch(_showEntityPicker(val));
    };
}
export function hideEntityPicker(val) {
    return (dispatch) => {
        dispatch(_hideEntityPicker(val));
    };
}

export function showStage(val) {
    let data = { stage: { uuid: val.uuid, workspace_id: val.workspace_id } };

    return _doRequest(Endpoints.stage_get, getStage, data);
    //return dispatch => {dispatch(show_stage(val));};
}

export function setActualMeeting(val) {
    return (dispatch) => {
        dispatch(set_meeting(val));
    };
}

export function loadAnalytics({ user_id, workspace_id, company_id }) {
    return _doRequest(Endpoints.analytics_load, _loadAnalytics, {
        user_id: user_id,
        workspace_id: workspace_id,
        company_id,
    });
}

/*
 * entity_id:  should be an db entity id
 * entity_type:  should be a db table name
 * completeLoaded: the push will only sent, if actual workspace and actual company are set.
 */
let _lastPushAnalyticsCall = { ts: new Date().getTime(), obj: null };
export function pushAnalytics({
    entity_id,
    entity_type,
    data,
    completeLoaded,
}) {
    const state = store.getState();

    if (
        completeLoaded &&
        (state.workspace?.actualWorkspace?.uuid == null ||
            state.company?.actualCompany?.uuid == null)
    )
        return () => {};

    let callObj = {
        ts: new Date().getTime(),
        obj: {
            user_id: state.login?.user?.uuid,
            workspace_id: state.workspace?.actualWorkspace?.uuid,
            company_id: state.company?.actualCompany?.uuid,
            entity_id: entity_id,
            entity_type: entity_type,
            data: data,
        },
    };
    if (
        JSON.stringify(callObj) === JSON.stringify(_lastPushAnalyticsCall) &&
        callObj.ts - _lastPushAnalyticsCall.ts < 1000
    )
        return () => {};
    _lastPushAnalyticsCall = callObj;

    return _doRequest(Endpoints.analytics_push, _pushAnalytics, callObj.obj);
}

export function setSocialUserboardOpened(bool) {
    return (dispatch) => {
        dispatch(_setSocialUserboardOpened(bool));
    };
}

export function _getLandingPageTemplate(data, languageSelected) {
    return _doRequest(
        Endpoints.get_landing_page,
        getLandingPageTemplate,
        data,
        null,
        languageSelected
    );
}

export function _updateBlockTemplate(data) {
    return _doRequest(Endpoints.update_landing_page, updateBlockTemplate, data);
}

export function _createBlockTemplate(data) {
    return _doRequest(Endpoints.create_landing_page, createBlockTemplate, data);
}

export function _getAnalyticsAll({
    entity_id,
    workspace_id,
    company_id,
    entity_type,
}) {
    return _doRequest(Endpoints.analytics_get_all, getAnalyticsAll, {
        entity_type,
        entity_id,
        workspace_id,
        company_id,
    });
}

export function setDrawerModule(val) {
    return (dispatch) => {
        dispatch(_setDrawerModule(val));
    };
}

export function setChatOpened(val) {
    return (dispatch) => {
        dispatch(_setChatOpened(val));
    };
}

export function setSurveyAvailableQuestions(questions) {
    return (dispatch) => {
        dispatch(_setSurveyAvailableQuestions(questions));
    };
}

export function openCalendarEntryModal(entry) {
    return (dispatch) => {
        dispatch(_openCalendarEntryModal(entry));
    };
}
