import {callState, CALL_ACCEPTED, CALL_HANGEDUP, CALL_REJECTED, CALL_STARTED, CLOSE_BROADCAST} from './../actions/call';

export function start(data) {
	return dispatch => {	 	
		dispatch(callState(CALL_STARTED, data));
	};
}

export function accept(data) {
	return dispatch => {	 	
		dispatch(callState(CALL_ACCEPTED, data));
	};
}

export function reject(data) {
	return dispatch => {	 	
		dispatch(callState(CALL_REJECTED, data));
	};
}

export function hangup(data) {
	return dispatch => {	 	
		dispatch(callState(CALL_HANGEDUP, data));
	};
}

export function closeBroadCast() {
	return dispatch => {	 	
		dispatch(callState(CLOSE_BROADCAST, {}));
	};
}