import React, { Component, useEffect, useRef, useState } from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { GetGroupInformation } from "_redux/reducers/message";
import { GetActualCall } from "_redux/reducers/call";

import { CONST, _ } from "Constants";
import i18n from "i18n";
import UserRow from "./../UserRow";
import { DynamicSort } from "KlarHelper";
import Checkbox from "../../../../components/Checkbox";
import { EditGroupChatButton, UserContainer, UserRowStyles } from "./styles";
import { Paragraph, Space, TextInput } from "../../../../styles";
import { Composition } from "atomic-layout";
import {
  _createGroup as CreateGroup,
  _getUserGroups as GetUserGroups,
  _getGroup as GetGroup,
  _groupAddUsers as GroupAddUsers,
  _groupRemoveUsers as GroupRemoveUsers,
  _deleteGroup as DeleteGroup,
  _updateGroupTitle as UpdateGroupTitle,
} from "../../../../_redux/api/message";

import { getUser as GetMyUser } from "../../../../_redux/reducers/login";
import LabeledSpinner from "../../../../components/LabeledSpinner";
import { User } from "_redux/_models/User";
import { addListener, removeListener } from "../../../../_ws/Websocket";
import {
  Events,
  CreateGroup as WSCreateGroup,
  DeleteGroup as WSDeleteGroup,
  AddUsers as WSGroupAddUsers,
  RemoveUsers as WSGroupRemoveUsers,
  RenameGroup as WSRenameGroup,
} from "../../../../_ws/GroupWebsocket";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import classes from './CreateGroupChat.module.css';

function CreateGroupChat(props){

  const ref = useRef();

  const call = useSelector(state=>GetActualCall(state));
  const me = useSelector(state=>GetMyUser(state));
  const groupInformation = useSelector(state=>GetGroupInformation(state));
  const dispatch = useDispatch();

  const [state, setState] = useState({
    actualList: 0,
    search: "",
    selectedUsers: new Map(),
    startingUsers: new Map(),
    groupTitle: "",
    step: 0,
    loading: false,
    showDelete: false,
  })


  useEffect(()=>{
    addListener(Events.createGroup, groupCreated);
    return()=>{
      removeListener(Events.createGroup, groupCreated);
    }
  },[])

  useEffect(()=>{
    if(props.groupToEdit)
      dispatch(GetGroup({ uuid: props.groupToEdit.message_group_id }));
  },[props.groupToEdit])

  useEffect(()=>{
    if (groupInformation && props.groupToEdit)
    {
      let users = new Map();
      for (let i in groupInformation.users) {
        users.set(groupInformation.users[i].user_id, true);
      }

      setState({
        ...state,
        selectedUsers: users,
        startingUsers: users,
        groupTitle: groupInformation.name,
      });
    }
    if(!props.groupToEdit && groupInformation && state.groupTitle){
      setState({
        actualList: 0,
        search: "",
        selectedUsers: new Map(),
        startingUsers: new Map(),
        groupTitle: "",
        step: 0,
        loading: false,
        showDelete: false,
      });
    }
  },[groupInformation, props.groupToEdit, groupInformation])

  const groupCreated = (data) => {
    console.log(data)
    setState({ ...state, loading: false });
  }

  const selectUser = (uuid) => {
    let users = new Map(state.selectedUsers);
    if (users.get(uuid)) users.delete(uuid);
    else users.set(uuid, true);
    setState({ ...state, selectedUsers: users });
  }

  const search = (entry) => {
    return (
      entry.firstname.toLowerCase().includes(state.search.toLowerCase()) ||
      entry.lastname.toLowerCase().includes(state.search.toLowerCase())
    );
  };

  const handleGroupTitle = (val) => {
    setState({
      ...state,
      groupTitle: val,
    });
  }

  const handleNextStep = (nextStep) => {
    setState({
      ...state,
      step: nextStep,
    });
  }

  const createGroup = () => {
    let groupUsers = [];
    groupUsers.push({ uuid: me.uuid });
    for (let [userUuid, _] of state.selectedUsers.entries()) {
      groupUsers.push({ uuid: userUuid });
    }
    let reqObj = {
      name: state.groupTitle,
      users: groupUsers,
    };


    WSCreateGroup(reqObj);
    /*
    props.CreateGroup(reqObj).then((res) => {
      setState({ ...state, loading: false });
      props.toggleCreateGroup(true);
      props.GetUserGroups();
    });*/
  }

  const toggleDeleteGroup = () => {
    setState({
      ...state,
      showDelete: !state.showDelete,
    });
  }

  const save = () => {

    if (!props.groupToEdit) {
      setState({ ...state, loading: true });
      createGroup();
    } else if (props.groupToEdit && groupInformation) {

      setState({ ...state, loading: true });

      if (state.groupTitle !== groupInformation.name) {
        WSRenameGroup(groupInformation.uuid, state.groupTitle);
      }

      if (state.startingUsers !== state.selectedUsers) {
        // Check which users to delete from the group
        let usersToDelete = [];
        for (let [key, val] of state.startingUsers) {
          if (!state.selectedUsers.get(key)) {
            usersToDelete.push({ uuid: key });
          }
        }

        // Check which users to add to the group
        let usersToAdd = [];
        for (let [key, val] of state.selectedUsers) {
          if (!state.startingUsers.get(key)) {
            usersToAdd.push({ uuid: key });
          }
        }

        if (usersToDelete.length > 0) {
          WSGroupRemoveUsers(groupInformation.uuid, usersToDelete);
        }
        if (usersToAdd.length > 0) {
          // this.addUsers(usersToAdd);
          WSGroupAddUsers(groupInformation.uuid, usersToAdd);
        }
      }

      // If nothing has changed, just close the modal
      if (
        state.groupTitle === groupInformation.name &&
        state.startingUsers === state.selectedUsers
      ) {
        props.toggleCreateGroup();
      }
    }
  }

  let userList = props.userList;
  userList.sort(DynamicSort("status"));

  const buttonLayout = `
    back advance
    delete delete
  `;

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        {props.groupToEdit
          ? i18n.t("messenger.group_chat.edit.title")
          : i18n.t("messenger.group_chat.create.title")}
      </div>


      <div className={classes.steps}>
        <div className={classes.step + _ + (state.step === 0?classes.active:_)}>
          1. {i18n.t("messenger.group_chat.edit.group_title.label")}</div>
        <div className={classes.step + _ + (state.step === 1?classes.active:_)}>
          2. {i18n.t("messenger.group_chat.edit.participants.label")}
        </div>
      </div>
      {state.loading ? (
        <LabeledSpinner />
      ) : state.showDelete ? (
        <div className="list" style={{ padding: 15 }}>
          <Paragraph color="var(--bg-dark)" align="center" bold>
            {i18n.t("messenger.group_chat.delete.title")}
          </Paragraph>
          <Composition
            areas={buttonLayout}
            justifyContent="space-between"
            paddingTop="15px"
          >
            {(Areas) => (
              <>
                <Areas.Back>
                  <EditGroupChatButton
                    color="var(--bg-dark)"
                    onClick={toggleDeleteGroup}
                  >
                    {i18n.t("button.abort")}
                  </EditGroupChatButton>
                </Areas.Back>
                <Areas.Advance>
                  <EditGroupChatButton
                    color="var(--bg-dark)"
                    onClick={() =>
                      WSDeleteGroup(groupInformation.uuid)
                    }
                  >
                    {i18n.t("button.delete")}
                  </EditGroupChatButton>
                </Areas.Advance>
              </>
            )}
          </Composition>
        </div>
      ) : state.step === 0 ? (

        <div className="list" style={{ padding: 15 }}>
          <Paragraph color="var(--bg-dark)" align="center" bold>
            {i18n.t("messenger.group_chat.edit.group_title.label")}
          </Paragraph>
          <TextInput
            placeholder="Group Title"
            color="var(--bg-dark)"
            bgColor="var(--gray-light)"
            width="100%"
            value={state.groupTitle}
            onChange={(event) => handleGroupTitle(event.target.value)}
          />
          <Composition
            areas={buttonLayout}
            justifyContent="space-between"
            paddingTop="15px"
          >
            {(Areas) => (
              <>
                <Areas.Back>
                  <EditGroupChatButton
                    color="var(--bg-dark)"
                    onClick={props.toggleCreateGroup}
                  >
                    {i18n.t("button.abort")}
                  </EditGroupChatButton>
                </Areas.Back>
                <Areas.Advance>
                  <EditGroupChatButton
                    color="var(--bg-dark)"
                    onClick={(e) => handleNextStep(1)}
                  >
                    {i18n.t("button.next")}
                  </EditGroupChatButton>
                </Areas.Advance>
                <Areas.Delete>
                  {props.groupToEdit && <EditGroupChatButton
                    color="var(--bg-dark)"
                    onClick={toggleDeleteGroup}
                    style={{ width: "100%", marginTop: "15px" }}
                  >
                    {i18n.t("button.delete_group")}
                  </EditGroupChatButton>
                  }
                </Areas.Delete>
              </>
            )}
          </Composition>
        </div>
      ) : (
        <>
          <div className="list" style={{ padding: 15, height: "100%" }}>
            <Paragraph color="var(--bg-dark)" align="center" bold>
              {i18n.t("messenger.group_chat.edit.participants.label")}
            </Paragraph>
            <TextInput
              color="var(--bg-dark)"
              bgColor="var(--gray-light)"
              width="100%"
              ref={ref}
              type="text"
              value={state.search}
              onChange={(e) => setState({...state, search: e.target.value })}
              placeholder={i18n.t("search.placeholder")}
            />
            <UserContainer>
              {userList
                .filter((entry) => search(entry))
                .map((entry, i) =>
                  entry.connectionType !== User.ConnectionTypes.REQUESTED_I &&
                  entry.connectionType !==
                    User.ConnectionTypes.REQUESTED_ME ? (
                    <UserRowStyles key={entry.uuid}>
                      <Checkbox
                        width="25px"
                        height="25px"
                        value={entry.uuid}
                        checked={
                          state.selectedUsers.get(entry.uuid) === true
                        }
                        onChange={() => selectUser(entry.uuid)}
                      />
                      <Space />
                      <UserRow
                        selected={
                          state.selectedUsers.get(entry.uuid) === true
                        }
                        user={entry}
                        call={call}
                        key={i}
                        _key={i}
                        className={
                          i === state.actualId
                            ? CONST.classes.active
                            : CONST.classes._empty
                        }
                        onClick={() => selectUser(entry)}
                        showActions={false}
                        forceShow={true}
                      />
                    </UserRowStyles>
                  ) : null
                )}
            </UserContainer>
            <Composition
              areas={buttonLayout}
              justifyContent="space-between"
              paddingTop="15px"
            >
              {(Areas) => (
                <>
                  <Areas.Back>
                    <EditGroupChatButton
                      color="var(--bg-dark)"
                      onClick={(e) => handleNextStep(0)}
                    >
                      {i18n.t("button.back")}
                    </EditGroupChatButton>
                  </Areas.Back>
                  <Areas.Advance>
                    <EditGroupChatButton
                      color="var(--bg-dark)"
                      onClick={save}
                    >
                      {props.groupToEdit
                        ? i18n.t("button.save")
                        : i18n.t("button.create")}
                    </EditGroupChatButton>
                  </Areas.Advance>
                </>
              )}
            </Composition>
          </div>
        </>
      )}
    </div>
  );
}

export default CreateGroupChat;
