import { _ } from "Constants";
import "./App.css";
import classes from "./App.module.css";
import classes1 from "./ComponentTest.module.css";
import { Button } from "styles";

export function ComponentTest() {
    return (
        <div className={classes.App + _ + classes1.root} id="app">
            <h1>Button</h1>
            <small>{'<Button type="main">hello</Button>'}</small>
            <div className={classes1.btns}>
                <div>
                    <Button type="main">hello</Button>
                    <Button type="main" small={true}>
                        hello
                    </Button>
                </div>
                <div>
                    <Button type="main">
                        hello <i className="icon-cancel"></i>
                    </Button>
                    <Button type="main" small={true}>
                        hello <i className="icon-cancel"></i>
                    </Button>
                </div>

                <div>
                    <Button type="main">
                        <i className="icon-cancel"></i>hello
                    </Button>
                    <Button type="main" small={true}>
                        <i className="icon-cancel"></i>gello
                    </Button>
                </div>
                <div>
                    <Button type="main">
                        <i className="icon-cancel"></i>
                    </Button>
                    <Button type="main" small={true}>
                        <i className="icon-cancel"></i>
                    </Button>
                </div>
                <div>
                    <Button small={true}>
                        <i className="icon-ok"></i>button
                    </Button>
                    <Button type="main" small={true}>
                        <i className="icon-ok"></i>main
                    </Button>
                    <Button type="danger" small={true}>
                        <i className="icon-ok"></i>danger
                    </Button>
                    <Button type="info" small={true}>
                        <i className="icon-ok"></i>info
                    </Button>
                    <Button type="success" small={true}>
                        <i className="icon-ok"></i>success
                    </Button>
                </div>
            </div>

            <h1>Icon</h1>
            <small>{'<i className="icon-klarland"></i>'}</small>

            <div className={classes1.btns}>
                <div>
                    icon <i className="icon-klarland"></i>
                </div>
                <div>
                    btn <i className="icon-klarland btn"></i>
                </div>
            </div>
        </div>
    );
}
