import React from "react";
import styled from "styled-components/macro";

export default function ProgressBar(props) {
  const { bgcolor, completed, customStyle = {}, isCustom = false } = props;

  const containerStyles = {
    height: 20,
    width: "100%",
    backgroundColor: "#e0e0de",
    borderRadius: 50,
  };

  const fillerStyles = {
    height: "100%",
    width: `${completed}%`,
    backgroundColor: bgcolor,
    transition: "width 1s ease-in-out",
    borderRadius: "inherit",
    textAlign: "right",
  };

  const fillerCustomStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    overflow: "hidden",
  };

  const labelStyles = {
    padding: isCustom ? 0 : 5,
    color: "white",
    fontWeight: "bold",
    position: "relative",
    top: isCustom ? "0px" : "4px",
    fontSize: isCustom ? "12px" : "16px",
  };

  return (
    <div style={{ ...containerStyles, ...customStyle }}>
      <div
        style={
          isCustom ? { ...fillerStyles, ...fillerCustomStyles } : fillerStyles
        }
      >
        <span style={labelStyles} role="progressbar">{`${completed}%`}</span>
      </div>
    </div>
  );
}

const ProgressBackground = styled.div`
    background: var(--bg-dark);
    justify-content: flex-start;
    border-radius: 8px;
    align-items: center;
    position: relative;
    padding: 0px;
    display: flex;
    overflow: hidden;
    height: 35px;
    width: ${(props) => (props.width ? props.width : "100%")};
`;

const ProgressForeground = styled.div`
    background: var(--main);
    height: 100%;
    width: ${(props) => (props.percent ? props.percent + "%" : "0%")};
`;

const ProgressVotes = styled.div`
  width: 100%;
  color: white;
  text-align: right;
  position: absolute;
  padding-right: 20px;
`;

const ProgressPercentile = styled.div`
  width: 100%;
  color: white;
  text-align: left;
  position: absolute;
  padding-left: 10px;
`;

export function CardProgressBar(props) {
  const percent =
    Math.round(((100 / props.total) * props.votes + Number.EPSILON) * 100) /
    100;

  return (
    <ProgressBackground {...props}>
      <ProgressForeground percent={percent} />
      <ProgressVotes>{props.votes} Votes</ProgressVotes>
      <ProgressPercentile>
        {(isNaN(percent) || !isFinite(percent) ? 0 : percent) + " %"}{" "}
        {props.value}
      </ProgressPercentile>
    </ProgressBackground>
  );
}
