import {ANT_MESSAGE_RECEIVED} from './../actions/ant';
import {initialState}  from 'initialState.js';

let users = {};
export function antReducer(state = initialState, action) {
  switch(action.type) {
		case ANT_MESSAGE_RECEIVED: 
		
		if(action.data == null || action.data.streamId == null || action.data.event == null || action.data.event.data == null)
			return state;
		
		if(users[action.data.streamId] === action.data.event.data)
			return state;

		users[action.data.streamId] = action.data.event.data;
		console.log(users);
		//users = this.s
		return {
			...state,				
			users: {...users}
		}    
		default: 
			return state;
	}
}

export const GetUsers = state => state.ant.users;