import React, { useEffect, useRef } from "react";
import { CONST, _, _ia } from "Constants";
import CallState from "ui/Call/CallState";
import { ConnectionTypes, User, _GetName } from "_redux/_models/User";
import classes from "./UserRow.module.css";
import RemoteSharingStateView from "./RemoteSharingStateView/RemoteSharingStateView";
import { CreateGroupIdFromUserIds } from "../../../../Utils";
import UserImageAtomic from "../UserImageAtomic";
import UserInfoPopup from "./UserInfoPopup";
import { SendConnexionAnswer } from "_ws/ConnexionWebsocket";
import { FindUserByEmail } from "_redux/reducers/users";
import ContextMenu from "ui/assets/ContextMenu/ContextMenu";

export default function UserRow({
    user,
    selected,
    _key,
    onClick,
    call,
    me,
    showNewMessages = true,
    forceShow = false,
    showActions = true,
}) {
    const [detailsOpened, setDetailsOpened] = React.useState(false);
    const ref = useRef();
    useEffect(() => {
        if (detailsOpened)
            window.addEventListener("mousedown", clickedSomeWhere);

        return () => window.removeEventListener("mousedown", clickedSomeWhere);
    }, [detailsOpened]);

    const clickedSomeWhere = (e) => {
        if (!ref.current.contains(e.target) && e.target !== ref.current) {
            setDetailsOpened(false);
        } else {
        }
    };

    if (!forceShow && user.connectionType === User.ConnectionTypes.REQUESTED_I)
        return null;

    const isMyCall =
        call?.from === user.uuid || call?.to === user.uuid ? true : false;

    let foundUser = FindUserByEmail(user?.email);
    if (!foundUser) foundUser = user;

    return (
        <div
            className={
                classes.root +
                _ia +
                (selected ? _ + classes.active : CONST._empty)
            }
            ref={ref}
            key={_key}
            onClick={onClick}
        >
            <div className={classes.light}>
                <UserImageAtomic
                    user={user}
                    className={classes.UserImage}
                    showBadge={showNewMessages}
                ></UserImageAtomic>
                <span className={classes.UserName}>{_GetName(user)}</span>
                {showActions && (
                    <ContextMenu
                        icon={
                            user?.connectionType ? (
                                <i className="icon-more"></i>
                            ) : (
                                <i className="icon-social"></i>
                            )
                        }
                    >
                        <UserInfoPopup
                            className={classes.userInfoPopup}
                            user={foundUser}
                            me={me}
                        ></UserInfoPopup>
                    </ContextMenu>
                )}
            </div>
            <div className={classes.actions}>
                {user.connectionType === User.ConnectionTypes.CONNECTED && (
                    <>
                        {isMyCall && (
                            <CallState call={call} user={user}></CallState>
                        )}
                        {false && (
                            <RemoteSharingStateView
                                groupId={CreateGroupIdFromUserIds(
                                    user.uuid,
                                    me?.uuid
                                )}
                            ></RemoteSharingStateView>
                        )}
                    </>
                )}
                {showActions && (
                    <>
                        {user.connectionType ===
                            User.ConnectionTypes.REQUESTED_ME && (
                            <>
                                <i
                                    className={
                                        classes.connectionDeny + " icon-cancel"
                                    }
                                    onClick={() =>
                                        SendConnexionAnswer(user.email, false)
                                    }
                                ></i>
                                <i
                                    className={
                                        classes.connectionAccept + " icon-ok"
                                    }
                                    onClick={() =>
                                        SendConnexionAnswer(user.email, true)
                                    }
                                ></i>
                            </>
                        )}

                        {user.connectionType == null &&
                            (detailsOpened ? (
                                <UserInfoPopup
                                    className={classes.userInfoPopup}
                                    user={user}
                                    me={me}
                                ></UserInfoPopup>
                            ) : null)}
                    </>
                )}
            </div>
        </div>
    );
}
