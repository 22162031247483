export const APP_LANGUAGE_CHANGE = "APP_LANGUAGE_CHANGE";
export const APP_LANGUAGE_GET = "APP_LANGUAGE_GET";
export const APP_LANGUAGE_SET_HEADER = "APP_LANGUAGE_SET_HEADER";

export function _get(payload) {
  return { type: APP_LANGUAGE_GET, payload: payload };
}

export function _update(payload) {
  return { type: APP_LANGUAGE_CHANGE, payload: payload };
}
