import { CONST } from "Constants";
import { User } from "./User";



let userDb = null;
export class UserDB
{
	constructor() {
		this.users = new Map();
	}

  AddMe(me){
    if(!me.GetName)
      me = User.Parse(me);
    this.AddUser(me);
    this.UpdateStatus(me.email, CONST.userStates.available)
  }

  AddUser(user)
  {

    if(!user.email)
      return;

    if(!user.GetName)
      user = User.Parse(user);

    if(this.users.get(user.email))
    {
      if(this.users.get(user.email)?.Merge){

        this.users.get(user.email)?.Merge(user);
      }
    }
    else
    {
      this.users.set(user.email, user);
    }

    return this.users.get(user.email);
  }

	SetUsers(users)
	{
		users.map((user)=>this.users.set(user.email, user));

	}

  UpdateStatus(email, status)
  {
    const entry = this.users.get(email);
    if(entry){
      if(entry.status != status){
        entry.status = status;
        notifyAboutUserStatusUpdate(status, email);
        setTimeout(()=>{//dirty hack to avoid "call from reducer"
          //const d = {all:allNew}
         // d[id] = _new;
          //store.dispatch(setMessagesCount(d));
        },1);

      }
    }
  }

	FindUserByEmail(email)
	{
		return this.users.get(email);
	}

	FindUserByUuid(uuid)
	{
    let foundUser = null;
    this.users.forEach((user)=>{if(user.uuid == uuid)foundUser = user;})
		return foundUser;
	}

  GetUsersOnline(){
    let list = [];
    this.users.forEach((user)=>{if(user.status === CONST.userStates.available)list.push(user);})
    return list;
  }


  GetAllUsers(){
    let list = [];
    this.users.forEach((user)=>{list.push(user);})
    return list;
  }

}


const userStatusListeners = {};
function notifyAboutUserStatusUpdate(newStatus, id){
  if(userStatusListeners[id]){
    userStatusListeners[id].map(cb=>cb(newStatus))
  }
}

export function addUserStatusListener(id, callback){
  if(!userStatusListeners[id])
  userStatusListeners[id] = [];

  if(userStatusListeners[id].indexOf(callback)>-1)
    console.log("listener already in list");
  userStatusListeners[id].push(callback);
}

export function removeUserStatusListener(id, callback){
  if(!userStatusListeners[id])
    return;

    userStatusListeners[id] = userStatusListeners[id].filter(cb=>cb!=callback);
}

export function getUsersByConnectionType(connectionType){

  const users = [];
  userDb.users.forEach((user,email)=>{
    //console.log(user)
    if(user?.connectionType === connectionType)
      users.push(user);
  }
  )
  //console.log(userDb, Array.from(userDb.users));

  return users;
}

export function GetUserDb(){
  if(!userDb)
    userDb = new UserDB();
  return userDb;
}
