import { CONST } from "../../Constants";
import { DynamicSort } from "KlarHelper";
import { store } from "index";
import { setMessagesCount } from "_redux/api/message";


export const GetMessagesDB = () =>{
  return messagesDB;
}

class MessagesDB {
  constructor() {
    this.messages = new Map();
    //this.callMessage = {}; //TODO CALL OBECJT
    this.actualCall = {meeting_uuid: CONST._empty, action: CONST._empty};
  }

  SetMe(user){//required to ignore my mmessages in calculation messages amount
    this.me = user;
  }

  SetMessages(messages, email) {
    messages.map((msg) => this.AddMessage(msg, email));
  }

  GetHash() {
    return this.hash;
  }

  ResetMessage() {
    this.messages = new Map();
  }

  RemoveTmpMessages(id){
    let entry = this.messages.get(id);
    if(entry){
      entry.list = entry.list.filter(msg=>!msg.tmp);
      entry.list.sort(DynamicSort("created_at"));
    }
    this.recalculateNewMessages(id);
  }

  AddMessage(msg, id) {
    if (id == null) {
      id = "email";
      console.log("NO ID SET");
    }

    if(this.me?.uuid === msg.from || this.me?.email === msg.from)
      msg.is_new = false;

    if (this.messages.get(id) == null)
      this.messages.set(id, createEmptyMessagesCollection());

    let entry = this.messages.get(id);

    let parsedMessage;
    if (msg.message_type && msg.message_type === CONST.messagetypes.group_msg) {
      parsedMessage = ParseGroupMessage(msg);
      if (!entry.isGroup) entry.isGroup = true;
    } else {
      parsedMessage = ParseMessage(msg);
    }

    let list = entry.list;
    if (
      parsedMessage.type === CONST.messagetypes.text ||
      parsedMessage.type === CONST.messagetypes.group_msg
    ) {
      list = entry.list;
    } else if (
      parsedMessage.type === CONST.messagetypes.system ||
      parsedMessage.type === CONST.messagetypes.call
    ) {
      entry = this.messages.get(id);
      list = entry.system;
    }

    let found = -1;
    for (let i = 0; i < list.length; i++) {
      if (msg.id != null && list[i].id != null && list[i].id === msg.id) {
        found = i;
        break;
      } else if (
        list[i].created_at === parsedMessage.created_at &&
        list[i].from === parsedMessage.from
      ) {
        found = i;
        break;
      } else if (list[i]?.internal && list[i].msg === parsedMessage.msg) {
        found = i;
        break;
      }
    }

    if (found < 0)
      list.push(parsedMessage);
    else
      list[found] = parsedMessage;

    entry.hash = new Date().getTime() + "" + list.length;
    if (parsedMessage.type === CONST.messagetypes.call) {
      let msgObject;
      if (
        typeof parsedMessage.msg === "string" ||
        parsedMessage.msg instanceof String
      )
        msgObject = JSON.parse(parsedMessage.msg);
      else msgObject = parsedMessage.msg;

      if (msgObject.to == null) msgObject.to = [];
      parsedMessage.msg = msgObject;
    }


    if ((parsedMessage.type && parsedMessage.type === CONST.messagetypes.group_msg)) {
      list.sort(DynamicSort("created_at"));
    } else {
      list.sort(DynamicSort("id"));
    }

    this.recalculateNewMessages(id);
    this.hash = new Date().getTime();
  }

  recalculateNewMessages(id) {
    const entry = this.messages.get(id);
    if (entry == null) return;

    let _new = 0;

    const lastMessageInGroupReadTime = GetLastReadMessageTimeInGroup(id)
    //console.log(id, lastMessageInGroupReadTime);
    entry.list.map((msg) => {
      if (
        msg.isGroupMessage &&
        msg.is_new &&
        !msg.tmp
      ) {
        if(msg.timestamp.getTime() > lastMessageInGroupReadTime){
         // console.log(msg, lastMessageInGroupReadTime)
          _new++;
        }
        else
          msg.is_new = false;
      } else if (msg.is_new && msg.from === id) _new++;
    });

    const hasChanges = entry.new != _new;

    entry.new = _new;
    if(hasChanges){
      notifyAboutNewMessages(_new, id);
      let allNew = this.GetNewMessages();
      notifyAboutNewMessages(allNew);
      setTimeout(()=>{//dirty hack to avoid "call from reducer"
        const d = {all:allNew}
        d[id] = _new;
        store.dispatch(setMessagesCount(d));
      },1);

    }
  }

  GetMessagesList(id) {
    if (this.messages.get(id)) return this.messages.get(id);
    return createEmptyMessagesCollection();
  }

  GetNewMessages(email = null) {
    let _new = 0;

    let foundMessages = [];
    this.messages.forEach((value, key) => {
      if (!email || key === email)
        foundMessages.push(value);
    })
    foundMessages.forEach((value) => {
      _new += value.new;
    });
    // console.log("NEW", _new);
    return _new;
  }
}

const messagesDB = new MessagesDB();

const messageListeners = {};
function notifyAboutNewMessages(newMessagesCount, id=null){
  if(id === null)
    id = "cummulated";

  if(messageListeners[id]){
    messageListeners[id].map(cb=>cb(newMessagesCount))
  }
}

export function addUserMessagesListener(id = null, callback){
  if(id === null)
    id = "cummulated";

  if(!messageListeners[id])
    messageListeners[id] = [];

  if(messageListeners[id].indexOf(callback)>-1)
    console.log("listener already in list");
  messageListeners[id].push(callback);
}

export function removeUserMessagesListener(id = null, callback){
  if(id === null)
    id = "cummulated";

  if(!messageListeners[id])
    return;

  messageListeners[id] = messageListeners[id].filter(cb=>cb!=callback);
}



export function ParseMessage(json) {
  return {
    id: json.id,
    from: json.from,
    to: json.to,
    created_at: json.created_at,
    timestamp: json.created_at ? new Date(json.created_at) : new Date(),
    is_new: json.is_new === 1 || json.is_new === true,
    is_deleted: json.is_deleted === 1 || json.is_deleted === true,
    msg: json.msg,
    type:
      json.type === "call" ? CONST.messagetypes.call : CONST.messagetypes.text,
  };
}

export function ParseGroupMessage(json) {
  return {
    isGroupMessage: true,
    // id: json.id,
    from: json.from,
    from_name: json.user?.firstname + " " + json.user?.lastname,
    to: json.message_group_id ? json.message_group_id : json.to,
    created_at: json.created_at,
    timestamp: json.created_at ? new Date(json.created_at) : new Date(),
    is_new: json.is_new === 1 || json.is_new === true,
    // is_deleted: json.is_deleted === 1 || json.is_deleted === true,
    msg: json.msg,
    tmp:json.tmp,
    type: json.message_type ? json.message_type : json.type,
  };
}

if(!localStorage.getItem("readMsgsInGroups"))
  localStorage.setItem("readMsgsInGroups", JSON.stringify({}));

const lastReadMessageTimeInGroups = JSON.parse(localStorage.getItem("readMsgsInGroups")) || {};

export function SetLastReadMessageTimeInGroup(group_id, ts){

  lastReadMessageTimeInGroups[group_id] = ts;
  localStorage.setItem("readMsgsInGroups", JSON.stringify(lastReadMessageTimeInGroups));
  messagesDB.recalculateNewMessages(group_id);
}

export function GetLastReadMessageTimeInGroup(group_id){
  if(lastReadMessageTimeInGroups && lastReadMessageTimeInGroups[group_id])
    return lastReadMessageTimeInGroups[group_id]
  return 0;
}

export function SetMeInMessagesDb(me){

	return messagesDB.SetMe(me);
};

const createEmptyMessagesCollection = function () {
  return { list: [], system: [], new: 0, hash: CONST._empty, isGroup: false };
};
